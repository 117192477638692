import { useEffect, useState } from 'react';
import {
  SignalRDto,
  SignalREventType,
  SignalRMsgLogBase,
} from 'Models/SignalRMessage';
import { plainToClass } from 'class-transformer';
import i18n from 'plugins/i18next';
import SignalRService from 'Services/SignalRService';
import { getUseEffect } from 'utils/hub-helper';
import { useSelector } from 'react-redux';
import {
  selectCurrentScenarioKey,
  selectSolutionKey,
} from 'store/slices/scenarioSlice';
import { NEWLINE } from 'utils/constants';

interface SignalRMessagesProps {
  applicationLogHub: SignalRService | null;
}

export default function useApplicationLogHubMessages({
  applicationLogHub,
}: SignalRMessagesProps) {
  const eventIds: SignalREventType[] = [SignalREventType.GeneralMessage];
  const initialMessage = `${i18n.t(
    'GENERAL.FEEDBACK.SIGNALR.LISTENING_EVENT_TYPES',
  )}: ${eventIds.join(', ')}`;
  const currentScenarioKey = useSelector(selectCurrentScenarioKey);
  const solutionKey = useSelector(selectSolutionKey);
  const [connectionStatus, setConnectionStatus] = useState<string>('');
  const [message, setMessage] = useState<string>(initialMessage);

  function processMessages(realMessage: SignalRDto) {
    const { message: logMessage, hasAllMessages } =
      realMessage.signalRMsgBase as SignalRMsgLogBase;

    if (hasAllMessages) {
      setMessage(() => `${initialMessage}${NEWLINE}${logMessage}`);
    } else {
      setMessage((prev) => `${prev}${NEWLINE}${logMessage}`);
    }
  }

  const handleSignalRMsgReceived = (msg: SignalRDto) => {
    const realMessage = plainToClass(SignalRDto, msg);

    processMessages(realMessage);
  };

  useEffect(
    getUseEffect(
      applicationLogHub,
      setConnectionStatus,
      eventIds,
      handleSignalRMsgReceived,
    ),
    [applicationLogHub, solutionKey, currentScenarioKey],
  );

  useEffect(() => {
    setMessage(initialMessage);
  }, [solutionKey]);

  return {
    connectionStatus,
    message,
  };
}
