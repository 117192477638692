import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'Components/Layout/Header';
import {
  ScenariosTable,
  ScenarioCreateEdit,
} from 'Components/Modules/Scenario';
import useScenario from 'storeHooks/useScenario';

function Scenarios() {
  const { setCurrentSolutionKey, setCurrentSolveDataKey } = useScenario();
  const { t } = useTranslation();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const toggleCreateModal = () => {
    setIsCreateModalVisible((prev) => !prev);
  };

  useEffect(() => {
    setCurrentSolutionKey('');
    setCurrentSolveDataKey('');
  }, []);

  return (
    <div>
      <Header />

      <div className="mt-4 px-10">
        <div className="flex justify-between items-center px-3">
          <div className="text-3xl">
            {t('GENERAL.SCENARIO.TITLE', {
              count: 2,
            })}
          </div>
          <button
            type="button"
            className="btn-default"
            onClick={toggleCreateModal}
          >
            {t('GENERAL.SCENARIO.CREATE')}
          </button>
        </div>

        <div className="mt-4">
          <ScenariosTable />
        </div>
      </div>

      {isCreateModalVisible && (
        <ScenarioCreateEdit onCancel={toggleCreateModal} />
      )}
    </div>
  );
}

export default Scenarios;
