import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { Header } from 'Components/Layout/Header';
import MenuItem, {
  MenuItemType,
} from 'Components/Elements/Common/Menu/MenuItem';
import InviteUser from 'Components/UserDashboard/InviteUser';
import ApplicationSetting from 'Components/UserDashboard/ApplicationSetting';
import Integrations from 'Components/UserDashboard/Integrations';
import { isAdmin } from 'Services/UserService';

function UserDashboard() {
  const { t } = useTranslation();
  const [active, setActive] = useState<MenuItemType>('application-settings');

  return (
    <div className="">
      <Header />

      <div className="mt-12 px-[150px]">
        <div className="title-lg">{t('GENERAL.SETTING')}</div>
        <Divider />
        <div className="flex gap-2 ">
          <div className="flex flex-column gap-1">
            <MenuItem
              title={t('GENERAL.USER.APPLICATION_SETTING')}
              id="application-settings"
              active={active === 'application-settings'}
              onClick={setActive}
            />
            <MenuItem
              title="Integrations"
              id="integrations"
              active={active === 'integrations'}
              onClick={setActive}
            />
            {isAdmin() && (
              <MenuItem
                title={t('GENERAL.USER.INVITE_USER')}
                id="invite-users"
                active={active === 'invite-users'}
                onClick={setActive}
              />
            )}
          </div>
          <Divider type="vertical" style={{ height: 500 }} />
          <div className="w-[450px]">
            {active === 'invite-users' && <InviteUser />}
            {active === 'application-settings' && <ApplicationSetting />}
            {active === 'integrations' && <Integrations />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
