import React, { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Empty from 'Components/Elements/Common/Empty/Empty';
import NetworkCategories from './NetworkCategories';
import NetworkCreatePopover from './NetworkCreatePopover';
import { Network } from 'Models/Network';
import useNetwork from 'storeHooks/queue/useNetwork';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import {
  CREATED,
  NEW,
  SYNC_NETWORK_CATEGORY_KEY_PREFIX,
  SYNC_NETWORK_KEY_PREFIX,
} from 'utils/variables';
import { selectNetworks } from 'store/slices/scenarioSlice';
import { useSelector } from 'react-redux';
import { randomId } from 'utils/ui-helper';
import { TextMapType } from 'Models/Scenario';

interface Props {
  className?: string;
}
const EMPTY = '';
const DEFAULT_COLOR = '#175cd3';

export default function Networks({ className }: Props): JSX.Element {
  const { t } = useTranslation();
  const { onEnqueue } = useQueue();
  const { syncNetworkToStore } = useNetwork();
  const { getCurrentOrClonedSolution } = useSolution();
  const initialNetworkState: Network = {
    name: '',
    color: DEFAULT_COLOR,
    networkKey: 'NewItem',
    networkStatus: t('GENERAL.NEW'),
    code: 'N1',
    networkCategories: [],
  };
  const allNetworks = useSelector(selectNetworks);
  const networkCodeMap = useRef<TextMapType>({});
  const [network, setNetwork] = useState(initialNetworkState);
  const [networkCategoriesNumber, setNetworkCategoriesNumber] = useState(0);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const networks = useMemo(() => {
    if (allNetworks) {
      networkCodeMap.current = allNetworks.reduce((acc, item) => {
        acc[item.networkKey] = item.code?.toLocaleLowerCase().trim() ?? '';

        item.networkCategories.forEach((nc) => {
          acc[nc.networkCategoryKey] =
            nc.code?.toLocaleLowerCase().trim() ?? '';
        });
        return acc;
      }, {} as TextMapType);
      return allNetworks;
    }

    return [];
  }, [allNetworks]);

  const togglePopover = () => {
    setNetwork(initialNetworkState);
    setNetworkCategoriesNumber(() => (!isPopoverOpen ? 1 : 0));
    setPopoverOpen((prev) => !prev);
  };

  const handleChangeNetwork = (name: string, value: string | number) => {
    setNetwork((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeNetworkCategoryNumber = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const number = Number(e.currentTarget.value);
    setNetworkCategoriesNumber(number >= 0 ? number : 0);
  };

  const handleSaveNetwork = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const { solutionKey } = chosenSolution.stateData;

    const toBeSyncedKey = `${SYNC_NETWORK_KEY_PREFIX}${uuidv4()}`;

    const dataPayload = {
      name: network.name,
      color: network.color ?? DEFAULT_COLOR,
      code: `N-${randomId(3)}`,
      networkKey: toBeSyncedKey,
      solutionKey,
      networkStatus: CREATED,
      toBeSyncedKey,
    } as Network;

    dataPayload.networkCategories = Array(networkCategoriesNumber)
      .fill(EMPTY)
      .map((__, index) => {
        const toBeSyncedNCKey = `${SYNC_NETWORK_CATEGORY_KEY_PREFIX}${uuidv4()}`;

        return {
          color: network.color ?? DEFAULT_COLOR,
          name: t('GENERAL.NETWORK.NEW', {
            count: index + 1,
          }),
          code: `NC${randomId(3)}`,
          network: null,
          networkCategoryId: toBeSyncedNCKey,
          networkCategoryKey: toBeSyncedNCKey,
          networkCategoryStatus: CREATED,
          solutionKey,
          toBeSyncedKey: toBeSyncedNCKey,
          slotType: null,
          slotTypeKey: null,
        };
      });

    syncNetworkToStore({
      data: dataPayload,
      solutionKey: chosenSolution.solutionKey,
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_NETWORK',
      payload: {
        data: {
          ...dataPayload,
          networkStatus: NEW,
          networkCategories: dataPayload.networkCategories.map((item) => ({
            ...item,
            networkCategoryStatus: NEW,
          })),
        },
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
      },
    });

    togglePopover();
  };

  const createPopover = () => (
    <NetworkCreatePopover
      networkCategoriesNumber={networkCategoriesNumber}
      handleChangeNetworkCategoryNumber={handleChangeNetworkCategoryNumber}
      network={network}
      handleChangeNetwork={handleChangeNetwork}
      handleSaveNetwork={handleSaveNetwork}
      onClose={togglePopover}
    />
  );

  return (
    <div
      className={classNames('section-container', className)}
      data-testid="networks-container"
    >
      <div className="section-header flex-between">
        <div className="section-title" data-testid="network-title">
          {t('GENERAL.NETWORK.TITLE', {
            count: 2,
          })}
        </div>

        <Dropdown
          menu={{ items: [] }}
          trigger={['click']}
          dropdownRender={createPopover}
          open={isPopoverOpen}
          destroyPopupOnHide
        >
          <Button
            className="white-btn"
            icon={<PlusOutlined />}
            onClick={() => togglePopover()}
            data-testid="network-create-trigger"
          >
            {t('GENERAL.NETWORK.CREATE')}
          </Button>
        </Dropdown>
      </div>

      {networks.length === 0 && (
        <Empty
          title={t('GENERAL.NETWORK.EMPTY')}
          subtitle={t('GENERAL.NETWORK.LET_CREATE')}
          btnText={t('GENERAL.NETWORK.CREATE')}
          onBtnClick={() => togglePopover()}
          height={495}
          className="white-background mt-1"
        />
      )}
      <div className="network-categories" data-testid="network-categories">
        {networks.map((_network) => (
          <NetworkCategories
            key={_network.networkKey}
            network={_network}
            networkCodeMap={networkCodeMap.current}
          />
        ))}
      </div>
    </div>
  );
}
