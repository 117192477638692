import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioSetLineResponsePromiseType, SetLine } from 'Models/Scenario';
import { QueueCreateUpdateSetLinePayloadType } from 'Models/Queue';
import { saveSetLine, syncSetline } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { getNthElement, getScenarioKey } from 'utils/ui-helper';
import { useAppDispatch } from '../hooks';

export const useSetLine = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareSetLinePayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateSetLinePayloadType): SetLine => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (newPayload.setKey && queueLocalKeyMapRef.current[newPayload.setKey]) {
      newPayload.setKey = queueLocalKeyMapRef.current[newPayload.setKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.setLineKey]) {
      newPayload.setLineKey =
        queueLocalKeyMapRef.current[newPayload.setLineKey];
    }

    if (newPayload.teamKey && queueLocalKeyMapRef.current[newPayload.teamKey]) {
      newPayload.teamKey = queueLocalKeyMapRef.current[newPayload.teamKey];
    }

    if (
      newPayload.opponentTeamKey &&
      queueLocalKeyMapRef.current[newPayload.opponentTeamKey]
    ) {
      newPayload.opponentTeamKey = getNthElement(
        queueLocalKeyMapRef.current[newPayload.opponentTeamKey],
        4,
      );
    }

    if (newPayload.venueKey && queueLocalKeyMapRef.current[newPayload.venueKey]) {
      newPayload.venueKey = queueLocalKeyMapRef.current[newPayload.venueKey];
    }

    if (
      newPayload.slotTypeKey &&
      queueLocalKeyMapRef.current[newPayload.slotTypeKey]
    ) {
      newPayload.slotTypeKey = queueLocalKeyMapRef.current[newPayload.slotTypeKey];
    }

    if (
      newPayload.networkKey &&
      queueLocalKeyMapRef.current[newPayload.networkKey]
    ) {
      newPayload.networkKey =
        queueLocalKeyMapRef.current[newPayload.networkKey];
    }

    if (
      newPayload.networkCategoryKey &&
      queueLocalKeyMapRef.current[newPayload.networkCategoryKey]
    ) {
      newPayload.networkCategoryKey = queueLocalKeyMapRef.current[newPayload.networkCategoryKey];
    }

    return newPayload;
  };

  const syncSetLineToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: QueueCreateUpdateSetLinePayloadType): void => {
    const preparedData = prepareSetLinePayload({
      data,
      toBeUpdatedFields,
      solutionKey,
    });

    dispatch(
      syncSetline({
        data: {
          ...preparedData,
          solutionKey,
          toBeSyncedKey,
        },
        solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateSetLineQueue = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: {
    data: SetLine;
    toBeUpdatedFields: {
      [key in keyof SetLine]?: SetLine[key];
    };
    toBeSyncedKey: string;
    solutionKey: string;
  }): Promise<ScenarioSetLineResponsePromiseType | null> => {
    const preparedData = prepareSetLinePayload({
      data,
      toBeUpdatedFields,
    });

    const scenarioKey = data.solutionKey
      ? getScenarioKey(data.solutionKey)
      : solutionKey;

    if (!preparedData) return Promise.resolve(null);

    return dispatch(
      saveSetLine({
        payload: {
          setLine: {
            ...preparedData,
            toBeSyncedKey,
          },
          solutionKey: preparedData.solutionKey ?? '',
          scenarioKey,
          toBeSyncedKey,
        },
        solutionStatus: CREATED,
      }),
    ) as Promise<ScenarioSetLineResponsePromiseType>;
  };

  return {
    prepareSetLinePayload,
    syncSetLineToStore,
    createOrUpdateSetLineQueue,
  };
};

export default useSetLine;
