import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M15.0011 6.25075H23.8781L14.6629 33.5705C14.6158 33.7101 14.5472 33.8414 14.4596 33.9598C14.3722 34.0783 14.2669 34.1822 14.1472 34.2681C14.0276 34.3539 13.8956 34.4207 13.7554 34.4657C13.6151 34.5107 13.4688 34.5339 13.3218 34.5339H6.41333C6.18907 34.5339 5.96781 34.4807 5.7683 34.3779C5.56858 34.2755 5.39618 34.1269 5.26541 33.9444C5.13455 33.762 5.04894 33.551 5.01565 33.329C4.98227 33.107 5.00252 32.8801 5.07415 32.6675L13.6597 7.21341C13.7066 7.07391 13.7752 6.94265 13.863 6.82415C13.9504 6.70564 14.0561 6.60176 14.1754 6.51588C14.295 6.43001 14.427 6.36325 14.5673 6.31825C14.7075 6.27325 14.8538 6.25 15.0008 6.25V6.25038L15.0011 6.25075Z"
          fill="url(#paint0_linear_1084_14607)"
        />
        <path
          d="M31.3658 34.5337H23.3949C23.306 34.5337 23.2171 34.5254 23.1294 34.5086C23.042 34.4921 22.9561 34.4673 22.8732 34.4347C22.7904 34.4021 22.7109 34.3616 22.6355 34.3143C22.5602 34.2667 22.4895 34.2123 22.4243 34.1516L13.379 25.704C13.2833 25.6146 13.2167 25.4986 13.1877 25.371C13.1588 25.2433 13.169 25.1099 13.217 24.9881C13.2649 24.8663 13.3484 24.7617 13.4566 24.6881C13.565 24.6146 13.6929 24.5752 13.8238 24.5752H27.9003L31.3651 34.5341L31.3658 34.5337Z"
          fill="#0078D4"
        />
        <path
          d="M23.9119 6.25099L17.6979 24.5776L27.851 24.5753L31.3338 34.5342H23.3929C23.3107 34.5334 23.2286 34.5259 23.1476 34.5113C23.0666 34.4967 22.9871 34.4753 22.9098 34.4468C22.7549 34.3904 22.6108 34.3078 22.4838 34.2027L16.3778 28.504L14.6685 33.5449C14.6243 33.6709 14.5635 33.7905 14.4878 33.9004C14.3359 34.1215 14.1283 34.2983 13.8859 34.4131C13.7652 34.4701 13.6374 34.5109 13.506 34.5342H6.41934C6.19283 34.5349 5.96932 34.4809 5.76794 34.3767C5.56652 34.2725 5.39315 34.1214 5.26247 33.9361C5.13179 33.7508 5.04765 33.5367 5.01716 33.312C4.98678 33.0872 5.01083 32.8584 5.08728 32.6449L13.659 7.23278C13.7036 7.09065 13.7707 6.95677 13.8577 6.83602C13.9444 6.71526 14.0501 6.60876 14.1701 6.521C14.2901 6.43325 14.4236 6.365 14.565 6.31925C14.7064 6.27349 14.8545 6.25024 15.0034 6.25099H23.9122H23.9119Z"
          fill="url(#paint1_linear_1084_14607)"
        />
        <path
          d="M34.9256 32.6677C34.9973 32.8805 35.0175 33.1072 34.9845 33.3292C34.9512 33.5512 34.8657 33.7621 34.7351 33.9446C34.6043 34.127 34.432 34.2755 34.2324 34.378C34.0328 34.4806 33.8116 34.5341 33.5872 34.5342H23.6936C23.9179 34.5342 24.1391 34.4805 24.339 34.3782C24.5385 34.2758 24.711 34.1269 24.8419 33.9446C24.9728 33.7624 25.0583 33.5512 25.0916 33.3292C25.125 33.1072 25.1048 32.8803 25.0331 32.6677L16.4472 7.21289C16.3527 6.93248 16.1726 6.68879 15.9323 6.51611C15.813 6.43023 15.681 6.36386 15.5412 6.31885C15.4013 6.27385 15.255 6.25098 15.1084 6.25098H25.0016C25.1487 6.25098 25.2945 6.27385 25.4348 6.31885C25.5747 6.36386 25.7067 6.43061 25.8263 6.51611C25.9456 6.60199 26.0509 6.70587 26.1383 6.824C26.2257 6.94213 26.2939 7.07338 26.3412 7.21289L34.9271 32.6677H34.9256Z"
          fill="url(#paint2_linear_1084_14607)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1084_14607"
          x1="18.2409"
          y1="8.34708"
          x2="9.01555"
          y2="35.5994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1084_14607"
          x1="21.1191"
          y1="21.0453"
          x2="18.9853"
          y2="21.7691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.1" stopOpacity="0.2" />
          <stop offset="0.3" stopOpacity="0.1" />
          <stop offset="0.6" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1084_14607"
          x1="19.9405"
          y1="7.55228"
          x2="30.0658"
          y2="34.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
