import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import {
  LogMessagesPayload,
  LogMessagesResponse,
  LogMessageType,
  Solution,
} from 'Models/Scenario';
import { SolutionStatusStopped } from 'Models/SolutionStatuses';
import showMessage from 'Components/Elements/Common/Message';
import { ApplicationLog } from './ApplicationLog';
import { SolverLog } from './SolverLog';
import i18n from 'plugins/i18next';

interface Props {
  applicationLog: string;
}

export const handleFetchLogs = async (
  selectedSolution: Solution | null,
  logMessageType: LogMessageType,
  fetchLogMessages: (
    payload: LogMessagesPayload,
  ) => Promise<LogMessagesResponse | null>,
  setDisplayMessage: (value: string) => void,
) => {
  if (
    !selectedSolution ||
    selectedSolution.solutionStatus !== SolutionStatusStopped
  ) {
    return;
  }

  try {
    const res: LogMessagesResponse | null = await fetchLogMessages({
      solutionKey: selectedSolution.solutionKey,
      logMessageType,
    });

    if (res?.messages) {
      setDisplayMessage(res.messages);
    }
  } catch (err) {
    showMessage({
      key: selectedSolution.solutionKey,
      type: 'error',
      content: i18n.t('GENERAL.FEEDBACK.SOLUTION.FETCH_LOGS_FAILED'),
    });
  }
};

function ViewLog({ applicationLog }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div data-testid="log-view" className="view-log-section">
      <Tabs
        data-testid="log-view-tab"
        defaultActiveKey="1"
        items={[
          {
            label: t('GENERAL.VIEW_LOG_TAB.APPLICATION_LOG'),
            key: '1',
            children: <ApplicationLog message={applicationLog} />,
          },
          {
            label: t('GENERAL.VIEW_LOG_TAB.SOLVER_LOG'),
            key: '2',
            children: <SolverLog />,
          },
        ]}
      />
    </div>
  );
}

export default ViewLog;
