import React, { useEffect, useRef, useMemo } from 'react';
import ClassNames from 'classnames';
import { Solution } from 'Models/Scenario';
import useScenario from 'storeHooks/useScenario';
import {
  ExportSolveData,
  CompareAndCreateSeedPayload,
} from 'Services/ScenarioService';
import { FileExport } from 'Models/FileExport';
import { useTranslation } from 'react-i18next';
import useNetwork from 'storeHooks/useNetwork';
import { shadeColor } from 'utils/ui-helper';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BASE, COPY, DELETE, NEW } from 'utils/variables';
import { selectScenario } from 'store/slices/scenarioSlice';
import { useSelector } from 'react-redux';
import { ThreeDots } from '../Icons';
import { useAppDispatch } from '../../../storeHooks/hooks';
import {
  cloneSolveData,
  cloneScenarioWithSolveData,
  deleteSolveData,
  compareAndCreateSeed,
} from '../../../store/slices/solutionSlice';
import showMessage from '../Common/Message';
import './style.scss';
import {
  SolveDataOptimizationStatusOptimal,
  SolveDataOptimizationStatusUnknown,
} from '../../../Models/SolveDataOptimizationStatus';

interface Props {
  solution: Solution;
  onChange: (key: string) => void;
  className?: string;
  backgroundColor?: string;
  color?: string;
}

const CREATE_SCENARIO_FROM_RESULT = 'create-scenario-from-result';
const EXPORT_CSV = 'export-csv';
const CREATE_COMMON_SEED = 'create-common-seed';
const WHATS_NEW = 'whats-new';
const COMMON = 'common';

export default function SolutionItem({
  solution,
  className,
  onChange,
  backgroundColor,
  color,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const scenario = useSelector(selectScenario);
  const { currentScenarioKey, solutionKey, solveDataKey } = useScenario();
  const { goToScenario } = useNetwork();
  const dispatch = useAppDispatch();
  const listRef = useRef<HTMLLIElement[]>([]);

  useEffect(() => {
    if (backgroundColor && color && listRef.current) {
      listRef.current.forEach((liElement) => {
        if (liElement.classList.contains('active')) {
          liElement.style.background = shadeColor(backgroundColor, -16);
        } else {
          liElement.style.background = 'inherit';
          liElement.style.border = '';
        }
      });
    }
  }, [backgroundColor, solution, solveDataKey]);

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: COPY,
        label: t('GENERAL.COPY'),
      },
      {
        key: DELETE,
        label: t('GENERAL.DELETE'),
      },
      {
        type: 'divider',
      },
      {
        key: CREATE_SCENARIO_FROM_RESULT,
        label: t('GENERAL.SOLUTION.CREATE_SCENARIO_FROM_RESULT'),
      },
      {
        key: EXPORT_CSV,
        label: t('GENERAL.SOLUTION.EXPORT_RESULT_INTO_CSV'),
      },
      {
        key: CREATE_COMMON_SEED,
        label: t('GENERAL.SOLUTION.CREATE_COMMON_SEED'),
        children: scenario
          ? scenario.optimizationEnvelop.solutions.map((item) => ({
              key: `${COMMON}-${item.solutionKey}`,
              label: `${t('GENERAL.INSTANCE')} ${item.solutionIndex}`,
              type: 'group',
              children: item.solveDataList.map((solveData) => ({
                key: `${COMMON}-${solveData.solveDataKey}`,
                label: `${solveData.totalPenalty ?? BASE}`,
              })),
            }))
          : [],
      },
      {
        key: WHATS_NEW,
        label: t('GENERAL.SOLUTION.WHATS_NEW_IN_RESULT'),
        children: scenario
          ? scenario.optimizationEnvelop.solutions.map((item) => ({
              key: `${NEW}-${item.solutionKey}`,
              label: `${t('GENERAL.INSTANCE')} ${item.solutionIndex}`,
              type: 'group',
              children: item.solveDataList.map((solveData) => ({
                key: `${NEW}-${solveData.solveDataKey}`,
                label: `${solveData.totalPenalty ?? BASE}`,
              })),
            }))
          : [],
      },
    ],
    [scenario, solutionKey],
  );

  const handleDeleteSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETING'),
      });

      const res = await dispatch(
        deleteSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCloneSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPYING'),
      });

      const res = await dispatch(
        cloneSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPIED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPY_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCompareAndCreateSeed = async (
    data: Partial<CompareAndCreateSeedPayload>,
  ) => {
    if (
      currentScenarioKey &&
      data.compareType &&
      data.solveDataKey1 &&
      data.solveDataKey2
    ) {
      const res = await dispatch(
        compareAndCreateSeed({
          scenarioKey: currentScenarioKey,
          solveDataKey1: data.solveDataKey1,
          solveDataKey2: data.solveDataKey2,
          compareType: data.compareType,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t(
            `GENERAL.FEEDBACK.SOLUTION.${
              data.compareType === 'Common'
                ? 'SOLUTION_FROM_COMMON_SEED'
                : 'SOLUTION_FROM_DIFFERENT_SEED'
            }`,
          ),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCloneScenarioWithSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SCENARIO.CREATING'),
      });

      const res = await dispatch(
        cloneScenarioWithSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleExportResult = async () => {
    try {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORTING'),
      });

      const response: FileExport = await ExportSolveData(
        currentScenarioKey,
        solutionKey,
        solveDataKey,
      );

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute(
        'href',
        `data:${response.mimeType};charset=utf-8,${response.content}`,
      );
      downloadLink.setAttribute('download', response.fileName);
      downloadLink.click();

      showMessage({
        key: solveDataKey,
        type: 'success',
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORTED'),
      });
    } catch (err) {
      showMessage({
        key: solveDataKey,
        type: 'error',
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORT_FAILED'),
      });
    }
  };

  const handleChange = ({
    key,
    sourceSolveDataKey,
  }: {
    key: string;
    sourceSolveDataKey: string;
  }) => {
    switch (key) {
      case COPY: {
        handleCloneSolveData();
        break;
      }
      case DELETE: {
        handleDeleteSolveData();
        break;
      }
      case CREATE_SCENARIO_FROM_RESULT: {
        handleCloneScenarioWithSolveData();
        break;
      }
      case EXPORT_CSV: {
        handleExportResult();
        break;
      }

      default: {
        const compareType = key.includes(COMMON) ? 'Common' : 'Different';

        if (key.includes(sourceSolveDataKey)) return;

        handleCompareAndCreateSeed({
          compareType,
          solveDataKey1: sourceSolveDataKey,
          solveDataKey2: key.replace(`${NEW}-`, '').replace(`${COMMON}-`, ''),
        });
      }
      //
    }
  };

  return (
    <ul className={className} data-testid="solution-items">
      {solution.solveDataList.map((item, i) => (
        <li
          key={item.solveDataKey}
          ref={(el) => {
            if (el) {
              listRef.current[i] = el;
            }
          }}
          className={ClassNames('solution-item', {
            active: item.solveDataKey === solveDataKey,
          })}
          title={`Created at: ${item.timestamp?.toString()}, Status: ${
            item.solveDataOptimizationStatus
          }`}
          onClick={() => onChange(item.solveDataKey)}
        >
          <div className="flex justify-between">
            <span>
              {item.solveDataOptimizationStatus ===
                SolveDataOptimizationStatusOptimal ||
              item.solveDataOptimizationStatus ===
                SolveDataOptimizationStatusUnknown
                ? item.totalPenalty ?? 'No Value '
                : item.solveDataOptimizationStatus}
            </span>

            <span>
              {item.solveDataKey === solution.solveDataList[0].solveDataKey
                ? `[${BASE}]`
                : ''}
            </span>

            <Dropdown
              menu={{
                items,
                selectable: true,
                onSelect: (data) =>
                  handleChange({
                    ...data,
                    sourceSolveDataKey: item.solveDataKey,
                  }),
              }}
              trigger={['click']}
              destroyPopupOnHide
            >
              <div className="cursor-pointer" data-testid="three-dot">
                <ThreeDots className="w-4" color={color ?? '#0F1316'} />
              </div>
            </Dropdown>
          </div>
        </li>
      ))}
    </ul>
  );
}
