import React, { useState, useDeferredValue, useEffect } from 'react';
import {
  OptimizedColumn,
  StateData,
  SolveTableType,
  ZoomType,
} from 'Models/Scenario';
import SolveTableHtml from 'Components/Scenarios/EditScenario/Solve/Solve/SolveTableHtml';
import { useTranslation } from 'react-i18next';
import {
  solveSectionheaderHeight,
  solveSectionOffsetHeight,
} from 'Components/Elements/Drawer/constants';
import { clearCellsHilighting } from 'Components/Scenarios/EditScenario/Solve/Solve/SelectSolveTableCells';
import SelectSolveDataType from './SelectSolveDataType';
import SelectSolveZoom from './SelectSolveZoom';
import useSolveTable from './useSolveTable';
import './style.scss';
import { useSelector } from 'react-redux';
import {
  selectScenario,
  selectSelectedSolution,
  selectSolveDataKey,
  selectSolutionKey,
} from '../../../store/slices/scenarioSlice';

const adjustExtraHeightForBiggerScreen = (height: number) =>
  height > 500 ? 36 : 0;

function SolveDataTable({
  height,
  width,
  maxInnerHeight,
  customWidth,
}: {
  height: number;
  width: number;
  maxInnerHeight: number;
  customWidth?: number;
}): JSX.Element {
  const { t: translate } = useTranslation();
  const scenario = useSelector(selectScenario);
  const selectedSolution = useSelector(selectSelectedSolution);
  const stateData = selectedSolution?.stateData;
  const solveDataKey = useSelector(selectSolveDataKey);
  const solveData =
    selectedSolution && solveDataKey
      ? selectedSolution.solveDataList.find(
          (item) => item.solveDataKey === solveDataKey,
        )
      : null;
  const solutionKey = useSelector(selectSolutionKey);
  const { handleGamesLock, createFixedGameSet } = useSolveTable();
  const [type, setType] = useState<SolveTableType>('all');
  const [zoom, setZoom] = useState<ZoomType>('100');
  const gridWidth = width !== 0 ? width : 600;
  const gridHeight = height !== 0 ? height : maxInnerHeight;
  const solutionIndex = scenario?.optimizationEnvelop.solutions.find(
    (item) => item.solutionKey === solutionKey,
  )?.solutionIndex;

  const deferredStateData = useDeferredValue(stateData);
  const deferredSolveData = useDeferredValue(solveData);

  const getTableHtml = (
    currentStateData: StateData | null,
    optimizedColumns: OptimizedColumn[] | null,
    zoomPercentage: ZoomType,
  ): JSX.Element | undefined => {
    let body;

    if (currentStateData) {
      const slotRows = deferredSolveData?.slotRows ?? [];

      const selectedHomeTeams = [...currentStateData.teams].sort((a, b) =>
        a.code.localeCompare(b.code, 'en', { numeric: true }),
      ); // lets always show all team now

      body = (
        <SolveTableHtml
          slotRows={slotRows}
          selectedHomeTeams={selectedHomeTeams}
          onGamesLock={handleGamesLock}
          createFixedGameSet={createFixedGameSet}
          type={type}
          zoom={zoomPercentage}
        />
      );
    } else if (!selectedSolution) {
      body = <div>Solution is not selected</div>;
    }

    return body;
  };

  const detectClickOutside = (e: MouseEvent) => {
    const solveTable = document.querySelector(
      '.solve-table.table',
    ) as HTMLTableElement;
    if (!solveTable) return;

    // @ts-expect-error
    if (e.target && !solveTable.contains(e.target)) {
      clearCellsHilighting();
    }
  };

  const totalColumnsAmt = deferredSolveData?.optimizedColumns.length ?? 0;
  const lockedColumns =
    deferredSolveData?.optimizedColumns.filter((x) => x.isLocked) ?? [];
  const lockedColumnsAmt = lockedColumns?.length ?? 0;

  let calculatedHeight = gridHeight - 59;

  if (height && gridWidth < height) {
    calculatedHeight = gridHeight - 52;
  }

  useEffect(() => {
    window.addEventListener('click', detectClickOutside);

    return () => {
      window.removeEventListener('click', detectClickOutside);
    };
  }, []);

  return (
    <div
      className="solve-data-table"
      style={{
        height:
          calculatedHeight +
          solveSectionheaderHeight +
          (customWidth
            ? solveSectionOffsetHeight
            : adjustExtraHeightForBiggerScreen(height)),
      }}
    >
      {selectedSolution && (
        <div className="solve-info">
          <div>
            {scenario?.name} / {translate('GENERAL.INSTANCE')}{' '}
            {solutionIndex ?? 0}/{` ${solveData?.totalPenalty ?? '---'}`}
          </div>
          <div className="stats">
            <div data-testid="locked-count">
              {translate('GENERAL.GAME_TYPES.LOCKED')} : {lockedColumnsAmt}
            </div>
            <div data-testid="unlocked-count">
              {`${translate('GENERAL.GAME_TYPES.UNLOCKED')} : ${
                totalColumnsAmt - lockedColumnsAmt
              }`}
            </div>
            <div>
              <SelectSolveDataType onChange={setType} />
            </div>
            <div>
              <SelectSolveZoom zoom={zoom} onChange={setZoom} />
            </div>
          </div>
        </div>
      )}
      <div
        className="overflow-auto"
        style={{
          width: gridWidth + 2,
          height: height
            ? '88%'
            : calculatedHeight +
              (customWidth ? solveSectionOffsetHeight : 0) -
              4,
        }}
      >
        {getTableHtml(
          deferredStateData ?? null,
          deferredSolveData?.optimizedColumns ?? null,
          zoom,
        )}
      </div>
    </div>
  );
}

export default SolveDataTable;
