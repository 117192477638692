import React, { useEffect, useState } from 'react';
import SignalRMessages from 'Components/Scenarios/EditScenario/Solve/Solve/SignalRMessages';
import { FetchLogMessages } from 'Services/ScenarioService';
import useScenario from 'storeHooks/useScenario';
import useSolverLogHubMessages from 'customHooks/useSolverLogHubMessages';
import useConnectHubs from 'customHooks/useConnectHubs';
import { handleFetchLogs } from './ViewLog';

export function SolverLog() {
  const { selectedSolution } = useScenario();
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    solverLogHub,
    connectSolverLogHubHandler,
    disconnectSolverLogHubHandler,
  } = useConnectHubs();
  const { message } = useSolverLogHubMessages({
    solverLogHub,
  });

  useEffect(() => {
    handleFetchLogs(
      selectedSolution,
      'SolverLog',
      FetchLogMessages,
      setDisplayMessage,
    );
  }, [selectedSolution]);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  useEffect(() => {
    if (selectedSolution) {
      connectSolverLogHubHandler(selectedSolution.solutionKey);
    }

    return () => {
      disconnectSolverLogHubHandler();
    };
  }, [selectedSolution]);

  return (
    <div className="terminal-log" data-testid="solver-log">
      <SignalRMessages message={displayMessage} />
    </div>
  );
}
