import { useMemo, useRef, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  RoundTemplate,
  StateData,
  RoundDay,
  TimeSlot,
  RoundType,
  RoundUpdateUnitType,
  Solution,
  RoundTemplateKeys,
} from 'Models/Scenario';
import { CreateEmptyDay } from 'Components/Scenarios/EditScenario/Rounds/RoundsView/Editor/RoundFactory';
import useScenario from 'storeHooks/useScenario';
import {
  CREATED,
  DELETED,
  NEW,
  ROUND_DAY_KEY_PREFIX,
  ROUND_TEMPLATE_KEY_PREFIX,
  TIME_SLOT_KEY_PREFIX,
  WeekDayMap,
} from 'utils/variables';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import useRoundTemplateQueue from 'storeHooks/queue/useRoundTemplate';
import useTimeSlot from 'storeHooks/queue/useTimeSlot';

dayjs.extend(utc);

type RepeatType = 'week' | 'day';

const DATE_FORMAT = 'DD MMM YYYY';

type WeekDayType = keyof typeof WeekDayMap;

export const useRoundCreate = () => {
  const { t } = useTranslation();
  const { selectedSolution } = useScenario();
  const { onEnqueue } = useQueue();
  const { syncRoundToStore, syncRoundWeekStartDay } = useRoundTemplateQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const { syncTimeSlotToStore } = useTimeSlot();
  const selectedSolutionRef = useRef<Solution | null>(selectedSolution);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  const stateData = useMemo(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
      return selectedSolution.stateData;
    }

    return null;
  }, [selectedSolution]);

  const createNewSlot = (timeSlotText: string, networkCategoryKey: string) => {
    if (!selectedSolutionRef.current) return null;

    const updatedStateData: StateData = _.cloneDeep(
      selectedSolutionRef.current.stateData,
    );

    const { roundsDictionaries } = updatedStateData;

    const targetNetwork = roundsDictionaries.networks.find((network) =>
      network.networkCategories.some(
        (category) => category.networkCategoryKey === networkCategoryKey,
      ),
    );

    const toBeSyncedKey = `${TIME_SLOT_KEY_PREFIX}${uuidv4()}`;

    return {
      timeSlotId: toBeSyncedKey,
      timeSlotKey: toBeSyncedKey,
      timeSlotText,
      timeSlotStatus: NEW,
      networkCategoryKey,
      networkKey: targetNetwork?.networkKey ?? '',
      toBeSyncedKey,
    } as TimeSlot;
  };

  const hasOverlappingDate = (
    StartA: Date,
    EndA: Date,
    StartB: Date,
    EndB: Date,
  ) =>
    (StartA <= EndB && EndA >= StartB) || (StartA === EndA && StartB === EndB);

  const createEmptyRounds = () => {
    const roundDays: RoundDay[] = [];

    if (stateData) {
      for (let i = 1; i <= 7; i++) {
        roundDays.push(CreateEmptyDay(i, stateData.roundsDictionaries, true));
      }
    }

    return roundDays;
  };

  const getValidWeekDaysNumber = (startDate: Dayjs, endDate: Dayjs) => {
    let roundStartDate = startDate;

    const validWeekDays: WeekDayType[] = [
      roundStartDate.format('ddd') as WeekDayType,
    ];

    while (endDate.diff(roundStartDate) > 0) {
      roundStartDate = roundStartDate.add(1, 'day');
      validWeekDays.push(roundStartDate.format('ddd') as WeekDayType);
    }

    return Array.from(
      new Set(validWeekDays.map((v: WeekDayType) => WeekDayMap[v])),
    ) as number[];
  };

  const getValidRoundDays = (round: RoundTemplate, validWeekDays: number[]) =>
    _.cloneDeep(round).roundDays.map((roundDay) => {
      if (!validWeekDays.includes(roundDay.weekDay)) {
        roundDay.timeSlots = roundDay.timeSlots.map((x) => ({
          ...x,
          timeSlotStatus: DELETED,
        }));
      }

      return roundDay;
    });

  const shiftRoundDay = (roundDays: RoundDay[], shiftBy: number) => {
    if (shiftBy === 0) return roundDays;

    const originalRoundDays = JSON.parse(
      JSON.stringify(roundDays),
    ) as RoundDay[];
    const clonedRoundDays = JSON.parse(JSON.stringify(roundDays)) as RoundDay[];
    let shiftedRoundDays = clonedRoundDays;

    shiftedRoundDays = shiftedRoundDays.map((roundDay, rdi) => {
      let newWeekDay = originalRoundDays[rdi].weekDay;

      if (shiftBy > 0) {
        newWeekDay += shiftBy;

        if (newWeekDay > 7) {
          newWeekDay -= 7;
        }
      } else {
        newWeekDay -= Math.abs(shiftBy);

        if (newWeekDay <= 0) {
          newWeekDay = 7 - Math.abs(newWeekDay);
        }
      }

      return {
        ...roundDay,
        roundDayKey: originalRoundDays[rdi].roundDayKey,
        weekDay: newWeekDay,
      };
    });

    return shiftedRoundDays;
  };

  const updateEntireRound = (currentStartDate: Dayjs, newStartDate: Dayjs) => {
    if (!stateData) return;

    const differenceBewteenDates = newStartDate.diff(currentStartDate, 'days');

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const updatedStateData: StateData = chosenSolution.stateData;

    const updatePayload: RoundUpdateUnitType[] =
      updatedStateData.roundTemplates.map((item) => {
        const updateRoundItem: RoundUpdateUnitType = {
          startDate: item.startDate,
          endDate: item.endDate,
          roundTemplateKey: item.roundTemplateKey,
          roundTemplateStatus: item.roundTemplateStatus,
          groupBatchId: item.groupBatchId,
          name: item.name,
        };

        const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;
        updateRoundItem.toBeSyncedKey = toBeSyncedKey;

        if (differenceBewteenDates > 0) {
          updateRoundItem.startDate = dayjs(item.startDate)
            .utc()
            .add(differenceBewteenDates, 'days')
            .toDate();

          updateRoundItem.endDate = dayjs(item.endDate)
            .utc()
            .add(differenceBewteenDates, 'days')
            .toDate();
        } else if (differenceBewteenDates < 0) {
          updateRoundItem.startDate = dayjs(item.startDate)
            .utc()
            .subtract(Math.abs(differenceBewteenDates), 'days')
            .toDate();

          updateRoundItem.endDate = dayjs(item.endDate)
            .utc()
            .subtract(Math.abs(differenceBewteenDates), 'days')
            .toDate();
        }

        syncRoundToStore({
          data: {
            ...item,
            startDate: updateRoundItem.startDate,
            endDate: updateRoundItem.endDate,
            roundDays: shiftRoundDay(
              item.roundDays,
              differenceBewteenDates % 7,
            ),
          },
          toBeSyncedKey,
          solutionKey: chosenSolution.solutionKey,
          toBeUpdatedFields: {
            roundTemplateStatus: CREATED,
          },
        });

        return updateRoundItem;
      });

    const payloadForApi = updatePayload.map((item, i) => ({
      ...item,
      roundDays: shiftRoundDay(
        updatedStateData.roundTemplates[i].roundDays,
        differenceBewteenDates % 7,
      ),
    }));

    onEnqueue({
      type: 'UPDATE_ROUND_TEMPLATE',
      payload: {
        data: payloadForApi as RoundTemplate[],
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });
  };

  const hasValidSlot = (slot: TimeSlot, day?: keyof typeof WeekDayMap) => {
    if (!selectedSolutionRef.current) return false;

    const validSlots: number[] = [];

    selectedSolutionRef.current.stateData.roundTemplates.forEach((item) => {
      const validWeekDaysNumber: number[] = getValidWeekDaysNumber(
        dayjs(item.startDate).utc(),
        dayjs(item.endDate).utc(),
      );

      item.roundDays.forEach((roundDay) => {
        if (
          validWeekDaysNumber.includes(roundDay.weekDay) &&
          !roundDay.timeSlots.find(
            (ts) => ts.networkCategoryKey === slot.networkCategoryKey,
          )
        ) {
          if (day) {
            const weekDay = WeekDayMap[day];

            if (roundDay.weekDay === weekDay) {
              validSlots.push(1);
            }
            return;
          }

          validSlots.push(1);
        }
      });
    });

    return validSlots.length > 0;
  };

  const saveRounds = (
    startDate: Dayjs,
    endDate: Dayjs,
    repeat: RepeatType,
    numberOfRounds: number,
  ): string => {
    let responseString = '';

    if (!stateData) return '';

    const differenceBewteenDates = endDate.diff(startDate, 'days');

    if (differenceBewteenDates < 0) {
      return t('GENERAL.ROUND.END_START_DATE_ERROR');
    }

    const result: RoundTemplate[] = [];
    const groupBatchId = new Date().toISOString();

    const allSyncedKeys: RoundTemplateKeys[] = [];

    for (let round = 0; round < numberOfRounds; round++) {
      let roundStartDate = startDate.add(round * 7, 'day');
      let roundEndDate = endDate.add(round * 7, 'day');

      if (repeat !== 'week') {
        roundStartDate = startDate.add(
          round * (differenceBewteenDates + 1),
          'days',
        );
        roundEndDate = endDate.add(
          round * (differenceBewteenDates + 1),
          'days',
        );
      }
      const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;
      const syncRecord: RoundTemplateKeys = {
        parentKey: toBeSyncedKey,
        childKeys: [],
      };

      result.push({
        roundTemplateKey: toBeSyncedKey,
        name: t('GENERAL.ROUND.TEMPLATE'),
        roundTemplateStatus: t('GENERAL.NEW'),
        startDate: roundStartDate.utc().toDate(),
        endDate: roundEndDate.utc().toDate(),
        roundDays: createEmptyRounds().map((roundDay) => {
          const toBeSyncedRoundDayKey = `${ROUND_DAY_KEY_PREFIX}${uuidv4()}`;

          syncRecord.childKeys.push(toBeSyncedRoundDayKey);

          return {
            ...roundDay,
            roundDayKey: toBeSyncedRoundDayKey,
            toBeSyncedKey: toBeSyncedRoundDayKey,
          };
        }),
        groupBatchId,
        toBeSyncedKey,
      });

      allSyncedKeys.push(syncRecord);
    }

    const updatedStateData: StateData = _.cloneDeep(stateData);

    // check date intervals intersection
    result.forEach((newRoundTemplate) => {
      updatedStateData.roundTemplates.forEach((rt) => {
        if (
          newRoundTemplate.roundTemplateKey !== rt.roundTemplateKey &&
          hasOverlappingDate(
            newRoundTemplate.startDate,
            newRoundTemplate.endDate,
            rt.startDate,
            rt.endDate,
          ) &&
          !responseString
        ) {
          responseString = t('GENERAL.ROUND.OVERLAPPING', {
            start: dayjs(newRoundTemplate.startDate).utc().format(DATE_FORMAT),
            end: dayjs(newRoundTemplate.endDate).utc().format(DATE_FORMAT),
          });
        }
      });
    });

    if (responseString) return responseString;

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return '';
    const { solutionKey } = chosenSolution.stateData;

    const payload: RoundType = {
      startDate: startDate.utc().toDate(),
      endDate: endDate.utc().toDate(),
      repeatType: repeat[0].toUpperCase() + repeat.slice(1),
      count: numberOfRounds,
    };

    result.forEach((round) => {
      syncRoundToStore({
        data: {
          ...round,
          roundDays: round.roundDays.map((rd) => ({
            ...rd,
            roundDayStatus: CREATED,
          })),
        },
        toBeSyncedKey: round.toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
        toBeUpdatedFields: {
          roundTemplateStatus: CREATED,
        },
      });
    });

    payload.toBeSyncedKeys = allSyncedKeys;
    payload.actualData = result;

    onEnqueue({
      type: 'CREATE_ROUND_TEMPLATE',
      payload: {
        data: payload,
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: solutionKey,
      },
    });

    return '';
  };

  const updateRound = (
    startDate: Dayjs,
    endDate: Dayjs,
    round: RoundTemplate,
  ): string => {
    let responseString = '';

    if (!stateData) return '';

    const differenceBewteenDates = endDate.diff(startDate, 'days');

    if (differenceBewteenDates < 0) {
      return t('GENERAL.ROUND.END_START_DATE_ERROR');
    }

    const updatedStateData: StateData = _.cloneDeep(stateData);

    updatedStateData.roundTemplates.forEach((rt) => {
      if (
        round.roundTemplateKey !== rt.roundTemplateKey &&
        hasOverlappingDate(
          startDate.utc().toDate(),
          endDate.utc().toDate(),
          rt.startDate,
          rt.endDate,
        ) &&
        !responseString
      ) {
        responseString = t('GENERAL.ROUND.OVERLAPPING', {
          start: dayjs(startDate).utc().format(DATE_FORMAT),
          end: dayjs(endDate).utc().format(DATE_FORMAT),
        });
      }
    });

    if (responseString) return responseString;

    const validWeekDaysNumber: number[] = getValidWeekDaysNumber(
      startDate,
      endDate,
    );

    const validRoundDays = getValidRoundDays(round, validWeekDaysNumber);

    const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;

    const roundPayload = {
      roundTemplateKey: round.roundTemplateKey,
      roundTemplateStatus: round.roundTemplateStatus,
      startDate: startDate.utc().toDate(),
      endDate: endDate.utc().toDate(),
      groupBatchId: round.groupBatchId,
      name: round.name,
      toBeSyncedKey,
    };

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return '';
    const { solutionKey } = chosenSolution.stateData;

    syncRoundToStore({
      data: {
        ...round,
        roundDays: validRoundDays,
        startDate: startDate.utc().toDate(),
        endDate: endDate.utc().toDate(),
        toBeSyncedKey,
      },
      toBeSyncedKey,
      solutionKey: chosenSolution.solutionKey,
    });

    onEnqueue({
      type: 'UPDATE_ROUND_TEMPLATE',
      payload: {
        data: [
          {
            ...roundPayload,
            roundDays: validRoundDays,
          },
        ] as RoundTemplate[],
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: solutionKey,
      },
    });

    return responseString;
  };

  const saveSlot = (
    slot: TimeSlot,
    roundTemplateKey: string,
    weekDay: number,
  ) => {
    if (stateData) {
      const updatedStateData: StateData = _.cloneDeep(stateData);

      let roundDayKey = '';

      let isValidWeekdaySlot = false;

      updatedStateData.roundTemplates.forEach((item) => {
        if (item.roundTemplateKey === roundTemplateKey) {
          item.roundDays.forEach((roundDay) => {
            if (
              roundDay.weekDay === weekDay &&
              !roundDay.timeSlots.find(
                (ts) => ts.networkCategoryKey === slot.networkCategoryKey,
              )
            ) {
              roundDayKey = roundDay.roundDayKey;
              isValidWeekdaySlot = true;
            }
          });
        }
      });

      if (!isValidWeekdaySlot) return;

      const toBeSyncedKey = `${TIME_SLOT_KEY_PREFIX}${uuidv4()}`;
      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;
      const { solutionKey } = chosenSolution.stateData;

      const slotPayload = {
        timeSlotKey: toBeSyncedKey,
        timeSlotText: slot.timeSlotText,
        networkCategoryKey: slot.networkCategoryKey,
        networkKey: slot.networkKey,
        status: slot.timeSlotStatus,
        roundTemplateKey,
        roundDayKey,
      };

      syncTimeSlotToStore({
        data: {
          timeSlotKey: toBeSyncedKey,
          timeSlotText: slot.timeSlotText,
          networkCategoryKey: slot.networkCategoryKey,
          networkKey: slot.networkKey,
          timeSlotStatus: CREATED,
          timeSlotId: toBeSyncedKey,
          toBeSyncedKey,
        },
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
        roundTemplateKey,
        roundDayKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_TIME_SLOT',
        payload: {
          data: {
            ...slotPayload,
            toBeSyncedKey,
          },
          solutionKey: chosenSolution.solutionKey, // local solution key
          actualSolutionKey: solutionKey,
        },
      });
    }
  };

  const saveSlotToAll = async (slot: TimeSlot) => {
    if (!hasValidSlot(slot)) return;

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const updatedStateData: StateData = _.cloneDeep(chosenSolution.stateData);

    updatedStateData.roundTemplates = updatedStateData.roundTemplates.map(
      (item) => {
        const validWeekDaysNumber: number[] = getValidWeekDaysNumber(
          dayjs(item.startDate).utc(),
          dayjs(item.endDate).utc(),
        );

        let isCreated = false;

        item.roundDays = item.roundDays.map((roundDay) => {
          if (
            validWeekDaysNumber.includes(roundDay.weekDay) &&
            !roundDay.timeSlots.find(
              (ts) => ts.networkCategoryKey === slot.networkCategoryKey,
            )
          ) {
            const syncedKey = `${TIME_SLOT_KEY_PREFIX}${uuidv4()}`;
            roundDay.timeSlots.push({
              ...slot,
              timeSlotKey: syncedKey,
              timeSlotId: syncedKey,
              toBeSyncedKey: syncedKey,
            });
            isCreated = true;
          }

          return roundDay;
        });

        if (isCreated) {
          const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;
          item.toBeSyncedKey = toBeSyncedKey;

          syncRoundToStore({
            data: item,
            toBeSyncedKey,
            solutionKey: chosenSolution.solutionKey,
            toBeUpdatedFields: {
              roundTemplateStatus: CREATED,
            },
          });
        }

        return item;
      },
    );

    onEnqueue({
      type: 'UPDATE_ROUND_TEMPLATE',
      payload: {
        data: updatedStateData.roundTemplates as RoundTemplate[],
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });
  };

  const saveSlotToSpecificDay = async (
    slot: TimeSlot,
    day: keyof typeof WeekDayMap,
  ) => {
    if (!hasValidSlot(slot, day)) return;

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const updatedStateData: StateData = _.cloneDeep(chosenSolution.stateData);
    const weekDay = WeekDayMap[day];

    updatedStateData.roundTemplates = updatedStateData.roundTemplates.map(
      (item) => {
        const validWeekDaysNumber: number[] = getValidWeekDaysNumber(
          dayjs(item.startDate).utc(),
          dayjs(item.endDate).utc(),
        );

        let isCreated = false;

        item.roundDays = item.roundDays.map((roundDay) => {
          if (
            roundDay.weekDay === weekDay &&
            validWeekDaysNumber.includes(roundDay.weekDay) &&
            !roundDay.timeSlots.find(
              (ts) => ts.networkCategoryKey === slot.networkCategoryKey,
            )
          ) {
            isCreated = true;
            const syncedKey = `${TIME_SLOT_KEY_PREFIX}${uuidv4()}`;
            roundDay.timeSlots.push({
              ...slot,
              timeSlotKey: syncedKey,
              timeSlotId: syncedKey,
              toBeSyncedKey: syncedKey,
            });
          }

          return roundDay;
        });

        if (isCreated) {
          const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;
          item.toBeSyncedKey = toBeSyncedKey;

          syncRoundToStore({
            data: item,
            toBeSyncedKey,
            solutionKey: chosenSolution.solutionKey,
            toBeUpdatedFields: {
              roundTemplateStatus: CREATED,
            },
          });
        }

        return item;
      },
    );

    onEnqueue({
      type: 'UPDATE_ROUND_TEMPLATE',
      payload: {
        data: updatedStateData.roundTemplates as RoundTemplate[],
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });
  };

  const updateRoundWeekStart = (roundTemplateWeekStartDay: number) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    syncRoundWeekStartDay({
      roundTemplateWeekStartDay,
      solutionKey: chosenSolution.solutionKey,
    });

    onEnqueue({
      type: 'UPDATE_ROUND_WEEK_START',
      payload: {
        data: {
          roundTemplateWeekStartDay,
        },
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });
  };

  const deleteSlot = ({
    roundTemplateKey,
    roundDayKey,
    timeSlotKey,
  }: {
    roundTemplateKey: string;
    roundDayKey: string;
    timeSlotKey: string;
  }) => {
    if (stateData) {
      const updatedStateData: StateData = _.cloneDeep(stateData);
      let toBeDeletedSlot: TimeSlot | undefined;

      const roundTemplateKeyIndex = updatedStateData.roundTemplates.findIndex(
        (item) => item.roundTemplateKey === roundTemplateKey,
      );

      let roundDayKeyIndex = -1;
      let slotKeyIndex = -1;

      if (roundTemplateKeyIndex !== -1) {
        roundDayKeyIndex = updatedStateData.roundTemplates[
          roundTemplateKeyIndex
        ].roundDays.findIndex((rd) => rd.roundDayKey === roundDayKey);

        const roundDay =
          updatedStateData.roundTemplates[roundTemplateKeyIndex].roundDays[
            roundDayKeyIndex
          ];

        if (roundDay) {
          slotKeyIndex = roundDay.timeSlots.findIndex(
            (ts) => ts.timeSlotKey === timeSlotKey,
          );

          if (slotKeyIndex !== -1) {
            toBeDeletedSlot = roundDay.timeSlots[slotKeyIndex];
          }
        }
      }

      if (toBeDeletedSlot) {
        const slotPayload = {
          timeSlotKey,
          timeSlotText: toBeDeletedSlot.timeSlotText,
          networkCategoryKey: toBeDeletedSlot.networkCategoryKey,
          networkKey: toBeDeletedSlot.networkKey,
          status: DELETED,
          roundTemplateKey,
          roundDayKey,
        };

        const toBeSyncedKey = `${TIME_SLOT_KEY_PREFIX}${uuidv4()}`;
        const chosenSolution = getCurrentOrClonedSolution();

        if (!chosenSolution) return;
        const { solutionKey } = chosenSolution.stateData;

        syncTimeSlotToStore({
          data: {
            timeSlotKey,
            timeSlotText: toBeDeletedSlot.timeSlotText,
            networkCategoryKey: toBeDeletedSlot.networkCategoryKey,
            networkKey: toBeDeletedSlot.networkKey,
            timeSlotId: toBeDeletedSlot.timeSlotId,
            timeSlotStatus: DELETED,
            toBeSyncedKey,
          },
          toBeSyncedKey,
          solutionKey: chosenSolution.solutionKey,
          roundTemplateKey,
          roundDayKey,
        });

        onEnqueue({
          type: 'CREATE_UPDATE_TIME_SLOT',
          payload: {
            data: {
              ...slotPayload,
              toBeSyncedKey,
            },
            solutionKey: chosenSolution.solutionKey, // local solution key
            actualSolutionKey: solutionKey,
          },
        });
      }
    }
  };

  return {
    createNewSlot,
    hasOverlappingDate,
    saveRounds,
    updateEntireRound,
    saveSlot,
    saveSlotToAll,
    updateRoundWeekStart,
    createEmptyRounds,
    updateRound,
    deleteSlot,
    saveSlotToSpecificDay,
    hasValidSlot,
  };
};

export default useRoundCreate;
