import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioNetworkResponsePromiseType } from 'Models/Scenario';
import { QueueCreateUpdateNetworkPayloadType } from 'Models/Queue';
import { syncNetwork, saveNetwork } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED, DELETED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { Network } from 'Models/Network';
import { getScenarioKey } from 'utils/ui-helper';
import { useAppDispatch } from '../hooks';

export const useNetwork = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareNetworkPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateNetworkPayloadType): Network => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.networkKey]) {
      newPayload.networkKey =
        queueLocalKeyMapRef.current[newPayload.networkKey];
    }

    // check setline for local Key
    newPayload.networkCategories = newPayload.networkCategories.map(
      (networkCategory) => {
        if (queueLocalKeyMapRef.current[networkCategory.networkCategoryKey]) {
          networkCategory.networkCategoryKey =
            queueLocalKeyMapRef.current[networkCategory.networkCategoryKey];
        }

        if (toBeUpdatedFields?.networkStatus === DELETED) {
          networkCategory.networkCategoryStatus = DELETED;
        }

        return networkCategory;
      },
    );

    return newPayload;
  };

  const syncNetworkToStore = ({
    data,
    toBeUpdatedFields,
    solutionKey,
  }: QueueCreateUpdateNetworkPayloadType): void => {
    dispatch(
      syncNetwork({
        data: prepareNetworkPayload({
          data,
          toBeUpdatedFields,
        }),
        solutionKey,
      }),
    );
  };

  const createOrUpdateNetworkQueue = ({
    data,
    toBeUpdatedFields,
  }: {
    data: Network;
    toBeUpdatedFields: {
      [key in keyof Network]?: Network[key];
    };
    toBeSyncedKey: string;
  }): Promise<ScenarioNetworkResponsePromiseType | null> => {
    const preparedData = prepareNetworkPayload({
      data,
      toBeUpdatedFields,
    });

    const scenarioKey = getScenarioKey(preparedData.networkKey);

    if (preparedData) {
      return dispatch(
        saveNetwork({
          scenarioKey,
          solutionKey: preparedData.solutionKey ?? '',
          network: preparedData,
          solutionStatus: CREATED,
        }),
      ) as Promise<ScenarioNetworkResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareNetworkPayload,
    syncNetworkToStore,
    createOrUpdateNetworkQueue,
  };
};

export default useNetwork;
