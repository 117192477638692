import React, { useMemo, useEffect, useState } from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { ChevronDown, ChevronUp, ThreeDots } from 'Components/Elements/Icons';
import useScenario from 'storeHooks/useScenario';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { clearCellsHilighting } from 'Components/Scenarios/EditScenario/Solve/Solve/SelectSolveTableCells';
import {
  cloneSolution,
  cloneScenarioWithSolution,
  deleteSolution,
} from 'store/slices/solutionSlice';
import { useAppDispatch } from 'storeHooks/hooks';
import SolutionItem from './SolutionItem';
import showMessage from '../Common/Message';
import './style.scss';
import { Solution } from 'Models/Scenario';

import useNetwork from 'storeHooks/useNetwork';
import { getNthElement, getScenarioKey } from 'utils/ui-helper';
import { Color } from 'Models/Network';
import { colors } from '@mui/material';
import fontColorContrast from 'Library/font-color-contrast';
import {
  SolveDataOptimizationStatusCutoff,
  SolveDataOptimizationStatusError,
  SolveDataOptimizationStatusInfeasible,
  SolveDataOptimizationStatusMaxIterationsExceeded,
  SolveDataOptimizationStatusOptimal,
  SolveDataOptimizationStatusStoppedByUser,
  SolveDataOptimizationStatusUnknown,
} from 'Models/SolveDataOptimizationStatus';
import {
  SolutionStatusCreated,
  SolutionStatusSolveRequested,
  SolutionStatusSolving,
  SolutionStatusStopRequested,
  SolutionStatusStopped,
  SolutionStatusStopping,
} from 'Models/SolutionStatuses';

const rootId = 'SolutionOptions';

export default function Solutions(): JSX.Element {
  const { t } = useTranslation();
  const { scenario, currentScenarioKey, solutionKey } = useScenario();
  const { goToScenario } = useNetwork();
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { show, hideAll } = useContextMenu({
    id: rootId,
  });

  const onChange = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKey(key);
    }
  };

  const onChangeSolveDataKey = (key: string) => {
    clearCellsHilighting();
    const scenarioKey = getScenarioKey(key);
    const season = getNthElement(key, 1);
    navigate(`/scenario/${season}/${scenarioKey}?solveKey=${key}`);
  };

  const handleCloneSolution = async (_solutionKey: string | null) => {
    if (_solutionKey) {
      showMessage({
        key: _solutionKey,
        content: t('GENERAL.FEEDBACK.INSTANCE.COPYING'),
      });

      const res = await dispatch(
        cloneSolution({
          scenarioKey: currentScenarioKey,
          solutionKey: _solutionKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: _solutionKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.INSTANCE.COPIED'),
        });
      } else {
        showMessage({
          key: _solutionKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.INSTANCE.COPY_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleDeleteSolution = async (_solutionKey: string | null) => {
    if (_solutionKey) {
      showMessage({
        key: _solutionKey,
        content: t('GENERAL.FEEDBACK.INSTANCE.DELETING'),
      });

      const res = await dispatch(
        deleteSolution({
          scenarioKey: currentScenarioKey,
          solutionKey: _solutionKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: _solutionKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.INSTANCE.DELETED'),
        });
      } else {
        showMessage({
          key: _solutionKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.INSTANCE.DELETION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCloneScenarioWithSolution = async (
    _solutionKey: string | null,
  ) => {
    if (_solutionKey) {
      showMessage({
        key: _solutionKey,
        content: t('GENERAL.FEEDBACK.SCENARIO.CREATING'),
      });

      const res = await dispatch(
        cloneScenarioWithSolution({
          scenarioKey: currentScenarioKey,
          solutionKey: _solutionKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: _solutionKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATED'),
        });
      } else {
        showMessage({
          key: _solutionKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  function displayMenu(e: React.MouseEvent, _solutionKey: string) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({ event: e, props: { solutionKey: _solutionKey } });
  }

  const genExtra = (_solutionKey: string, color: string) => (
    <div className="flex items-center gap-2" data-testid="solution-action-menu">
      <span
        aria-valuetext={_solutionKey}
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          displayMenu(e, _solutionKey);
        }}
      >
        <ThreeDots className="w-4" color={color} />
        <Menu id={rootId}>
          <Item
            onClick={(event) => {
              handleCloneSolution(event.props.solutionKey);
              hideAll();
            }}
            data-testid="copy-item"
          >
            {t('GENERAL.COPY')}
          </Item>
          <Item
            onClick={(event) => {
              handleDeleteSolution(event.props.solutionKey);
              hideAll();
            }}
          >
            {t('GENERAL.DELETE')}
          </Item>
          <Separator />
          <Item
            onClick={(event) => {
              handleCloneScenarioWithSolution(event.props.solutionKey);
              hideAll();
            }}
          >
            {t('GENERAL.SCENARIO.CREATE_FROM_INSTANCE')}
          </Item>
        </Menu>
      </span>
    </div>
  );

  const getOptimizationStatus = (solution: Solution): string => {
    if (solution.solutionStatus === SolutionStatusStopped) {
      return ` (${solution.solveDataOptimizationStatus})`;
    }

    return '';
  };

  function getSolutionBgColor(solution: Solution): Color {
    if (solution.solutionStatus === SolutionStatusCreated) {
      return colors.yellow[50];
    }

    if (
      solution.solutionStatus === SolutionStatusSolveRequested ||
      solution.solutionStatus === SolutionStatusSolving ||
      solution.solutionStatus === SolutionStatusStopRequested ||
      solution.solutionStatus === SolutionStatusStopping
    ) {
      return colors.blue[200];
    }

    if (solution.solutionStatus === SolutionStatusStopped) {
      switch (solution.solveDataOptimizationStatus) {
        case SolveDataOptimizationStatusUnknown:
        case undefined:
          return colors.teal[50];
        case SolveDataOptimizationStatusOptimal:
          return colors.teal[500];
        case SolveDataOptimizationStatusInfeasible:
          return colors.grey[700];
        case SolveDataOptimizationStatusError:
          return colors.red[500];
        case SolveDataOptimizationStatusCutoff:
          return colors.red[200];
        case SolveDataOptimizationStatusStoppedByUser:
          return colors.red[100];
        case SolveDataOptimizationStatusMaxIterationsExceeded:
          return colors.green[800];
        default:
          throw new Error(
            `Unknown optimization status:
            ${solution.solveDataOptimizationStatus}`,
          );
      }
    }

    throw new Error(
      `Unknown solution color for status: ${solution.solutionStatus}`,
    );
  }

  const items: CollapseProps['items'] = useMemo(() => {
    if (scenario?.optimizationEnvelop) {
      return scenario.optimizationEnvelop.solutions.map((solution) => {
        const backgroundColor = getSolutionBgColor(solution);
        const color = fontColorContrast(backgroundColor);
        const solutionTip =
          `${t('GENERAL.STATUS')}: ${
            solution.solutionStatus
          } ${getOptimizationStatus(solution)} ` +
          `${
            solution.solveType
              ? `${'\n'}${t('GENERAL.TYPE')}: ${solution.solveType}`
              : ''
          }`;

        return {
          key: solution.solutionKey,
          style: { backgroundColor, color },
          label: `${t('GENERAL.INSTANCE')} ${solution.solutionIndex}`,
          title: solutionTip,
          children: (
            <SolutionItem
              solution={solution}
              onChange={onChangeSolveDataKey}
              backgroundColor={backgroundColor}
              color={color}
            />
          ),
          extra: genExtra(solution.solutionKey, color),
        };
      });
    }
    return [];
  }, [scenario]);

  const expandIcon = ({
    isActive,
    panelKey,
  }: {
    isActive: boolean;
    panelKey: string;
  }) => {
    let color = '#0F1316';

    if (scenario) {
      const thisSolution = scenario.optimizationEnvelop.solutions.find(
        (item) => item.solutionKey === panelKey,
      );

      if (thisSolution) {
        const backgroundColor = getSolutionBgColor(thisSolution);
        color = fontColorContrast(backgroundColor);
      }
    }

    return isActive ? (
      <ChevronUp className="w-4" color={color} />
    ) : (
      <ChevronDown className="w-4" color={color} />
    );
  };

  useEffect(() => {
    if (solutionKey) {
      setActiveKey([solutionKey]);
    } else {
      setActiveKey([]);
    }
  }, [solutionKey]);

  useEffect(() => {
    // @ts-expect-error
    document.appNavigate = navigate;
  }, []);

  return (
    <div>
      {t('GENERAL.SOLUTION.TITLE', {
        count: 2,
      })}
      <Collapse
        className="app-solutions"
        data-testid="app-solutions"
        items={items}
        activeKey={activeKey}
        onChange={onChange}
        expandIconPosition="end"
        bordered={false}
        // @ts-expect-error
        expandIcon={expandIcon}
      />
    </div>
  );
}
