import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { TrophyOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from 'ag-grid-react';
import useLocale from 'locales/localeMapGrid';
import { getFormattedDateWithTime } from 'utils/date-helper';
import Select from 'react-select';
import { Search, ArrowLeft, ArrowRight } from 'Components/Elements/Icons';
import { getSeasons } from 'Components/Common/TimeUtils';
import { useSelector } from 'react-redux';
import useScenario from 'storeHooks/useScenario';
import { ScenarioClone, ScenarioCreateEdit } from 'Components/Modules/Scenario';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import { ScenarioMetaData } from 'Models/Scenario';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import './Scenario.scss';

type MyOption = { label: string; value: number };
interface CellParamType {
  data: ScenarioMetaData;
  colDef: {
    cellClass: string;
    headerName: string;
  };
}

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const currentYear = new Date().getFullYear();

const baseSeason: MyOption = {
  value: currentYear,
  label: currentYear.toString(),
};

const numberOptions = [
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

const selectStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
};

function ScenariosTable(): JSX.Element {
  const { t } = useTranslation();
  const [loadingScenariosMeta, setLoadingScenariosMeta] = useState(false);
  const {
    selectedSeason,
    setSelectedSeason,
    scenarioMetas,
    getMetas,
    onDeleteScenario,
  } = useScenario();
  const gridRef = useRef();
  const navigate = useNavigate();
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);
  const { scenarioName, results, modifiedAt } = useLocale();
  const [scenarioPerPage, setScenarioPerPage] = useState(numberOptions[0]);
  const [scenario, setScenario] = useState<ScenarioMetaData | null>(null);
  const [season, setSeason] = useState(baseSeason);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState('1');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const isLoading = useRef(false);

  useEffect(() => {
    if (selectedSeason) {
      setSeason({
        value: selectedSeason,
        label: selectedSeason.toString(),
      });
    }
  }, [selectedSeason]);

  const openCloneScenarioModal = (item: ScenarioMetaData) => {
    setScenario(item);
    setIsCloneModalVisible(true);
  };

  const openEditScenarioModal = (item: ScenarioMetaData) => {
    setScenario(item);
    setIsEditModalVisible(true);
  };

  const [columnDefs] = useState([
    {
      maxWidth: 80,
      sortable: false,
      cellRenderer: (event: CellParamType) => {
        const { data } = event;

        return (
          <div
            className="flex items-center justify-center pt-1"
            onClick={() =>
              navigate(
                `/scenario/${event.data.season}/${event.data.scenarioKey}`,
              )
            }
          >
            {data.logo ? (
              <Avatar shape="circle" src={data.logo} />
            ) : (
              <Avatar shape="circle" icon={<TrophyOutlined />} />
            )}
          </div>
        );
      },
      resizable: false,
    },
    {
      field: 'name',
      headerName: scenarioName,
      editable: false,
    },
    { field: 'results', headerName: results, editable: false },
    {
      field: 'lastModifiedDate',
      headerName: modifiedAt,
      editable: false,
      valueFormatter: (p: { value: Date }) => getFormattedDateWithTime(p.value),
      sort: 'desc',
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: 'edit-icon',
      maxWidth: 40,
      onCellClicked: (event: CellParamType) => {
        if (event.colDef.cellClass === 'edit-icon') {
          openEditScenarioModal(event.data);
        }
      },
      resizable: false,
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: 'clone-icon',
      maxWidth: 40,
      onCellClicked: (event: CellParamType) => {
        if (event.colDef.cellClass === 'clone-icon') {
          openCloneScenarioModal(event.data);
        }
      },
      resizable: false,
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 40,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={t('GENERAL.SCENARIO.DELETE.TITLE')}
            onConfirm={() => onDeleteScenario(event.data.scenarioKey)}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;
        onDeleteScenario(event.data.scenarioKey);
      },
      resizable: false,
    },
  ]);

  const availableSeasons = useMemo(
    () => getSeasons().map((item) => ({ label: item, value: Number(item) })),
    [],
  );
  const validPageNumber = useMemo(() => {
    const pageNumber = Number(page);

    return pageNumber > 0 ? pageNumber : 1;
  }, [page]);
  const filteredScenarios = useMemo(() => {
    const validQuery = query.toLowerCase().trim();

    if (validQuery) {
      return scenarioMetas.filter((item) =>
        item.name.toLowerCase().includes(validQuery),
      );
    }

    return scenarioMetas;
  }, [scenarioMetas, query]);

  const visibleScenarios = useMemo(() => {
    const start = (validPageNumber - 1) * scenarioPerPage.value;
    const end = start + scenarioPerPage.value;

    return filteredScenarios.slice(start, end);
  }, [filteredScenarios, scenarioPerPage, validPageNumber]);

  const totalNumberOfPages = Math.ceil(
    filteredScenarios.length / scenarioPerPage.value,
  );

  const hasNextPage =
    filteredScenarios.length > validPageNumber * scenarioPerPage.value;
  const hasPrevPage =
    scenarioPerPage.value < validPageNumber * scenarioPerPage.value;

  const onCellClicked = (event: CellParamType) => {
    if (event.colDef.headerName) {
      navigate(`/scenario/${event.data.season}/${event.data.scenarioKey}`);
    }
  };

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onHandleNextPage = () => {
    setPage((validPageNumber + 1).toString());
  };

  const onHandlePrevPage = () => {
    setPage((validPageNumber - 1).toString());
  };

  const onPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(event.target.value);
  };

  const onShowCurrentPage = () => {
    setPage(validPageNumber.toString());
  };

  const onChangePerPage = (selected?: MyOption | null) => {
    if (selected) {
      setScenarioPerPage(selected);
    }
  };

  const onSeasonPage = (selected?: MyOption | null) => {
    if (selected) {
      setSeason(selected);
      setSelectedSeason(selected.value);
    }
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  const resetScenario = () => {
    setScenario(null);
    setIsCloneModalVisible(false);
    setIsEditModalVisible(false);
  };

  useEffect(() => {
    async function getScenariosMeta() {
      if (selectedSeason && !isLoading.current) {
        setLoadingScenariosMeta(true);
        setPage('1');
        isLoading.current = true;
        await getMetas(selectedSeason);
        setLoadingScenariosMeta(false);
        isLoading.current = false;
      }
    }

    getScenariosMeta();
  }, [selectedSeason]);

  return (
    <div className="scenarios-container">
      <div className="filters-container flex justify-between items-center px-3 pt-3 pb-1">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <Search />
          </div>
          <input
            type="text"
            id="input-group-1"
            className="w-80 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  "
            placeholder={t('GENERAL.SEARCH')}
            value={query}
            onChange={onQueryChange}
          />
        </div>

        <Select
          options={availableSeasons}
          className="w-52"
          styles={selectStyles}
          value={season}
          onChange={onSeasonPage}
        />
      </div>

      <div className="scenarios-table">
        <div
          className="ag-theme-alpine mt-2 ag-grid-white no-x-border"
          style={{
            height: 580,
          }}
        >
          {/* @ts-ignore */}
          <AgGridReact
            ref={gridRef}
            loading={loadingScenariosMeta}
            rowData={visibleScenarios}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={(params) => params.data.scenarioKey}
            animateRows
            readOnlyEdit
            rowSelection="single"
            onCellClicked={onCellClicked}
            enterNavigatesVerticallyAfterEdit
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>

      <footer>
        <Select
          value={scenarioPerPage}
          options={numberOptions}
          onChange={onChangePerPage}
          className="w-24"
          menuPlacement="top"
          styles={selectStyles}
        />

        <div className="flex gap-2 justify-evenly items-center ">
          <input
            type="text"
            className="w-24 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-1.5  "
            value={page}
            onChange={onPageChange}
            onBlur={onShowCurrentPage}
          />

          <span className="w-24">
            {t('GENERAL.OFF_PAGES', {
              count: totalNumberOfPages,
            })}
          </span>

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="w-12 rounded-l-lg border border-blue-700 flex justify-center items-center p-1"
              onClick={onHandlePrevPage}
              disabled={!hasPrevPage}
            >
              <ArrowLeft color={!hasPrevPage ? '#F1EFEF' : '#808283'} />
            </button>

            <button
              type="button"
              className="w-12 rounded-r-lg border border-blue-700 flex justify-center items-center p-1"
              onClick={onHandleNextPage}
              disabled={!hasNextPage}
            >
              <ArrowRight color={!hasNextPage ? '#F1EFEF' : '#808283'} />
            </button>
          </div>
        </div>
      </footer>

      {scenario && isCloneModalVisible && (
        <ScenarioClone scenario={scenario} onCancel={resetScenario} />
      )}

      {scenario && isEditModalVisible && (
        <ScenarioCreateEdit scenario={scenario} onCancel={resetScenario} />
      )}
    </div>
  );
}

export default ScenariosTable;
