// @ts-nocheck
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useDeferredValue,
} from 'react';
import { renderToString } from 'react-dom/server';
import moment from 'moment';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { RoundTemplate } from 'Models/Scenario';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import { Plus, EditObject, Pin } from 'Components/Elements/Icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import useScenario from 'storeHooks/useScenario';
import { FullCalenderStartDayMap, WeekDayMap } from 'utils/variables';
import RoundCreatePopover from './Rounds/RoundCreatePopover';
import RoundEvent from './Rounds/RoundEvent';
import ScenarioStartDatePopover from './Rounds/ScenarioStartDatePopover';
import useRoundCreate from './Rounds/useRoundCreate';
import './style.scss';

dayjs.extend(minMax);
dayjs.extend(utc);

type CalenderDataType = {
  title: string;
  start: string;
  end: string;
  data: RoundTemplate;
};

type CellOffsetType = {
  offsetLeft: number;
  offsetTop: number;
};

type InitialDateProp = {
  start: Dayjs;
  end: Dayjs;
};

type DateMapType = Record<string, boolean>;

const CALENDAR_CELL_DATE_FORMAT = 'MMMM D, YYYY';
const YEAR_FORMAT = 'YYYY';
const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_TITLE = 'DD MMM YYYY';
const CALENDAR_DATE_FORMAT = 'D MMM, YYYY';

export const getDefaultCalenderTitle = () => {
  const currentYear = Number(dayjs().utc().format(YEAR_FORMAT));

  return `${currentYear} - ${currentYear + 1}`;
};

export const scrollToCell = (date: Dayjs) => {
  const formattedDate = date.format(CALENDAR_CELL_DATE_FORMAT);

  const targetElement = document.querySelector(
    `[aria-label="${formattedDate}"]`,
  );

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const getPopoverOffset = (eventY: number) => {
  if (eventY < 300) {
    return eventY * 2;
  }
  if (eventY < 400) {
    return eventY - 50;
  }

  if (eventY < 500) {
    return 300;
  }

  if (eventY < 600) {
    return 200;
  }

  return 100;
};

export default function RoundsTab(): JSX.Element {
  const { stateData } = useScenario();
  const { updateRoundWeekStart } = useRoundCreate();
  const { t } = useTranslation();
  const calendarRef = useRef(null);
  const [title, setTitle] = useState(getDefaultCalenderTitle());
  const deferredTitle = useDeferredValue(title);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [scenarioStartPopoverOpen, setScenarioStartPopoverOpen] =
    useState(false);
  const [isCellPopoverOpen, setCellPopoverOpen] = useState(false);
  const [calenderData, setCalenderData] = useState<CalenderDataType[]>([]);
  const [offset, setOffset] = useState<CellOffsetType>({
    offsetLeft: 0,
    offsetTop: 0,
  });
  const [inititalDate, setInitialDate] = useState<InitialDateProp>({
    start: dayjs().utc(),
    end: dayjs().utc(),
  });

  const existingRoundDate = useRef<DateMapType>({});

  const roundTemplates = useMemo(() => {
    if (stateData) {
      return stateData.roundTemplates.filter(
        (item) => item.roundTemplateStatus !== 'Deleted',
      );
    }

    return [];
  }, [stateData]);

  const calenderRange = useMemo(() => {
    const allEventDays: Dayjs[] = roundTemplates.reduce(
      (acc: Dayjs[], item) => [
        ...acc,
        dayjs(item.startDate).utc(),
        dayjs(item.endDate).utc(),
      ],
      [],
    );

    const newlyAddedRounds: Dayjs[] = [];

    allEventDays.forEach((eventDate) => {
      if (!existingRoundDate.current[eventDate.format(DATE_FORMAT)]) {
        newlyAddedRounds.push(eventDate);
      }
    });

    existingRoundDate.current = allEventDays.reduce((acc, item) => {
      acc[item.format(DATE_FORMAT)] = true;
      return acc;
    }, {} as DateMapType);

    if (allEventDays.length > 0) {
      const startDate = dayjs.min(allEventDays);
      const endDate = dayjs.max(allEventDays);

      const weeksRange = endDate.diff(startDate, 'weeks') + 1;
      const today = dayjs().utc();
      const maxRoundDate = dayjs.max([today, endDate]);
      let hasFocusMoved = false;

      const yearDifference = Math.abs(
        parseInt(maxRoundDate?.format(YEAR_FORMAT), 16) -
          parseInt(startDate?.format(YEAR_FORMAT), 16),
      );

      let NumberOfYears = yearDifference + 1;

      if (newlyAddedRounds.length > 0) {
        if (calendarRef.current?.calendar) {
          hasFocusMoved = true;
          setTimeout(() => {
            calendarRef.current?.calendar.render();

            if (
              Number(dayjs.min(newlyAddedRounds)?.format(YEAR_FORMAT)) <
              Number(today.format(YEAR_FORMAT))
            ) {
              calendarRef.current?.calendar.gotoDate(
                dayjs.min(newlyAddedRounds).$d,
              );
            } else {
              scrollToCell(dayjs.min(newlyAddedRounds));
            }
          }, 100);
        }
      }

      const differenceBetweenCurrentYearAndEndYear =
        parseInt(endDate?.format(YEAR_FORMAT), 16) -
        parseInt(today.format(YEAR_FORMAT), 16);

      const differenceBetweenCurrentYearAndStartYear =
        parseInt(startDate?.format(YEAR_FORMAT), 16) -
        parseInt(today.format(YEAR_FORMAT), 16);

      if (today.format(YEAR_FORMAT) === endDate?.format(YEAR_FORMAT)) {
        NumberOfYears++;
      } else if (differenceBetweenCurrentYearAndEndYear < 0) {
        NumberOfYears += Math.abs(differenceBetweenCurrentYearAndEndYear);
      } else if (differenceBetweenCurrentYearAndStartYear > 0) {
        NumberOfYears += Math.abs(differenceBetweenCurrentYearAndStartYear);
      }

      let calenderFocusDate = startDate.$d;

      if (
        Number(today.format(YEAR_FORMAT)) <
        Number(startDate.format(YEAR_FORMAT))
      ) {
        calenderFocusDate = today.$d;

        if (!hasFocusMoved) {
          setTimeout(() => {
            calendarRef.current?.calendar.render();
            // after the deletion of a round we scroll to the nearest available round
            scrollToCell(startDate);
          }, 100);
        }
      }

      return {
        start: calenderFocusDate,
        weeks: weeksRange > 4 ? weeksRange : 4,
        end: endDate.$d,
        NumberOfYears,
      };
    }

    return {
      start: null,
      weeks: 4,
      end: null,
      NumberOfYears: 2,
    };
  }, [roundTemplates]);

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const toggleCellPopover = () => {
    setCellPopoverOpen((prev) => !prev);
  };

  const toggleScenarioPopover = () => {
    setScenarioStartPopoverOpen((prev) => !prev);
  };

  const closePopovers = () => {
    setPopoverOpen(false);
    setCellPopoverOpen(false);
    setScenarioStartPopoverOpen(false);
  };

  const createPopover = () => <RoundCreatePopover onClose={closePopovers} />;

  const createCellPopover = () => (
    <RoundCreatePopover
      onClose={closePopovers}
      start={inititalDate.start}
      end={inititalDate.end}
    />
  );

  const scenarioStartDatePopover = () => (
    <ScenarioStartDatePopover
      onClose={closePopovers}
      start={dayjs(calenderRange.start).utc()}
    />
  );

  useEffect(() => {
    setCalenderData(
      roundTemplates.reduce((acc: CalenderDataType[], item) => {
        const currentAcc = [...acc];

        let label = '';

        if (dayjs(item.startDate).isSame(item.endDate, 'day')) {
          label = `${moment(item.startDate).utc().format(DATE_FORMAT_TITLE)}`;
        } else {
          label = t('GENERAL.ROUND.DATE', {
            start: moment(item.startDate).utc().format(DATE_FORMAT_TITLE),
            end: moment(item.endDate).utc().format(DATE_FORMAT_TITLE),
          });
        }

        currentAcc.push({
          title: label,
          start: moment(item.startDate).utc().format(DATE_FORMAT),
          end: moment(item.endDate).utc().add(1, 'day').format(DATE_FORMAT),
          data: item,
        });

        return currentAcc;
      }, []),
    );
  }, [roundTemplates]);

  const onCalenderLoad = () => {
    if (calendarRef.current?.calendar) {
      setTitle(calendarRef.current.calendar.currentData.viewTitle);
    }
  };

  const onDateClick = (info) => {
    closePopovers();

    setInitialDate({
      start: dayjs(`${info.startStr}T12:00:00Z`),
      end: dayjs(`${info.endStr}T12:00:00Z`).subtract(1, 'day'),
    });

    setOffset({
      offsetLeft: info.jsEvent.x,
      offsetTop: getPopoverOffset(info.jsEvent.y),
    });

    setTimeout(() => toggleCellPopover(), 100);
  };

  const eventRender = (arg) => {
    const eventRound = arg.event.extendedProps.data;

    return (
      <RoundEvent
        title={arg.event.title}
        roundTemplateKey={eventRound.roundTemplateKey}
        templates={roundTemplates}
      />
    );
  };

  useEffect(() => {
    const headerColumnCells = document.querySelectorAll('.fc-col-header-cell');
    const existingPin = document.querySelectorAll('.pin');

    for (let i = 0; i < existingPin.length; i++) {
      existingPin[i].remove();
    }

    for (let i = 0; i < headerColumnCells.length; i++) {
      const { innerText } = headerColumnCells[i];
      const togglePinElement = document.createElement('span');
      togglePinElement.classList.add('pin');
      togglePinElement.innerHTML = renderToString(<Pin />);

      togglePinElement.addEventListener('click', () => {
        updateRoundWeekStart(WeekDayMap[innerText] ?? 1);
      });

      headerColumnCells[i].appendChild(togglePinElement);
    }
  }, [calenderData]);

  return (
    <div className="round-tab">
      <div className="section-container">
        <div className="section-header flex-between pb-2">
          <div className="section-title" data-testid="current-year">
            {deferredTitle}
          </div>

          <div className="flex items-center gap-1">
            <Dropdown
              menu={{ items: [] }}
              trigger={['click']}
              dropdownRender={scenarioStartDatePopover}
              open={scenarioStartPopoverOpen}
              destroyPopupOnHide
            >
              <div
                className={classNames('round-start-edit', {
                  active: calenderRange.start,
                })}
                onClick={toggleScenarioPopover}
                data-testid="open-round-start-change"
              >
                <span>
                  {calenderRange.start
                    ? dayjs(calenderRange.start)
                        .utc()
                        .format(CALENDAR_DATE_FORMAT)
                    : t('GENERAL.START_DATE')}
                </span>
                <EditObject />
              </div>
            </Dropdown>

            <span className="px-1">-</span>

            <div className="round-start-edit">
              {calenderRange.end
                ? dayjs(calenderRange.end).utc().format(CALENDAR_DATE_FORMAT)
                : t('GENERAL.END_DATE')}
            </div>

            <span className="px-3">|</span>

            <Dropdown
              menu={{ items: [] }}
              trigger={['click']}
              dropdownRender={createPopover}
              open={isPopoverOpen}
              destroyPopupOnHide
            >
              <Button
                className="create-btn"
                onClick={togglePopover}
                icon={<Plus />}
                data-testid="open-round-create"
              >
                {t('GENERAL.ROUND.CREATE')}
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="white-background">
          <div className="calender">
            <FullCalendar
              timeZone="UTC"
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridYear"
              events={calenderData}
              headerToolbar={{
                left: '',
                center: '',
                right: '',
              }}
              views={{
                dayGridYear: {
                  type: 'dayGrid',
                  duration: { years: calenderRange.NumberOfYears },
                  monthStartFormat: {
                    month: 'long',
                    year: 'numeric',
                    day: '2-digit',
                  },
                },
              }}
              eventDidMount={onCalenderLoad}
              select={onDateClick}
              contentHeight={620}
              dayCellClassNames={() => ['round-calendar-cell']}
              eventClassNames={() => ['round-calendar-event']}
              initialDate={calenderRange.start}
              firstDay={
                FullCalenderStartDayMap[stateData?.roundTemplateWeekStartDay] ??
                1
              }
              eventContent={eventRender}
              selectable
            />
          </div>
          <Dropdown
            menu={{ items: [] }}
            trigger={['click']}
            dropdownRender={createCellPopover}
            open={isCellPopoverOpen}
            align={{ offset: [offset.offsetLeft, offset.offsetTop] }}
            destroyPopupOnHide
          >
            <Button className="invisible" onClick={toggleCellPopover} />
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
