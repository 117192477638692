import _ from 'lodash';
import { useSelector } from 'react-redux';
import { QueueUpdateDeleteRoundTemplatePayloadType } from 'Models/Queue';
import {
  syncRoundTemplate,
  syncRoundTemplateWeekStartDay,
} from 'store/slices/scenarioSlice';
import {
  createRround,
  updateRound,
  updateRoundTemplateWeekStartDay,
} from 'store/slices/roundSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { Team } from 'Models/Team';
import { CREATED } from 'utils/variables';
import { Venue } from 'Models/Venue';
import { VenueTeam } from 'Models/VenueTeam';
import {
  RoundTemplate,
  RoundType,
  RoundTemplateResponsePromiseType,
} from 'Models/Scenario';
import { getScenarioKey } from 'utils/ui-helper';

export type SyncNewTeamPayloadType = {
  team: Team;
  venue: Venue;
  venueTeam: VenueTeam;
  solutionKey: string;
};

export type RoundWeekDayPayload = {
  roundTemplateWeekStartDay: number;
  solutionKey: string;
};

export const useRoundTemplate = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareRoundPayload = ({
    data,
    toBeUpdatedFields,
  }: {
    data: RoundTemplate;
    toBeUpdatedFields: {
      [key in keyof RoundTemplate]?: RoundTemplate[key];
    };
  }): RoundTemplate => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.roundTemplateKey]) {
      newPayload.roundTemplateKey =
        queueLocalKeyMapRef.current[newPayload.roundTemplateKey];
    }

    if (Array.isArray(newPayload.roundDays)) {
      newPayload.roundDays = newPayload.roundDays.map((rd) => {
        if (queueLocalKeyMapRef.current[rd.roundDayKey]) {
          rd.roundDayKey = queueLocalKeyMapRef.current[rd.roundDayKey];
        }

        rd.timeSlots = rd.timeSlots.map((ts) => {
          if (queueLocalKeyMapRef.current[ts.timeSlotKey]) {
            ts.timeSlotKey = queueLocalKeyMapRef.current[ts.timeSlotKey];
          }

          if (queueLocalKeyMapRef.current[ts.networkKey]) {
            ts.networkKey = queueLocalKeyMapRef.current[ts.networkKey];
          }

          if (queueLocalKeyMapRef.current[ts.networkCategoryKey]) {
            ts.networkCategoryKey =
              queueLocalKeyMapRef.current[ts.networkCategoryKey];
          }

          return ts;
        });

        return rd;
      });
    }

    return newPayload;
  };

  const syncRoundToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: QueueUpdateDeleteRoundTemplatePayloadType): void => {
    if (Array.isArray(data)) return;

    // @ts-expect-error
    const preparedRound = prepareRoundPayload({ data, toBeUpdatedFields });
    dispatch(
      syncRoundTemplate({
        data: preparedRound,
        solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createRoundTemplateQueue = ({
    data,
    actualSolutionKey,
  }: {
    data: RoundType;
    actualSolutionKey: string;
  }): Promise<RoundTemplateResponsePromiseType | null> => {
    const scenarioKey = getScenarioKey(actualSolutionKey);

    return dispatch(
      createRround({
        data: {
          data,
          scenarioKey,
          solutionKey: actualSolutionKey,
        },
        solutionStatus: CREATED,
      }),
    ) as Promise<RoundTemplateResponsePromiseType>;
  };

  const updateRoundTemplateQueue = ({
    data,
    toBeUpdatedFields,
    actualSolutionKey,
  }: {
    data: RoundTemplate[];
    toBeUpdatedFields: {
      [key in keyof RoundTemplate]?: RoundTemplate[key];
    };
    actualSolutionKey: string;
  }): Promise<RoundTemplateResponsePromiseType | null> => {
    const scenarioKey = getScenarioKey(actualSolutionKey);

    return dispatch(
      updateRound({
        data: {
          data: data.map((item) =>
            prepareRoundPayload({ data: item, toBeUpdatedFields }),
          ),
          scenarioKey,
          solutionKey: actualSolutionKey,
        },
        solutionStatus: CREATED,
      }),
    ) as Promise<RoundTemplateResponsePromiseType>;
  };

  const syncRoundWeekStartDay = ({
    roundTemplateWeekStartDay,
    solutionKey,
  }: RoundWeekDayPayload): void => {
    dispatch(
      syncRoundTemplateWeekStartDay({
        data: {
          roundTemplateWeekStartDay,
        },
        solutionKey,
      }),
    );
  };

  const updateRoundWeekStartDay = ({
    data,
    actualSolutionKey,
  }: {
    data: {
      roundTemplateWeekStartDay: number;
    };
    actualSolutionKey: string;
  }): void => {
    dispatch(
      updateRoundTemplateWeekStartDay({
        data: {
          data,
          solutionKey: actualSolutionKey,
        },
        solutionStatus: CREATED,
      }),
    );
  };

  return {
    prepareRoundPayload,
    syncRoundToStore,
    createRoundTemplateQueue,
    updateRoundTemplateQueue,
    updateRoundWeekStartDay,
    syncRoundWeekStartDay,
  };
};

export default useRoundTemplate;
