import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  getCloudProviderSettings,
  saveCloudProviderSettings,
} from 'Services/UserService';
import IntegrationCard from './IntegrationCard';
import { useEffect, useRef, useState } from 'react';

const Integrations = () => {
  const { t } = useTranslation();
  const isFetchingData = useRef(false);
  const [integratedServices, setIntegratedServices] = useState({});

  const integrations = [
    {
      provider: 'Azure',
      title: t('GENERAL.INTIGRATIONS.AZURE.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.AZURE.SUBTITLE'),
    },
    {
      provider: 'AWS',
      title: t('GENERAL.INTIGRATIONS.AWS.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.AWS.SUBTITLE'),
    },
    {
      provider: 'GCP',
      title: t('GENERAL.INTIGRATIONS.GCP.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.GCP.SUBTITLE'),
    },
  ];

  const fetchProviderSettings = async () => {
    const data = await getCloudProviderSettings();

    if (data && Array.isArray(data.cloudProviderSettings)) {
      setIntegratedServices(
        data.cloudProviderSettings.reduce((acc, item) => {
          acc[item.providerName] = item;
          return acc;
        }, {}),
      );
    }

    return data;
  };

  const onSave = (data) =>
    saveCloudProviderSettings(data).then(fetchProviderSettings);

  useEffect(() => {
    if (isFetchingData.current) return;

    isFetchingData.current = true;
    fetchProviderSettings().finally(() => {
      isFetchingData.current = false;
    });
  }, []);

  return (
    <>
      <div>
        <div className="section-title font-semibold pl-0">
          {t('GENERAL.USER.INTIGRATIONS')}
        </div>
        <div className="mt-1 section-subtitle">
          {t('GENERAL.USER.CONNECT_CLOUD_PROVIDERS')}
        </div>
      </div>

      <Divider style={{ marginBottom: '16px' }} />

      <div>
        <div className="flex flex-column gap-2">
          {integrations.map((item) => (
            <IntegrationCard
              key={item.title}
              item={item}
              onSave={onSave}
              integratedServices={integratedServices}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Integrations;
