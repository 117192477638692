import React, { useMemo, useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Dropdown, Badge, Tabs, message } from 'antd';
import type { MenuProps } from 'antd';
import { Plus } from 'Components/Elements/Icons';
import { decodeHTML } from 'utils/ui-helper';
import { Venue } from 'Models/Venue';
import { useTranslation } from 'react-i18next';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { useLoadScript } from '@react-google-maps/api';
import { useSize } from 'customHooks';
import LocationEditor from 'Components/Elements/Common/CellEditor/LocationEditor';
import VenueCreatePopOver from './VenueCreatePopOver';
import { HomeVenueRender } from './CellRenderer';
import { Solution } from 'Models/Scenario';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { VenueTeamGrid } from 'Components/Elements/VenueTeam';
import { useVenue as useVenueQueue } from 'storeHooks/queue';
import { DELETED, SYNC_VENUE_KEY_PREFIX } from 'utils/variables';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import { useSelector } from 'react-redux';
import {
  QueueActionType,
  QueueCreateUpdateVenuePayloadType,
} from 'Models/Queue';
import { EntityStatusType } from 'Models/Common';
import {
  selectVenues,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import './style.scss';

interface CellParamType {
  data: Venue;
}

const validator: Record<string, { maxLength?: number }> = {
  code: {
    maxLength: 5,
  },
  name: {
    maxLength: 200,
  },
  location: {
    maxLength: 500,
  },
};

const validate = (field: string, value: string): string => {
  if (!validator[field]) return value;

  if (validator[field].maxLength) {
    return value.slice(0, validator[field].maxLength);
  }

  return value;
};

export const handleDeleteVenue = async (
  venueKey: string,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncVenueToStore: (data: QueueCreateUpdateVenuePayloadType) => void,
) => {
  if (!selectedSolution) return;

  const { venues, solutionKey } = selectedSolution.stateData;
  const toBeDeletedVenue = venues.find((x) => x.venueKey === venueKey);

  if (!toBeDeletedVenue) return;

  const { solutionKey: localSolutionKey } = selectedSolution;

  const toBeSyncedKey = `${SYNC_VENUE_KEY_PREFIX}${uuidv4()}`;
  const toBeUpdatedFields = {
    venueStatus: DELETED as EntityStatusType,
  };

  syncVenueToStore({
    data: {
      ...toBeDeletedVenue,
      solutionKey: localSolutionKey,
    },
    solutionKey: localSolutionKey,
    toBeUpdatedFields,
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_VENUE',
    payload: {
      data: [
        {
          ...toBeDeletedVenue,
          solutionKey,
        },
      ],
      toBeUpdatedFields,
      toBeSyncedKey,
      solutionKey: localSolutionKey,
      actualSolutionKey: solutionKey,
    },
  });
};

function VenueList(): JSX.Element {
  useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    libraries: ['places'],
  });
  const { t } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedSolutionRef = useRef(selectedSolution);
  const { syncVenueToStore } = useVenueQueue();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution, canDeleteVenue } = useSolution();
  const { code, venue, location, homeOf } = useLocale();
  const gridRef = useRef();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const size = useSize(container);
  const allVenues = useSelector(selectVenues);
  const venuesRef = useRef<Venue[]>([]);
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);

  const agGridStyle = useMemo(() => {
    if (size && size.height) {
      const multiplyBy = size.height < 500 ? 0.7 : 0.88;

      return {
        height: size.height * multiplyBy,
      };
    }

    return {};
  }, [size]);

  const venues: Venue[] = useMemo(() => {
    if (allVenues) {
      venuesRef.current = allVenues.map((v) => ({
        ...v,
        code: decodeHTML(v.code),
        name: decodeHTML(v.name),
        location: decodeHTML(v.location ?? ''),
      }));
      return [...venuesRef.current];
    }
    return [];
  }, [allVenues]);

  const items: MenuProps['items'] = [];

  const onDeleteVenue = (venueKey: string) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleDeleteVenue(venueKey, chosenSolution, onEnqueue, syncVenueToStore);
  };

  const [columnDefs] = useState([
    { field: 'code', headerName: code, editable: true, resizable: true },
    {
      field: 'name',
      headerName: venue,
      editable: true,
      resizable: true,
    },
    {
      field: 'location',
      headerName: location,
      cellEditor: LocationEditor,
      editable: true,
      resizable: true,
    },
    {
      field: 'homeOf',
      headerName: homeOf,
      cellRenderer: HomeVenueRender,
      cellEditor: HomeVenueRender,
      editable: true,
      resizable: true,
    },
    {
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 80,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={t('GENERAL.FEEDBACK.VENUE.DELETE_CONFIRM')}
            onConfirm={() => {
              if (!canDeleteVenue(event.data.venueKey)) return;

              onDeleteVenue(event.data.venueKey);
            }}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;

        if (!canDeleteVenue(event.data.venueKey)) return;

        onDeleteVenue(event.data.venueKey);
      },
      resizable: false,
    },
  ]);

  const hasDuplicateTeamCode = (data: Venue) =>
    venuesRef.current.filter(
      (item) =>
        item.venueKey !== data.venueKey &&
        item.code.toLocaleLowerCase().trim() ===
          data.code.toLocaleLowerCase().trim(),
    ).length > 0;

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const createPopover = () => <VenueCreatePopOver onClose={togglePopover} />;

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  // @ts-expect-error
  const onCellEditRequest = (event) => {
    const oldData = venuesRef.current.find(
      (item) => item.venueKey === event.data.venueKey,
    );

    if (!oldData) return;

    const toBeUpdatedFields: QueueCreateUpdateVenuePayloadType['toBeUpdatedFields'] =
      {};

    const { field } = event.colDef;
    const { newValue } = event;
    const newData = { ...oldData } as Venue;

    const validatedValue = validate(field, newValue);

    // @ts-expect-error
    newData[field] = validatedValue;
    // @ts-expect-error
    toBeUpdatedFields[field as keyof Venue] = validatedValue;

    if (hasDuplicateTeamCode(newData)) {
      message.error(t('GENERAL.VALIDATION.CODE_DUPLICATION'));
      return;
    }

    if (newValue !== undefined) {
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);

      const toBeSyncedKey = `${SYNC_VENUE_KEY_PREFIX}${uuidv4()}`;

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;

      const { solutionKey } = chosenSolution;
      oldData.solutionKey = solutionKey;
      oldData.toBeSyncedKey = toBeSyncedKey;

      syncVenueToStore({
        data: [oldData],
        toBeUpdatedFields,
        solutionKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_VENUE',
        payload: {
          data: [
            {
              ...oldData,
              solutionKey: chosenSolution.stateData.solutionKey,
            },
          ],
          toBeUpdatedFields,
          toBeSyncedKey,
          solutionKey, // local solution key
          actualSolutionKey: chosenSolution.stateData.solutionKey,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
    }
  }, [selectedSolution]);

  return (
    <div
      className="venues-table ag-grid-curve section-container"
      ref={setContainer}
    >
      <Tabs
        defaultActiveKey="venues"
        items={[
          {
            label: (
              <div className="section-title flex items-center gap-2">
                {t('GENERAL.VENUE.TITLE', { count: 2 })}

                <span data-testid="venue-count">
                  <Badge
                    count={venues?.length}
                    style={{ backgroundColor: '#d0d5dd', color: '#000000' }}
                  />
                </span>
              </div>
            ),
            key: 'venues',
            children: (
              <div className="p-1">
                <div className="section-header flex-between">
                  <span>&nbsp;</span>

                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    dropdownRender={createPopover}
                    open={isPopoverOpen}
                    destroyPopupOnHide
                  >
                    <Button
                      className="create-btn"
                      onClick={togglePopover}
                      icon={<Plus />}
                      data-testid="open-venue-create"
                    >
                      {t('GENERAL.VENUE.CREATE')}
                    </Button>
                  </Dropdown>
                </div>
                <div
                  className="ag-theme-alpine mt-2 h-full ag-grid-curve"
                  style={agGridStyle}
                >
                  {/* @ts-expect-error */}
                  <AgGridReact
                    ref={gridRef}
                    rowData={venues}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onCellEditRequest={onCellEditRequest}
                    getRowId={(params) => params.data.venueKey}
                    animateRows
                    readOnlyEdit
                    rowSelection="multiple"
                    enterNavigatesVerticallyAfterEdit
                    stopEditingWhenCellsLoseFocus
                  />
                </div>
              </div>
            ),
          },
          {
            label: (
              <span className="font-semibold primary-color-text">
                {t('GENERAL.VENUETEAM.TEAM_VENUE_MATCHUP')}
              </span>
            ),
            key: 'venue-team',
            children: <VenueTeamGrid height={size.height} />,
          },
        ]}
      />
    </div>
  );
}

export default VenueList;
