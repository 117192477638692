import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7662 11.2045H20.6206L23.0548 8.77034L23.1745 7.73751C18.6453 3.73999 11.7312 4.17072 7.73369 8.69992C6.62222 9.9581 5.81591 11.4545 5.37695 13.0742C5.64807 12.9627 5.94853 12.9451 6.23139 13.0226L11.0986 12.2198C11.0986 12.2198 11.3462 11.8102 11.4742 11.836C13.6396 9.45811 17.2839 9.18113 19.7838 11.2045H19.7662Z"
        fill="#EA4335"
      />
      <path
        d="M26.522 13.0742C25.9622 11.0144 24.8143 9.16235 23.2169 7.74573L19.8016 11.1611C21.244 12.3395 22.0656 14.1152 22.0304 15.9779V16.5846C23.7099 16.5846 25.0702 17.9461 25.0702 19.6245C25.0702 21.304 23.7087 22.6643 22.0304 22.6643H15.9496L15.3428 23.2793V26.9259L15.9496 27.5327H22.0304C25.5162 27.5597 28.6088 25.3003 29.644 21.9718C30.6768 18.6421 29.4092 15.0272 26.522 13.0742Z"
        fill="#4285F4"
      />
      <path
        d="M9.86141 27.4986H15.9422V22.6314H9.86141C9.42833 22.6314 8.99994 22.5387 8.60558 22.3579L7.75115 22.622L5.30052 25.0562L5.08691 25.9106C6.46128 26.9481 8.13846 27.5056 9.86141 27.4986Z"
        fill="#34A853"
      />
      <path
        d="M9.86069 11.7079C6.48286 11.7279 3.49117 13.891 2.41374 17.0916C1.33631 20.2922 2.41022 23.8249 5.08737 25.8835L8.61425 22.3567C7.08378 21.6654 6.40423 19.8649 7.09552 18.3345C7.78681 16.804 9.58723 16.1245 11.1177 16.8157C11.7914 17.1209 12.3313 17.6608 12.6364 18.3345L16.1633 14.8076C14.6622 12.8464 12.3301 11.6997 9.86069 11.7079Z"
        fill="#FBBC05"
      />
    </svg>
  );
}

export default Icon;
