import React, { useMemo, useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Dropdown, Badge, message } from 'antd';
import type { MenuProps } from 'antd';
import { Plus } from 'Components/Elements/Icons';
import { Team } from 'Models/Team';
import { decodeHTML } from 'utils/ui-helper';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { useLoadScript } from '@react-google-maps/api';
import { useSize } from 'customHooks';
import LocationEditor from 'Components/Elements/Common/CellEditor/LocationEditor';
import { Solution } from 'Models/Scenario';
import i18n from 'plugins/i18next';
import { useTeam as useTeamQueue } from 'storeHooks/queue';
import { useSelector } from 'react-redux';
import {
  QueueActionType,
  QueueCreateUpdateTeamPayloadType,
} from 'Models/Queue';
import useSolution from 'customHooks/useSolution';
import { DELETED, SYNC_TEAM_KEY_PREFIX } from 'utils/variables';
import { EntityStatusType } from 'Models/Common';
import useQueue from 'storeHooks/useQueue';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import TeamCreatePopOver from './TeamCreatePopOver';
import { HomeVenueRender, TeamLogo } from './CellRenderer';
import {
  selectTeams,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import './style.scss';

interface CellParamType {
  data: Team;
}

const validator: Record<string, { maxLength?: number }> = {
  code: {
    maxLength: 5,
  },
  name: {
    maxLength: 200,
  },
  city: {
    maxLength: 500,
  },
};

const validate = (field: string, value: string): string => {
  if (!validator[field]) return value;

  if (validator[field].maxLength) {
    return value.slice(0, validator[field].maxLength);
  }

  return value;
};

export const handleDeleteTeam = async (
  teamKey: string,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamToStore: (data: QueueCreateUpdateTeamPayloadType) => void,
) => {
  if (!selectedSolution) return;

  const { teams } = selectedSolution.stateData;
  const { solutionKey } = selectedSolution.stateData;
  const { solutionKey: localSolutionKey } = selectedSolution;

  const toBeDeletedTeam = teams.find((item) => item.teamKey === teamKey);

  if (!toBeDeletedTeam) return;

  const toBeSyncedKey = `${SYNC_TEAM_KEY_PREFIX}${uuidv4()}`;
  const toBeUpdatedFields = {
    teamStatus: DELETED as EntityStatusType,
  };

  syncTeamToStore({
    data: {
      ...toBeDeletedTeam,
      solutionKey: localSolutionKey,
    },
    toBeUpdatedFields,
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_TEAM',
    payload: {
      data: {
        ...toBeDeletedTeam,
        solutionKey,
      },
      toBeUpdatedFields,
      toBeSyncedKey,
      solutionKey: localSolutionKey, // local solution key
    },
  });
};

function TeamList(): JSX.Element {
  useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    libraries: ['places'],
  });
  const gridRef = useRef();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedSolutionRef = useRef(selectedSolution);
  const { syncTeamToStore } = useTeamQueue();
  const { getCurrentOrClonedSolution, canDeleteTeam } = useSolution();
  const { onEnqueue } = useQueue();
  const { team, city, homeVenue } = useLocale();
  const { create, code, name, title } = team;
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const size: { height: number } | null = useSize(container)!;
  const allTeams = useSelector(selectTeams);
  const teamsRef = useRef<Team[]>([]);
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);

  const agGridStyle = useMemo(() => {
    if (size && size.height) {
      const multiplyBy = size.height < 500 ? 0.7 : 0.94;

      return {
        height: size.height * multiplyBy,
      };
    }

    return {};
  }, [size]);

  const teams: Team[] = useMemo(() => {
    if (allTeams) {
      teamsRef.current = [...allTeams]
        .map((x) => ({
          ...x,
          name: decodeHTML(x.name),
          code: decodeHTML(x.code),
          city: decodeHTML(x.city ?? ''),
        }))
        .sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true }));

      return teamsRef.current;
    }
    return [];
  }, [allTeams]);

  const items: MenuProps['items'] = [];

  const onDeleteTeam = (teamKey: string) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleDeleteTeam(teamKey, chosenSolution, onEnqueue, syncTeamToStore);
  };

  const [columnDefs] = useState([
    {
      field: '',
      cellRenderer: TeamLogo,
      maxWidth: 100,
      resizable: true,
    },
    { field: 'code', headerName: code, editable: true, resizable: true },
    {
      field: 'name',
      headerName: name,
      editable: true,
      resizable: true,
    },
    {
      field: 'city',
      headerName: city,
      cellEditor: LocationEditor,
      editable: true,
      resizable: true,
    },
    {
      field: 'homeOf',
      headerName: homeVenue,
      cellRenderer: HomeVenueRender,
      cellEditor: HomeVenueRender,
      editable: true,
      resizable: true,
    },
    {
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 80,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={i18n.t('GENERAL.FEEDBACK.TEAM.DELETE_CONFIRM')}
            onConfirm={() => {
              if (!canDeleteTeam(event.data.teamKey)) return;

              onDeleteTeam(event.data.teamKey);
            }}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;

        if (!canDeleteTeam(event.data.teamKey)) return;

        onDeleteTeam(event.data.teamKey);
      },
      resizable: false,
    },
  ]);

  const hasDuplicateTeamCode = (data: Team) =>
    teamsRef.current.filter(
      (item) =>
        item.teamKey !== data.teamKey &&
        item.code.toLocaleLowerCase().trim() ===
          data.code.toLocaleLowerCase().trim(),
    ).length > 0;

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const createPopover = () => <TeamCreatePopOver onClose={togglePopover} />;

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  // @ts-expect-error
  const onCellEditRequest = async (event) => {
    const oldData = teamsRef.current.find(
      (item) => item.teamKey === event.data.teamKey,
    );

    if (!oldData) return;

    const { field } = event.colDef;
    const { newValue } = event;
    const newData = { ...oldData };

    const toBeUpdatedFields: QueueCreateUpdateTeamPayloadType['toBeUpdatedFields'] =
      {};

    const validatedValue = validate(field, newValue);

    // @ts-expect-error
    newData[field] = validatedValue;
    // @ts-expect-error
    toBeUpdatedFields[field] = validatedValue;

    if (hasDuplicateTeamCode(newData)) {
      message.error(i18n.t('GENERAL.VALIDATION.CODE_DUPLICATION'));
      return;
    }

    if (validatedValue !== undefined) {
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);

      const toBeSyncedKey = `${SYNC_TEAM_KEY_PREFIX}${uuidv4()}`;

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;

      const { solutionKey } = chosenSolution;

      syncTeamToStore({
        data: {
          ...oldData,
          solutionKey,
        },
        toBeUpdatedFields,
        toBeSyncedKey,
        solutionKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_TEAM',
        payload: {
          data: {
            ...oldData,
            solutionKey: chosenSolution.stateData.solutionKey,
            toBeSyncedKey,
          },
          toBeUpdatedFields,
          toBeSyncedKey,
          solutionKey, // local solution key
        },
      });
    }
  };

  useEffect(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
    }
  }, [selectedSolution]);

  return (
    <div
      className="teams-table ag-grid-curve section-container"
      ref={setContainer}
    >
      <div className="flex justify-between items-center">
        <div className="section-title flex items-center gap-2">
          {title}

          <span data-testid="team-count">
            <Badge
              count={teams?.length}
              style={{ backgroundColor: '#d0d5dd', color: '#000000' }}
            />
          </span>
        </div>

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          dropdownRender={createPopover}
          open={isPopoverOpen}
          destroyPopupOnHide
        >
          <Button
            className="create-btn"
            onClick={togglePopover}
            icon={<Plus />}
            data-testid="open-team-create"
          >
            {create}
          </Button>
        </Dropdown>
      </div>
      <div className="ag-theme-alpine mt-2 ag-grid-curve" style={agGridStyle}>
        {/* @ts-expect-error */}
        <AgGridReact
          ref={gridRef}
          rowData={teams}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellEditRequest={onCellEditRequest}
          getRowId={(params) => params.data.teamKey}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          suppressRowClickSelection
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default TeamList;
