import { useRef, useEffect } from 'react';
import { GamesLockPayload } from 'Models/Scenario.d';
import i18n from 'plugins/i18next';
import { message } from 'antd';
import { PostCreateSetFromGames } from 'Services/ScenarioService';
import { LockType } from 'Models/LockTypes';
import useScenario from 'storeHooks/useScenario';
import useSolveData from 'customHooks/useSolveData';
import { useAppDispatch } from 'storeHooks/hooks';
import {
  syncGamesLock,
  syncSet,
  syncConstraint,
} from 'store/slices/scenarioSlice';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { CREATED } from 'utils/variables';
import { SolutionStatusStopped } from 'Models/SolutionStatuses';

export const useSolveTable = () => {
  const dispatch = useAppDispatch();
  const { scenario, selectedSolution, solveData } = useScenario();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const { cloneToNewInstance } = useSolveData();
  const selectedSolutionRef = useRef(selectedSolution);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  const handleGamesLock = async (
    lockType: LockType,
    columnShortIds: string[],
  ): Promise<string> => {
    if (!scenario || !selectedSolution || !solveData) {
      return Promise.resolve('');
    }

    const chosenSolution = getCurrentOrClonedSolution(false);

    if (!chosenSolution) return Promise.resolve('');

    const { solveDataKey } = solveData;

    const gamesLockPayload: GamesLockPayload = {
      scenarioKey: scenario.scenarioKey,
      solutionKey: chosenSolution.solutionKey,
      solveDataKey,
      lockType,
      columnShortIds,
      solutionStatus: CREATED,
    };

    dispatch(syncGamesLock(gamesLockPayload));

    onEnqueue({
      type: 'UPDATE_LOCK_UNLOCK',
      payload: {
        data: gamesLockPayload,
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });

    return Promise.resolve('');
  };

  const createFixedGameSet = async (columnShortIds: string[]) => {
    if (!scenario || !selectedSolution || !solveData) {
      return;
    }

    let currentSolution = selectedSolution;
    let { solveDataKey } = solveData;
    let isCloned = false;

    if (selectedSolution.solutionStatus === SolutionStatusStopped) {
      const clonedSolution = await cloneToNewInstance(false);

      if (clonedSolution) {
        isCloned = true;
        currentSolution = clonedSolution;
        if (currentSolution.solveDataList[0]) {
          solveDataKey = currentSolution.solveDataList[0].solveDataKey;
        }
      }
    }

    const result = await PostCreateSetFromGames({
      solutionKey: currentSolution.solutionKey,
      solveDataKey,
      columnShortIds,
    });

    if (result) {
      const { set, constraint } = result;
      dispatch(syncSet(set));
      dispatch(syncConstraint(constraint));

      if (isCloned) {
        message.success(
          i18n.t('GENERAL.FEEDBACK.SOLUTION.SET_CONSTRAINT_CREATED_CLONED'),
        );
      } else {
        message.success(
          i18n.t('GENERAL.FEEDBACK.SOLUTION.SET_CONSTRAINT_CREATED'),
        );
      }
    }
  };

  return {
    scenario,
    handleGamesLock,
    createFixedGameSet,
  };
};

export default useSolveTable;
