// @ts-nocheck
import React, { useMemo, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSize } from 'customHooks';
import { useSelector } from 'react-redux';
import {
  selectNetworks,
  selectSelectedSolution,
  selectSolveDataKey,
} from 'store/slices/scenarioSlice';
import { SolverColumn } from 'Components/Scenarios/EditScenario/Solve/Solve/SolveTypes';
import { NetworkCategory } from 'Models/Network';
import i18n from 'plugins/i18next';
import './style.scss';

type CellValueType = { data: SolverColumn };

function NetworkCategoryMatchupTab(): JSX.Element {
  const gridRef = useRef();
  const selectedSolution = useSelector(selectSelectedSolution);
  const solveDataKey = useSelector(selectSolveDataKey);
  const solveData =
    selectedSolution && solveDataKey
      ? selectedSolution.solveDataList.find(
          (item) => item.solveDataKey === solveDataKey,
        )
      : null;
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const size: { height: number } | null = useSize(container)!;
  const allNetworks = useSelector(selectNetworks);
  const networkCategories: NetworkCategory[] = useMemo(() => {
    if (allNetworks) {
      return allNetworks.flatMap((item) => item.networkCategories);
    }

    return [];
  }, [allNetworks]);
  const networkCategoryCount: Record<string, number> = {};

  const agGridStyle = useMemo(() => {
    if (size && size.height) {
      const multiplyBy = size.height < 500 ? 0.7 : 0.93;

      return {
        height: size.height * multiplyBy,
      };
    }

    return {};
  }, [size]);

  const tableData: SolverColumn[] = useMemo(() => {
    const usedId: Record<string, boolean> = {};
    if (solveData && solveData.slotRows) {
      return solveData.slotRows
        .flatMap((item) => item.columns)
        .filter((item) => item.columnType)
        .reduce((acc, item) => {
          if (!usedId[item.columnShortId]) {
            usedId[item.columnShortId] = true;
            acc.push(item);
            if (
              item.networkCategory?.networkCategoryId &&
              networkCategoryCount[item.networkCategory.networkCategoryId]
            ) {
              networkCategoryCount[item.networkCategory.networkCategoryId] += 1;
            } else {
              networkCategoryCount[item.networkCategory.networkCategoryId] = 1;
            }
          }

          return acc;
        }, [] as SolverColumn[]);
    }
    return [];
  }, [solveData]);

  const [columnDefs] = useState([
    {
      headerName: i18n.t('GENERAL.MATCHUP'),
      cellRenderer: ({ data }: CellValueType) =>
        `${data.team?.code} - ${data.opponentTeam?.code}`,
    },
    {
      headerName: i18n.t('GENERAL.HOME'),
      cellRenderer: ({ data }: CellValueType) =>
        `${
          data.isHomeGameForOpponentTeam
            ? data.opponentTeam?.name
            : data.team?.name
        }`,
    },
    {
      headerName: i18n.t('GENERAL.AWAY'),
      cellRenderer: ({ data }: CellValueType) =>
        `${
          data.isHomeGameForOpponentTeam
            ? data.team?.name
            : data.opponentTeam?.name
        }`,
    },
    ...networkCategories.map((item) => ({
      headerName: `${item.name}  (${
        networkCategoryCount[item.networkCategoryId] ?? '0'
      })`,
      cellRenderer: ({ data }: CellValueType) =>
        data.networkCategory?.networkCategoryId === item.networkCategoryId
          ? 1
          : 0,
      cellClass: 'text-center',
    })),
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
    }),
    [],
  );

  return (
    <div
      className="teams-table ag-grid-curve section-container"
      ref={setContainer}
    >
      <div className="flex justify-between items-center">
        <div className="section-title flex items-center gap-2">
          {i18n.t('GENERAL.NETWORK.MATCHUP')}
        </div>
      </div>
      <div className="ag-theme-alpine mt-2 ag-grid-curve" style={agGridStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={(params) => params.data.columnShortId}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          suppressRowClickSelection
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default NetworkCategoryMatchupTab;
