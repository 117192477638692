import {
  RoundsDictionaries,
  RoundTemplate,
  RoundDay,
  TimeSlot,
} from 'Models/Scenario';
import { getShortUtcNow } from 'Components/Common/TimeUtils';

export function CreateEmptyTime(
  timeSlotText: string,
  roundsDictionaries: RoundsDictionaries,
): TimeSlot {
  const slotTime: TimeSlot = {
    timeSlotId: `${timeSlotText.toString()} ${new Date().toISOString()}`,
    timeSlotKey: `${timeSlotText.toString()} ${new Date().toISOString()}`,
    timeSlotText,
    timeSlotStatus: 'New',

    networkCategoryKey:
      roundsDictionaries.networks.length &&
      roundsDictionaries.networks[0].networkCategories.length
        ? roundsDictionaries.networks[0].networkCategories[0].networkCategoryKey
        : '',
    networkKey: roundsDictionaries.networks.length
      ? roundsDictionaries.networks[0].networkKey
      : '',
  };

  return slotTime;
}

export function CreateEmptyDay(
  dayNum: number,
  roundsDictionaries: RoundsDictionaries,
  haveEmptySlot?: boolean,
): RoundDay {
  const roundDay: RoundDay = {
    roundDayKey: `${dayNum.toString()} ${new Date().toISOString()}`,
    weekDay: dayNum,
    isActive: true,
    roundDayStatus: 'New',
    timeSlots: haveEmptySlot
      ? []
      : [CreateEmptyTime('tx1', roundsDictionaries)],
  };

  return roundDay;
}

export function CreateEmptyRoundTemplate(
  roundsDictionaries: RoundsDictionaries,
): RoundTemplate {
  function addDays(date: Date, days: number) {
    // var result = new Date(date);
    const result = date;
    result.setDate(result.getDate() + days);
    return result;
  }

  const today = getShortUtcNow();
  let tomorrow = getShortUtcNow();
  tomorrow = addDays(tomorrow, 6);

  const roundTemplate: RoundTemplate = {
    roundTemplateKey: `New RoundTemplate ${new Date().toISOString()}`,
    name: 'Round Template 1',
    roundTemplateStatus: 'New',
    startDate: today,
    endDate: tomorrow,
    roundDays: [],
  };

  for (let i = 1; i <= 7; i++) {
    roundTemplate.roundDays.push(CreateEmptyDay(i, roundsDictionaries));
  }

  roundTemplate.roundDays[0].isActive = true;

  return roundTemplate;
}
