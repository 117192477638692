import _ from 'lodash';
import { useSelector } from 'react-redux';
import { QueueCreateUpdateTimeSlotPayloadType } from 'Models/Queue';
import { syncTimeslot } from 'store/slices/scenarioSlice';
import { updateSlot } from 'store/slices/roundSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { Team } from 'Models/Team';
import { CREATED } from 'utils/variables';
import { Venue } from 'Models/Venue';
import { VenueTeam } from 'Models/VenueTeam';
import {
  ScenarioTimeSlotResponsePromiseType,
  SlotUpdateUnitType,
  TimeSlot,
} from 'Models/Scenario';
import { getScenarioKey, getNthElement } from 'utils/ui-helper';

export type SyncNewTeamPayloadType = {
  team: Team;
  venue: Venue;
  venueTeam: VenueTeam;
  solutionKey: string;
};

export const useTimeSlot = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareSlotPayload = ({ data }: { data: TimeSlot }): TimeSlot => {
    const newPayload = _.cloneDeep(data);

    if (queueLocalKeyMapRef.current[newPayload.timeSlotKey]) {
      newPayload.timeSlotKey =
        queueLocalKeyMapRef.current[newPayload.timeSlotKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.timeSlotId]) {
      newPayload.timeSlotId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.timeSlotId],
        6,
      );
    }

    if (queueLocalKeyMapRef.current[newPayload.networkKey]) {
      newPayload.networkKey =
        queueLocalKeyMapRef.current[newPayload.networkKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.networkCategoryKey]) {
      newPayload.networkCategoryKey =
        queueLocalKeyMapRef.current[newPayload.networkCategoryKey];
    }

    return newPayload;
  };

  const syncTimeSlotToStore = ({
    data,
    toBeSyncedKey,
    solutionKey,
    roundTemplateKey,
    roundDayKey,
  }: {
    data: TimeSlot;
    toBeSyncedKey: string;
    solutionKey: string;
    roundTemplateKey: string;
    roundDayKey: string;
  }): void => {
    const preparedTimeslot = prepareSlotPayload({ data });

    dispatch(
      syncTimeslot({
        data: preparedTimeslot,
        solutionKey,
        roundTemplateKey,
        roundDayKey,
        toBeSyncedKey,
      }),
    );
  };

  const prepareSlotUnitPayload = ({
    data,
  }: QueueCreateUpdateTimeSlotPayloadType): SlotUpdateUnitType => {
    const newPayload = _.cloneDeep(data);

    if (queueLocalKeyMapRef.current[newPayload.timeSlotKey]) {
      newPayload.timeSlotKey =
        queueLocalKeyMapRef.current[newPayload.timeSlotKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.networkKey]) {
      newPayload.networkKey =
        queueLocalKeyMapRef.current[newPayload.networkKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.networkCategoryKey]) {
      newPayload.networkCategoryKey =
        queueLocalKeyMapRef.current[newPayload.networkCategoryKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.roundTemplateKey]) {
      newPayload.roundTemplateKey =
        queueLocalKeyMapRef.current[newPayload.roundTemplateKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.roundDayKey]) {
      newPayload.roundDayKey =
        queueLocalKeyMapRef.current[newPayload.roundDayKey];
    }

    return newPayload;
  };

  const createOrUpdateTimeSlotQueue = ({
    data,
    actualSolutionKey,
  }: {
    data: SlotUpdateUnitType;
    actualSolutionKey: string;
    toBeSyncedKey: string;
  }): Promise<ScenarioTimeSlotResponsePromiseType | null> => {
    const preparedData = prepareSlotUnitPayload({
      data,
    });

    const scenarioKey = getScenarioKey(actualSolutionKey);

    // @ts-expect-error
    return dispatch(
      updateSlot({
        data: {
          data: preparedData,
          scenarioKey,
          solutionKey: actualSolutionKey,
        },
        solutionStatus: CREATED,
      }),
    );
  };

  return {
    prepareSlotPayload,
    syncTimeSlotToStore,
    createOrUpdateTimeSlotQueue,
  };
};

export default useTimeSlot;
