import { v4 as uuidv4 } from 'uuid';
import React, { useMemo, useState, useRef, ChangeEvent } from 'react';
import { Button, Input, Select } from 'antd';
import { Plus, Search } from 'Components/Elements/Icons';
import { decodeHTML } from 'utils/ui-helper';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import useQueue from 'storeHooks/useQueue';
import useConstraint from 'storeHooks/queue/useConstraint';
import { Constraint, Solution } from 'Models/Scenario';
import {
  QueueActionType,
  QueueCreateUpdateConstraintPayloadType,
} from 'Models/Queue';
import { useSelector } from 'react-redux';
import {
  selectConstraints,
  selectSolveDataKey,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import SelectSetRenderer from './SelectSetRenderer';
import TeamCellRenderer from './TeamCellRenderer';
import { copyConstraint, deleteConstraint } from './ActionCellRenderer';

import {
  IS_ACTIVE,
  OPERATOR,
  GAMES,
  ROUNDS,
  SET_ID,
  COMMENT,
  TEAMS,
  FAMILY_TYPE,
  START,
  END,
  CAP,
  CAP_SCORE,
  PENALTY_SCORE,
  SYNC_CONSTRAINT_KEY_PREFIX,
  MIN,
  BY_SET,
  CREATED,
  NEW,
  BY_TEAM,
  MATCHING,
  MAX,
} from 'utils/variables';

import {
  DEFAULT_PENALTY_CAP_SCORE,
  DEFAULT_PENALTY_CAP_THRESHOLD,
  PENALTY_HIGHLIGHT_CELL_COLOR,
  PENALTY_HIGHLIGHT_CELL_BACKGROUND_COLOR,
} from 'utils/constants';
import i18n from 'plugins/i18next';
import useSolution from 'customHooks/useSolution';
import '../style.scss';

interface CellParamType {
  data: Constraint;
}

const tableContainerStyle = {
  height: '94%',
};

const validator: Record<
  string,
  { min: number; max: number; required: boolean }
> = {
  games: {
    min: 0,
    max: 365,
    required: true,
  },
  rounds: {
    min: 0,
    max: 365,
    required: true,
  },
  start: {
    min: 0,
    max: 365,
    required: true,
  },
  end: {
    min: 0,
    max: 365,
    required: true,
  },
  penaltyScore: {
    min: 0,
    max: 999,
    required: true,
  },
  capThreshold: {
    min: 1,
    max: 100,
    required: false,
  },
  capScore: {
    min: 0,
    max: 10000,
    required: false,
  },
};

const validate = (field: string, value: number): number => {
  if (!validator[field]) return value;

  return Math.min(
    Math.max(Math.floor(value), validator[field].min),
    validator[field].max,
  );
};

export const handleCreateEmptyConstraint = (
  selectedSolution: Solution,
  onEnqueue: (data: QueueActionType) => void,
  syncConstraintToStore: (data: QueueCreateUpdateConstraintPayloadType) => void,
) => {
  if (!selectedSolution) return;

  const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

  const { solutionKey, teams } = selectedSolution.stateData;

  const dataPayload = {
    constraintKey: toBeSyncedKey,
    solutionKey: selectedSolution.solutionKey,
    comment: '',
    isActive: true,
    operator: MIN,
    familyType: BY_TEAM,
    games: 0,
    rounds: 1,
    start: 1,
    end: 1,
    teamsIds: teams.map((item) => item.teamId),
    penalty: {
      penaltyScore: 999,
      penaltyCap: null,
    },
    setId: null,
    constraintStatus: CREATED,
  };

  syncConstraintToStore({
    data: dataPayload as Constraint,
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_CONSTRAINT',
    payload: {
      data: {
        ...dataPayload,
        constraintStatus: NEW,
        solutionKey, // actual solution key even if the solution id is not correct now. it will be replaced once the solution is cloned
      } as Constraint,
      toBeSyncedKey,
      solutionKey: selectedSolution.solutionKey, // local solution key
    },
  });
};

function ConstraintList(): JSX.Element {
  const { t } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const allConstraints = useSelector(selectConstraints);
  const solveDataKey = useSelector(selectSolveDataKey);
  const { onEnqueue } = useQueue();
  const { syncConstraintToStore } = useConstraint();
  const { getCurrentOrClonedSolution } = useSolution();
  const { active, constraint, team, comment } = useLocale();
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);
  const constraintsRef = useRef<Constraint[]>([]);
  const {
    title,
    create,
    type,
    operator,
    games,
    set,
    rounds,
    start,
    end,
    penalty,
    cap,
    capScore,
  } = constraint;
  const { title: teamTitle } = team;
  const gridRef = useRef();
  const [searchText, setSearchText] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<number>(0);

  const solveData = useMemo(() => {
    if (selectedSolution && solveDataKey) {
      return selectedSolution.solveDataList.find(
        (item) => item.solveDataKey === solveDataKey,
      );
    }

    return null;
  }, [solveDataKey, selectedSolution]);

  const constraintWithPenalties = solveData?.penalties
    .filter((item) => item.constraintKey)
    .map((item) => item.constraintKey);

  const familyTypeMap = {
    [MATCHING]: t('GENERAL.CONSTRAINT.FAMILY_TYPE.MATCHING'),
    [BY_TEAM]: t('GENERAL.CONSTRAINT.FAMILY_TYPE.BY_TEAM'),
    [BY_SET]: t('GENERAL.CONSTRAINT.FAMILY_TYPE.BY_SET'),
  };
  const operatorMap = {
    [MIN]: t('GENERAL.CONSTRAINT.OPERATOR_TYPE.MIN'),
    [MAX]: t('GENERAL.CONSTRAINT.OPERATOR_TYPE.MAX'),
  };

  const sortOrderOptions = [
    { label: t('GENERAL.CONSTRAINT.SORT_ORDER.SELECT'), value: 0 },
    { label: t('GENERAL.CONSTRAINT.SORT_ORDER.COMMENTS_ASC'), value: 1 },
    { label: t('GENERAL.CONSTRAINT.SORT_ORDER.COMMENTS_DESC'), value: 2 },
    { label: t('GENERAL.CONSTRAINT.SORT_ORDER.TEAM_REQUESTS'), value: 3 },
  ];

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSortOrder = (value: number) => {
    setSortOrder(value);
  };

  const filteredConstraints: Constraint[] = useMemo(() => {
    if (!allConstraints) return [];

    constraintsRef.current = allConstraints;

    let items = [
      ...constraintsRef.current.map((c) => ({
        ...c,
        comment: decodeHTML(c.comment),
      })),
    ];

    if (searchText.trim().length > 0) {
      items = items.filter((item) => {
        const keyword = searchText.trim().toLowerCase();

        return (
          item.comment.toLowerCase().includes(keyword) ||
          item.familyType.toLowerCase().includes(keyword) ||
          item.operator.toLowerCase().includes(keyword) ||
          item.games.toString().includes(keyword) ||
          item.rounds.toString().includes(keyword) ||
          item.start.toString().includes(keyword) ||
          item.end.toString().includes(keyword) ||
          item.penalty.penaltyScore.toString().includes(keyword) ||
          item.penalty.penaltyCap?.penaltyCapThreshold
            ?.toString()
            .includes(keyword) ||
          item.penalty.penaltyCap?.penaltyScore?.toString().includes(keyword)
        );
      });
    }

    if (sortOrder === 1) {
      items.sort((a, b) =>
        a.comment.localeCompare(b.comment, 'en', { numeric: true }),
      );
    } else if (sortOrder === 2) {
      items.sort((a, b) =>
        b.comment.localeCompare(a.comment, 'en', { numeric: true }),
      );
    } else if (sortOrder === 3) {
      items = items.filter(
        (x) => x.familyType === BY_TEAM && x.teamsIds.length === 1,
      );
    }

    return items;
  }, [allConstraints, searchText, sortOrder]);

  const onDeleteConstraint = (constraintKey: string) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    const { solutionKey } = chosenSolution.stateData;

    deleteConstraint(
      chosenSolution.stateData.constraintsEnvelop.constraints,
      constraintKey,
      onEnqueue,
      syncConstraintToStore,
      chosenSolution.solutionKey,
      solutionKey,
    );
  };

  const [columnDefs] = useState([
    {
      field: IS_ACTIVE,
      headerName: active,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellEditorParams: {
        values: [true, false],
      },
      editable: true,
      maxWidth: 80,
    },
    {
      field: OPERATOR,
      headerName: operator,
      editable: true,
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      singleClickEdit: true,
      cellEditorParams: {
        values: Object.keys(operatorMap),
      },
      refData: operatorMap,
      maxWidth: 80,
    },
    {
      field: GAMES,
      headerName: games,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.games.min,
        max: validator.games.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: SET_ID,
      headerName: set,
      cellRenderer: SelectSetRenderer,
      cellEditor: SelectSetRenderer,
      editable: true,
      resizable: true,
      maxWidth: 140,
    },
    {
      field: ROUNDS,
      headerName: rounds,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.rounds.min,
        max: validator.rounds.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: START,
      headerName: start,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.start.min,
        max: validator.start.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: END,
      headerName: end,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.end.min,
        max: validator.end.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: PENALTY_SCORE,
      headerName: penalty,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.penaltyScore.min,
        max: validator.penaltyScore.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: CAP,
      headerName: cap,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.capThreshold.min,
        max: validator.capThreshold.max,
      },
      resizable: true,
      maxWidth: 90,
    },
    {
      field: CAP_SCORE,
      headerName: capScore,
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: validator.capScore.min,
        max: validator.capScore.max,
      },
      resizable: true,
      maxWidth: 110,
    },
    {
      field: FAMILY_TYPE,
      headerName: type,
      editable: true,
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      singleClickEdit: true,
      cellEditorParams: {
        values: Object.keys(familyTypeMap),
      },
      refData: familyTypeMap,
      maxWidth: 100,
    },
    {
      field: TEAMS,
      headerName: teamTitle,
      cellRenderer: TeamCellRenderer,
      cellEditor: TeamCellRenderer,
      editable: true,
      resizable: true,
      maxWidth: 150,
    },
    {
      field: COMMENT,
      headerName: comment,
      editable: true,
      cellStyle: (params: { data: Constraint }) => {
        if (
          Array.isArray(constraintWithPenalties) &&
          constraintWithPenalties.includes(params.data.constraintKey)
        ) {
          return {
            color: PENALTY_HIGHLIGHT_CELL_COLOR,
            backgroundColor: PENALTY_HIGHLIGHT_CELL_BACKGROUND_COLOR,
          };
        }
        return null;
      },
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: 'clone-icon',
      maxWidth: 40,
      onCellClicked: (event: CellParamType) => {
        const chosenSolution = getCurrentOrClonedSolution();

        if (!chosenSolution) return;
        const { solutionKey } = chosenSolution.stateData;

        copyConstraint(
          chosenSolution.stateData.constraintsEnvelop.constraints,
          event.data.constraintKey,
          onEnqueue,
          syncConstraintToStore,
          chosenSolution.solutionKey,
          solutionKey,
        );
      },
      resizable: false,
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 80,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={i18n.t('GENERAL.FEEDBACK.CONSTRAINT.DELETE_CONFIRM')}
            onConfirm={() => onDeleteConstraint(event.data.constraintKey)}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;

        onDeleteConstraint(event.data.constraintKey);
      },
      resizable: false,
    },
  ]);

  const createEmptyConstraint = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleCreateEmptyConstraint(
      chosenSolution,
      onEnqueue,
      syncConstraintToStore,
    );
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  // @ts-expect-error
  const onCellEditRequest = (event) => {
    const constraintIndex = constraintsRef.current.findIndex(
      (item) => item.constraintKey === event.data.constraintKey,
    );
    const oldData = constraintsRef.current[constraintIndex];

    if (!oldData) return;

    const { field } = event.colDef;
    const { newValue } = event;
    const newData = { ...oldData };

    if (String(oldData[field as keyof Constraint]) === String(newValue)) return;

    const toBeUpdatedFields: QueueCreateUpdateConstraintPayloadType['toBeUpdatedFields'] =
      {};

    if (field.includes('penalty.')) {
      const fields = field.split('.');
      let fieldName = fields[fields.length - 1];

      if (fieldName === 'penaltyScore' && fields.length === 3) {
        fieldName = 'capScore';
      }

      let fieldValue = newValue !== null ? validate(fieldName, newValue) : null;

      if (validator[fieldName]?.required && !fieldValue) {
        fieldValue = validator[fieldName].min;
      }

      newData.penalty = {
        penaltyScore:
          field === PENALTY_SCORE
            ? fieldValue ?? validator.penaltyScore.min
            : newData.penalty.penaltyScore,
        penaltyCap:
          field === PENALTY_SCORE
            ? newData.penalty.penaltyCap
            : {
                penaltyScore:
                  // eslint-disable-next-line
                  field === CAP_SCORE
                    ? fieldValue
                    : newData.penalty.penaltyCap?.penaltyScore
                    ? newData.penalty.penaltyCap?.penaltyScore
                    : DEFAULT_PENALTY_CAP_SCORE,

                penaltyCapThreshold:
                  // eslint-disable-next-line
                  field === CAP
                    ? fieldValue
                    : newData.penalty.penaltyCap?.penaltyCapThreshold
                    ? newData.penalty.penaltyCap?.penaltyCapThreshold
                    : DEFAULT_PENALTY_CAP_THRESHOLD,
              },
      };

      if (
        newData.penalty.penaltyCap &&
        (newData.penalty.penaltyCap.penaltyCapThreshold == null ||
          newData.penalty.penaltyCap.penaltyScore == null)
      ) {
        newData.penalty.penaltyCap = {
          penaltyCapThreshold: null,
          penaltyScore: null,
        };
      }

      toBeUpdatedFields['penalty'] = newData.penalty;
    } else {
      let fieldValue = newValue !== null ? validate(field, newValue) : null;
      if (validator[field]?.required && !fieldValue) {
        fieldValue = validator[field].min;
      }

      // @ts-expect-error
      newData[field] = fieldValue;
      // @ts-expect-error
      toBeUpdatedFields[field] = fieldValue;
    }
    if (newValue !== undefined) {
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);

      const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;

      const { solutionKey } = chosenSolution;

      syncConstraintToStore({
        data: {
          ...oldData,
          solutionKey,
        },
        toBeUpdatedFields,
        toBeSyncedKey,
        solutionKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_CONSTRAINT',
        payload: {
          data: {
            ...oldData,
            solutionKey: chosenSolution.stateData.solutionKey,
          },
          toBeSyncedKey,
          toBeUpdatedFields,
          solutionKey: chosenSolution.solutionKey,
        },
      });
    }
  };

  return (
    <div className="constraint-table">
      <div className="flex justify-between items-center">
        <span className="font-semibold ml-4 flex-grow-1">{title}</span>
        <Input
          className="search-input mx-2"
          size="middle"
          placeholder="Search"
          prefix={<Search />}
          value={searchText}
          onChange={onSearch}
          data-testid="search-input"
        />
        <Select
          className="search-input mx-2"
          value={sortOrder}
          optionFilterProp="children"
          options={sortOrderOptions}
          onChange={handleSortOrder}
          data-testid="select-sort-order"
        />
        <Button
          className="create-btn"
          onClick={createEmptyConstraint}
          icon={<Plus />}
          data-testid="create-constraint-btn"
        >
          {create}
        </Button>
      </div>
      <div
        className="ag-theme-alpine mt-2 ag-grid-curve"
        style={tableContainerStyle}
      >
        {/* @ts-ignore */}
        <AgGridReact
          ref={gridRef}
          rowData={filteredConstraints}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellEditRequest={onCellEditRequest}
          getRowId={(params) => params.data.constraintKey}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          suppressRowClickSelection
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default ConstraintList;
