import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { PostCreateCalculatedSets } from 'Services/ScenarioService';
import { ScenarioSlice, Set } from 'Models/Scenario';
import {
  getScenarioByKey,
  updateScenriosScenario,
  updateSolutionStateData,
} from './mutate_helpers';
import { getScenarioKey } from 'utils/ui-helper';
import { getStateData } from 'Models/ScenarioUtils';

export const getCalculatedSets = createAsyncThunk(
  'user/getCalculatedSets',
  async ({ solutionKey }: { solutionKey: string }) => {
    const res = await PostCreateCalculatedSets(solutionKey);

    return {
      data: res,
      solutionKey,
    };
  },
);

export const getCalculatedSetsReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: Set[];
      solutionKey: string;
    };
  },
) => {
  if (payload.data) {
    const { data: sets, solutionKey } = payload;

    if (!solutionKey) return;

    const scenarioKey = getScenarioKey(solutionKey);

    const targetScenario = getScenarioByKey(state, scenarioKey);

    if (!targetScenario) return;

    const stateData = _.cloneDeep(getStateData(solutionKey, targetScenario));

    const filteredSets = [
      ...stateData.constraintsEnvelop.sets.filter((x) => !x.isCalculated),
    ];

    stateData.constraintsEnvelop.sets = [...filteredSets, ...sets];

    updateScenriosScenario(
      state,
      scenarioKey,
      updateSolutionStateData(targetScenario, solutionKey, stateData),
    );
  }
};
