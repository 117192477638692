import _ from 'lodash';

import {
  getScenarioKey,
  getSolutionKey,
  replaceStringInObject,
} from 'utils/ui-helper';
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import {
  FetchScenariosMetaData,
  FetchScenario,
  PutUpdateScenario,
  PostCreateScenario,
  PostCloneScenario,
  PostSolveScenario,
  PostCreateOrUpdateConstraint,
  PostCreateOrUpdateSet,
  DeleteScenario,
  PostSolveScenarioStop,
  FetchSolution,
  FetchScenarioMeta,
  PostCreateOrUpdateSetLine,
  PostGamesLock,
  PostCreateCalculatedSets,
} from 'Services/ScenarioService';
import {
  ScenarioSlice,
  Scenario,
  Constraint,
  Set,
  ScenarioMetaData,
  ScenarioKey,
  Solution,
  SetLine,
  SolutionKey,
  NetworkKey,
  GamesLockPayload,
} from 'Models/Scenario';
import { NEW } from 'utils/variables';
import {
  saveVenue,
  saveVenueReducer,
  deleteVenue,
  deleteVenueReducer,
  updateVenueTeam,
  updateVenueTeamReducer,
  saveVenues,
  saveVenuesReducer,
} from './venueSlice';
import {
  saveTeam,
  saveTeamReducer,
  deleteTeam,
  deleteTeamReducer,
  saveOpponent,
  saveOpponentReducer,
} from './teamSlice';
import {
  updateScenriosScenario,
  getScenarioByKey,
  updateSolutionStateData,
  updateSolution,
  updateConstraint,
  updateSet,
  updateSetLine,
  updateOpponent,
  updateVenue,
  updateTeamVenueMap,
  updateTeam,
  updateNewTeam,
  updateSlotType,
  updateNetwork,
  updateNetworkCategory,
  updateRoundTemplate,
  updateTimeslot,
} from './mutate_helpers';
import {
  cloneScenarioWithSolution,
  cloneScenarioWithSolutionReducer,
  cloneScenarioWithSolveData,
  cloneScenarioWithSolveDataReducer,
  cloneSolution,
  cloneSolutionReducer,
  cloneSolveData,
  cloneSolveDataReducer,
  compareAndCreateSeed,
  compareAndCreateSeedReducer,
  deleteSolution,
  deleteSolutionReducer,
  deleteSolveData,
  deleteSolveDataReducer,
} from './solutionSlice';
import {
  createRround,
  createRoundReducer,
  updateRound,
  updateRoundReducer,
  deleteRound,
  deleteRoundReducer,
  updateSlot,
  updateSlotReducer,
  createOrUpdateSlotTypeReducer,
  createOrUpdateSlotType,
} from './roundSlice';
import { getScenarioMeta } from 'utils/scenario-helper';
import { SolveType } from 'Models/SolveTypes';
import { SolverParameterBase } from 'Models/SolverParameter';
import {
  Network,
  NetworkCategory,
  NetworkCategoryResponsePromiseType,
  NetworkResponsePromiseType,
} from 'Models/Network';
import {
  PostCreateOrUpdateNetwork,
  PostCreateOrUpdateNetworkCategory,
} from 'Services/NetworkService';
import { LockType, LockTypeLock } from 'Models/LockTypes';
import { Venue } from 'Models/Venue';
import { getStateData } from 'Models/ScenarioUtils';
import { getCalculatedSets, getCalculatedSetsReducer } from './setSlice';

export const getScenariosMeta = createAsyncThunk(
  'user/getScenariosMeta',
  async (season: number) => {
    const res = await FetchScenariosMetaData(season);

    return { data: res };
  },
);

export const getScenario = createAsyncThunk(
  'user/getScenario',
  async ({ season, scenarioKey }: { season: number; scenarioKey: string }) => {
    const res = await FetchScenario(season, scenarioKey);

    return { data: res };
  },
);

export const getSolution = createAsyncThunk(
  'user/getSolution',
  async ({
    scenarioKey,
    solutionKey,
  }: {
    scenarioKey: string;
    solutionKey: string;
  }) => {
    const res = await FetchSolution(scenarioKey, solutionKey);

    return {
      data: res,
      scenarioKey,
    };
  },
);

export const getScenarioWithoutSync = createAsyncThunk(
  'user/getScenarioWithoutSync',
  async ({ season, scenarioKey }: { season: number; scenarioKey: string }) => {
    const res = await FetchScenario(season, scenarioKey);

    return { data: res };
  },
);

export const getScenarioMetaData = createAsyncThunk(
  'user/getScenarioWithoutSync',
  async (scenarioKey: string) => {
    const res = await FetchScenarioMeta(scenarioKey);

    return { data: res };
  },
);

export const createScenario = createAsyncThunk(
  'user/createScenario',
  async (payload: ScenarioMetaData) => {
    const res = await PostCreateScenario(payload);

    return { data: res };
  },
);

export const cloneScenario = createAsyncThunk(
  'user/cloneScenario',
  async (payload: ScenarioMetaData) => {
    const res = await PostCloneScenario(payload);

    return { data: res };
  },
);

export const updateScenario = createAsyncThunk(
  'user/updateScenario',
  async (payload: { scenario: ScenarioMetaData }) => {
    const res = await PutUpdateScenario(payload.scenario);

    return { data: res, scenarioKey: payload.scenario.scenarioKey };
  },
);

export const deleteScenario = createAsyncThunk(
  'user/deleteScenario',
  async (payload: string) => {
    await DeleteScenario(payload);

    return { scenarioKey: payload };
  },
);

export const lockGames = createAsyncThunk(
  'user/lockGames',
  async (payload: {
    scenarioKey: string;
    solutionKey: string;
    solveDataKey: string;
    lockType: LockType;
    columnShortIds: string[];
    solutionStatus: string;
  }) => {
    const res = await PostGamesLock(payload);

    return { data: res };
  },
);

export const solveScenario = createAsyncThunk(
  'user/solveScenario',
  async (payload: {
    solutionKey: string | null;
    solveType: SolveType;
    solverParameters: SolverParameterBase[];
  }) => {
    const res = await PostSolveScenario(
      payload.solutionKey,
      payload.solveType,
      payload.solverParameters,
    );

    return { data: res };
  },
);

export const solveScenarioStop = createAsyncThunk(
  'user/solveScenarioStop',
  async (payload: { solutionKey: string | null }) => {
    await PostSolveScenarioStop(payload.solutionKey);

    return;
  },
);

export const saveConstraint = createAsyncThunk(
  'user/saveConstraint',
  async (payload: {
    constraint: Constraint;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await PostCreateOrUpdateConstraint(
      payload.constraint,
      payload.solutionStatus,
    );

    return { data: res, toBeSyncedKey: payload.constraint.toBeSyncedKey ?? '' };
  },
);

export const saveSet = createAsyncThunk(
  'user/saveSet',
  async (payload: { set: Set; solutionStatus: string | null | undefined }) => {
    const res = await PostCreateOrUpdateSet(
      payload.set,
      payload.solutionStatus,
    );

    const setLineToBeSyncedKeys = payload.set.setLines
      .filter((item) => item.setLineStatus === NEW && item.toBeSyncedKey)
      .map((item) => item.toBeSyncedKey);

    return {
      data: res,
      toBeSyncedKey: payload.set.toBeSyncedKey ?? '',
      setLineToBeSyncedKeys,
    };
  },
);

export const saveSetLine = createAsyncThunk(
  'user/saveSetLine',
  async (data: {
    payload: {
      setLine: SetLine;
      solutionKey: string;
      scenarioKey: string;
      toBeSyncedKey?: string;
    };
    solutionStatus: string | null | undefined;
  }) => {
    const res = await PostCreateOrUpdateSetLine(
      data.payload,
      data.solutionStatus,
    );

    let setKey = data.payload.setLine.setKey;

    if (!setKey && data.payload.setLine.setLineKey !== NEW) {
      setKey = data.payload.setLine.setLineKey
        .split('-')
        .slice(0, -1)
        .join('-');
    }

    return {
      data: res,
      setKey: setKey ?? '',
      solutionKey: data.payload.solutionKey,
      toBeSyncedKey: data.payload.toBeSyncedKey ?? '',
    };
  },
);

export const saveNetwork = createAsyncThunk(
  'user/saveNetwork',
  async (payload: {
    scenarioKey: ScenarioKey;
    solutionKey: SolutionKey;
    network: Network;
    solutionStatus: string | null | undefined;
  }) => {
    const { scenarioKey, solutionKey, network, solutionStatus } = payload;
    const res = await PostCreateOrUpdateNetwork(
      scenarioKey,
      solutionKey,
      network,
      solutionStatus,
    );

    const toBeSyncedKey = network.toBeSyncedKey ?? '';

    const networkCategoryToBeSyncedKeys = network.networkCategories
      .filter(
        (item) => item.networkCategoryStatus === NEW && item.toBeSyncedKey,
      )
      .map((item) => item.toBeSyncedKey);

    return {
      data: res,
      toBeSyncedKey,
      networkCategoryToBeSyncedKeys,
    };
  },
);

export const saveNetworkCategory = createAsyncThunk(
  'user/saveNetworkCategory',
  async (payload: {
    scenarioKey: ScenarioKey;
    solutionKey: SolutionKey;
    networkKey: NetworkKey;
    networkCategory: NetworkCategory;
    solutionStatus: string | null | undefined;
  }) => {
    const {
      scenarioKey,
      solutionKey,
      networkKey,
      networkCategory,
      solutionStatus,
    } = payload;
    const res = await PostCreateOrUpdateNetworkCategory(
      scenarioKey,
      solutionKey,
      networkKey,
      networkCategory,
      solutionStatus,
    );

    const toBeSyncedKey = networkCategory.toBeSyncedKey ?? '';

    return { data: res, networkKey, toBeSyncedKey };
  },
);

export const selectSeason = ({ scenario }: { scenario: ScenarioSlice }) => {
  return scenario.season;
};

export const selectScenarioMetas = ({
  scenario,
}: {
  scenario: ScenarioSlice;
}) => scenario.scenarios;

export const selectScenario = createSelector(
  [
    (state: any) => state.scenario.scenarios,
    (state: any) => state.scenario.scenarioKey,
  ],
  (scenarios: ScenarioMetaData[], scenarioKey: string) => {
    return (
      scenarios.find((item) => item.scenarioKey === scenarioKey)?.scenario ??
      null
    );
  },
);

export const selectSelectedSolution = createSelector(
  [
    selectScenario,
    (state: any) => state.scenario.scenarioKey,
    (state: any) => state.scenario.solutionKey,
  ],
  (
    currentScenario: Scenario | null,
    scenarioKey: string,
    solutionKey: string,
  ) => {
    if (currentScenario) {
      const selectedSolution =
        currentScenario.optimizationEnvelop?.solutions.find(
          (solution) => solution.solutionKey === solutionKey,
        );

      return selectedSolution ?? null;
    }

    return null;
  },
);
export const selectConstraints = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) =>
    solution?.stateData.constraintsEnvelop.constraints,
);
export const selectTeams = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) => solution?.stateData.teams,
);
export const selectVenues = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) => solution?.stateData.venues,
);
export const selectVenueTeams = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) => solution?.stateData.venueTeams,
);
export const selectSets = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) => solution?.stateData.constraintsEnvelop.sets,
);

export const selectRoundTemplates = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) => solution?.stateData.roundTemplates,
);

export const selectNetworks = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) =>
    solution?.stateData.roundsDictionaries.networks,
);

export const selectSlotTypes = createSelector(
  selectSelectedSolution,
  (solution: Solution | null) =>
    solution?.stateData.roundsDictionaries.slotTypes,
);

export const selectPrevSolution = ({
  scenario,
}: {
  scenario: ScenarioSlice;
}) => {
  const currentScenario = scenario.scenarios.find(
    (item) => item.scenarioKey === scenario.scenarioKey,
  )?.scenario;

  if (currentScenario) {
    const selectedSolution =
      currentScenario.optimizationEnvelop?.solutions.find(
        (solution) => solution.solutionKey === scenario.prevSolutionKey,
      );

    return selectedSolution ?? null;
  }

  return null;
};

export const selectCurrentScenarioKey = ({
  scenario,
}: {
  scenario: ScenarioSlice;
}) => scenario.scenarioKey;

export const selectSolutionKey = ({ scenario }: { scenario: ScenarioSlice }) =>
  scenario.solutionKey;

export const selectSolveDataKey = ({ scenario }: { scenario: ScenarioSlice }) =>
  scenario.solveDataKey;

export const selectClonedSolutionLocalKeys = ({
  scenario,
}: {
  scenario: ScenarioSlice;
}) => scenario.toBeClonedSolutionLocalKeys;

export const selectLocalSolutionKeyMap = ({
  scenario,
}: {
  scenario: ScenarioSlice;
}) => scenario.clonedSolutionLocalKeyMap;

const initialState: ScenarioSlice = {
  scenarios: [],
  scenarioKey: '',
  prevSolutionKey: '',
  solutionKey: '',
  solveDataKey: '',
  season: new Date().getUTCFullYear(),
  isLoaded: false,
  toBeClonedSolutionLocalKeys: [],
  clonedSolutionLocalKeyMap: {},
};

const scenarioSlice = createSlice({
  name: 'app/scenarios',
  initialState: {
    ...initialState,
  },
  reducers: {
    setScenarioKey(state, { payload }) {
      state.scenarioKey = payload;
    },
    setSolutionKey(state, { payload }) {
      state.prevSolutionKey = state.solutionKey;
      state.solutionKey = payload;
    },
    clearSolutionAndSolveDataKey(state) {
      state.prevSolutionKey = '';
      state.solutionKey = '';
      state.solveDataKey = '';
    },
    setSolveDataKey(state, { payload }) {
      state.solveDataKey = payload;
    },
    setSeason(state, { payload }) {
      state.season = payload;
    },
    setSolutionLocalKeyMap(
      state,
      {
        payload,
      }: {
        payload: {
          key: string;
          value: string;
        };
      },
    ) {
      const { key, value } = payload;

      state.clonedSolutionLocalKeyMap[key] = value;
    },
    addClonedSolutionKey(state, { payload }) {
      state.toBeClonedSolutionLocalKeys = [
        ...state.toBeClonedSolutionLocalKeys,
        payload,
      ];
    },
    removeFirstClonedSolutionLocalKey(state) {
      state.toBeClonedSolutionLocalKeys.shift();
    },
    addSolution(
      state,
      {
        payload,
      }: {
        payload: {
          solution: Solution;
          toBeUpdatedKey?: string;
        };
      },
    ) {
      const targetScenarioKey = state.scenarioKey;
      const targetScenario = getScenarioByKey(state, targetScenarioKey);

      if (!targetScenario) return;

      const { solution, toBeUpdatedKey } = payload;

      if (!toBeUpdatedKey) {
        targetScenario.optimizationEnvelop.solutions.push(solution);
      } else {
        targetScenario.optimizationEnvelop.solutions =
          targetScenario.optimizationEnvelop.solutions.map((item) => {
            if (item.solutionKey === toBeUpdatedKey) {
              return solution;
            }
            return item;
          });
      }
      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    },
    syncSolutionId(
      state,
      {
        payload,
      }: {
        payload: {
          toBeUpdatedKey: string;
          solution: Solution;
        };
      },
    ) {
      const { solution, toBeUpdatedKey } = payload;
      const targetScenarioKey = getScenarioKey(solution.solutionKey);
      const targetScenario = getScenarioByKey(state, targetScenarioKey);

      if (!targetScenario) return;

      const solutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (item) => item.solutionKey === toBeUpdatedKey,
        );

      if (solutionIndex === -1) return;

      let targetSolution = _.cloneDeep(
        targetScenario.optimizationEnvelop.solutions[solutionIndex],
      );
      const keys = targetSolution.stateData.solutionKey.split('-');
      const oldSolutionId = keys.length >= 3 ? keys[2] : '';

      if (!oldSolutionId) return;

      const newKeys = solution.solutionKey.split('-');
      targetSolution.solutionKey = solution.solutionKey;
      targetSolution.solutionIndex = solution.solutionIndex;

      targetSolution = replaceStringInObject(
        targetSolution,
        oldSolutionId,
        newKeys[2],
      );

      targetScenario.optimizationEnvelop.solutions[solutionIndex] =
        targetSolution;

      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    },

    updateSolveData(state, { payload }) {
      const solveData = payload;

      const targetScenarioKey = state.scenarioKey;
      const targetScenario = getScenarioByKey(state, targetScenarioKey);

      if (!targetScenario) return;

      const solutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (item) => item.solutionKey === solveData.solutionKey,
        );

      if (solutionIndex === -1) return;

      const solveDataIndex = targetScenario.optimizationEnvelop.solutions[
        solutionIndex
      ].solveDataList.findIndex(
        (item) => item.solveDataKey === solveData.solveDataKey,
      );
      if (solveDataIndex !== -1) {
        targetScenario.optimizationEnvelop.solutions[
          solutionIndex
        ].solveDataList[solveDataIndex] = solveData;
        updateScenriosScenario(state, targetScenarioKey, targetScenario);
      }
    },
    syncGamesLock(state, { payload }: { payload: GamesLockPayload }) {
      const {
        scenarioKey,
        solutionKey,
        solveDataKey,
        columnShortIds,
        lockType,
      } = payload;

      const targetScenario = getScenarioByKey(state, scenarioKey);
      if (!targetScenario) return;

      const targetSolution = targetScenario.optimizationEnvelop.solutions.find(
        (x) => x.solutionKey === solutionKey,
      );
      if (!targetSolution) return;

      const targetSolveData = targetSolution.solveDataList.find(
        (x) => x.solveDataKey === solveDataKey,
      );
      if (!targetSolveData) return;

      for (let i = 0; i < targetSolveData.optimizedColumns.length; i++) {
        if (
          columnShortIds.includes(
            targetSolveData.optimizedColumns[i]?.columnShortId,
          )
        ) {
          targetSolveData.optimizedColumns[i].isLocked =
            lockType === LockTypeLock;
        }
      }

      updateScenriosScenario(
        state,
        scenarioKey,
        updateSolutionStateData(
          targetScenario,
          solutionKey,
          targetSolution.stateData,
        ),
      );
    },
    syncConstraint(state, { payload }: { payload: Constraint }) {
      const targetScenarioKey = state.scenarioKey;
      const updatedConstraint = payload;

      updateConstraint(targetScenarioKey, updatedConstraint, state);
    },
    syncSet(state, { payload }) {
      const targetScenarioKey = state.scenarioKey;
      const updatedSet = payload;

      updateSet(targetScenarioKey, updatedSet, state);
    },
    syncSetline(state, { payload }) {
      updateSetLine(
        state.scenarioKey,
        payload.data.setKey,
        payload.solutionKey,
        payload.data,
        state,
      );
    },
    syncOpponent(state, { payload }) {
      updateOpponent(
        state.scenarioKey,
        payload.solutionKey,
        payload.updatedOpponent,
        payload.opponentsPenalty,
        state,
      );
    },
    syncVenue(state, { payload }) {
      const venues = payload.data as Venue[];

      updateVenue(state.scenarioKey, payload.solutionKey, venues, state);
    },
    syncTeamVenueMap(state, { payload }) {
      const { venueKey, teamKey, type, solutionKey } = payload;

      updateTeamVenueMap(
        state.scenarioKey,
        solutionKey,
        {
          venueKey,
          teamKey,
          type,
        },
        state,
      );
    },
    syncTeam(state, { payload }) {
      const { solutionKey, data } = payload;

      updateTeam(state.scenarioKey, solutionKey, data, state);
    },
    syncNewTeam(state, { payload }) {
      const { venue, team, venueTeam, solutionKey } = payload;

      updateNewTeam(
        state.scenarioKey,
        solutionKey,
        team,
        venue,
        state,
        venueTeam,
      );
    },
    syncSlotType(state, { payload }) {
      updateSlotType(
        state.scenarioKey,
        payload.solutionKey,
        payload.updatedSlotType,
        state,
      );
    },
    syncNetwork(state, { payload }) {
      const targetScenarioKey = state.scenarioKey;
      const { solutionKey, data } = payload;

      updateNetwork(targetScenarioKey, solutionKey, data, state);
    },

    syncNetworkCategory(state, { payload }) {
      const targetScenarioKey = state.scenarioKey;
      const { solutionKey, networkKey, data } = payload;

      updateNetworkCategory(
        targetScenarioKey,
        solutionKey,
        networkKey,
        data,
        state,
      );
    },
    syncRoundTemplate(state, { payload }) {
      const { solutionKey, data } = payload;

      updateRoundTemplate(state.scenarioKey, solutionKey, data, state);
    },

    syncRoundTemplateWeekStartDay(state, { payload }) {
      const { solutionKey, data } = payload;
      const { roundTemplateWeekStartDay } = data;

      const targetScenario = getScenarioByKey(state, state.scenarioKey);

      if (!targetScenario) return;

      const stateData = _.cloneDeep(getStateData(solutionKey, targetScenario));

      if (!stateData) return;

      stateData.roundTemplateWeekStartDay = roundTemplateWeekStartDay;

      updateScenriosScenario(
        state,
        state.scenarioKey,
        updateSolutionStateData(targetScenario, solutionKey, stateData),
      );
    },
    syncTimeslot(state, { payload }) {
      const { solutionKey, roundTemplateKey, roundDayKey, data } = payload;

      updateTimeslot(
        state.scenarioKey,
        solutionKey,
        roundTemplateKey,
        roundDayKey,
        data,
        state,
      );
    },
    syncToBeClonedSolution(
      state,
      {
        payload,
      }: {
        payload: Solution;
      },
    ) {
      const targetScenarioKey = state.scenarioKey;
      const newSolution = payload;

      const targetScenario = getScenarioByKey(state, targetScenarioKey);
      if (targetScenario && newSolution.toBeSyncedKey) {
        targetScenario.optimizationEnvelop.solutions.push(newSolution);

        updateScenriosScenario(state, targetScenarioKey, targetScenario);

        state.prevSolutionKey = state.solutionKey;

        state.solutionKey = newSolution.toBeSyncedKey;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScenariosMeta.fulfilled, (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        const existingScenarioMap = state.scenarios.reduce(
          (acc: Record<string, Scenario | null>, item) => {
            if (item.scenario) {
              acc[item.scenarioKey] = item.scenario;
            } else {
              acc[item.scenarioKey] = null;
            }

            return acc;
          },
          {},
        );
        state.scenarios = payload.data.map((item) => ({
          ...item,
          scenario: existingScenarioMap[item.scenarioKey] ?? null,
        }));
      }
    });
    builder.addCase(getScenarioMetaData.fulfilled, (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        const solutionsMeta = payload.data;
        const targetScenario = state.scenarios
          .filter((item) => item.scenarioKey === state.scenarioKey)
          .map((item) => {
            return {
              logo: item.logo,
              name: item.name,
              scenario: item.scenario ?? null,
              scenarioKey: item.scenarioKey,
              season: item.season,
            };
          })[0] as Scenario;
        targetScenario.scenarioStatus = '';
        targetScenario.createdBy = '';
        if (targetScenario && !targetScenario.scenario) {
          targetScenario.scenario = {
            ...targetScenario,
            optimizationEnvelop: {
              solutions: solutionsMeta,
            },
          };
          updateScenriosScenario(
            state,
            state.scenarioKey,
            targetScenario.scenario,
          );
        } else if (targetScenario?.scenario) {
          targetScenario.scenario.optimizationEnvelop = {
            solutions: solutionsMeta,
          };
          updateScenriosScenario(
            state,
            state.scenarioKey,
            targetScenario.scenario,
          );
        }
        if (solutionsMeta.length > 0) {
          const defaultSolution = solutionsMeta[solutionsMeta.length - 1];
          const solveDataList = defaultSolution.solveDataList;
          if (solveDataList.length > 0 && !state.solveDataKey) {
            state.solveDataKey =
              solveDataList[solveDataList.length - 1].solveDataKey;
          }

          const newSolutionKey =
            state.solutionKey ?? defaultSolution.solutionKey;

          if (newSolutionKey !== state.solutionKey) {
            state.prevSolutionKey = state.solutionKey;
          }

          state.solutionKey = newSolutionKey;
        }
      }
    });
    builder.addCase(getScenario.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const targetScenarioKey = payload.data?.scenarioKey;

        const newScenario = payload.data;

        updateScenriosScenario(state, targetScenarioKey, newScenario);

        const defaultSolution = newScenario.optimizationEnvelop.solutions[0];
        if (defaultSolution && !state.solveDataKey) {
          if (defaultSolution.solveDataList[0]) {
            state.solveDataKey = defaultSolution.solveDataList[0].solveDataKey;
          }

          state.solutionKey = defaultSolution.solutionKey;
        }
      }
    });
    builder.addCase(getSolution.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const { data: solution, scenarioKey } = payload;

        updateSolution(state, scenarioKey, solution.solutionKey, solution);

        if (solution) {
          state.solveDataKey =
            state.solveDataKey ??
            solution.solveDataList[solution.solveDataList.length - 1]
              .solveDataKey;
          state.prevSolutionKey = state.solutionKey;
          state.solutionKey = solution.solutionKey;
        }
      }
    });
    builder.addCase(updateScenario.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const oldScenarioKey = payload.scenarioKey;
        const targetScenarioKey = payload.data?.scenarioKey;
        if (oldScenarioKey === targetScenarioKey) {
          const updateScenarioMeta = payload.data;
          state.scenarios = state.scenarios.map((scenario) =>
            scenario.scenarioKey === updateScenarioMeta.scenarioKey
              ? {
                  ...scenario,
                  name: updateScenarioMeta.name,
                  season: updateScenarioMeta.season,
                  logo: updateScenarioMeta.logo,
                }
              : scenario,
          );
        } else {
          state.scenarios = state.scenarios.filter(
            (scenario) => scenario.scenarioKey !== oldScenarioKey,
          );
        }
      }
    });
    builder.addCase(createScenario.fulfilled, (state, { payload }) => {
      if (payload.data) {
        if (payload.data.season === state.season.toString()) {
          const scenarioMeta = getScenarioMeta(payload.data);
          state.scenarios = [...state.scenarios, scenarioMeta];
        }
      }
    });
    builder.addCase(cloneScenario.fulfilled, (state, { payload }) => {
      if (payload.data) {
        if (payload.data.season === state.season.toString()) {
          state.scenarios = [...state.scenarios, payload.data];
        }
      }
    });
    builder.addCase(deleteScenario.fulfilled, (state, { payload }) => {
      if (payload.scenarioKey) {
        state.scenarios = state.scenarios.filter(
          (item) => item.scenarioKey !== payload.scenarioKey,
        );
      }
    });
    builder.addCase(solveScenario.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const targetScenarioKey = getScenarioKey(payload.data.solutionKey);
        const targetScenario = getScenarioByKey(state, targetScenarioKey);

        if (targetScenarioKey && targetScenario && payload.data) {
          const solutions = [...targetScenario.optimizationEnvelop.solutions];

          let solutionInd = solutions.findIndex(
            (x) => x.solutionKey === payload.data?.solutionKey,
          );

          solutions[solutionInd] = payload.data;

          targetScenario.optimizationEnvelop.solutions = solutions;

          updateScenriosScenario(state, targetScenarioKey, targetScenario);
        }
      }
    });
    builder.addCase(saveConstraint.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const { solutionKey } = payload.data;
        const keyParts = solutionKey.split('-');
        const targetScenarioKey = `${keyParts[0]}-${keyParts[1]}`;
        const updatedConstraint = payload.data;

        updateConstraint(
          targetScenarioKey,
          updatedConstraint,
          state,
          payload.toBeSyncedKey,
        );
      }
    });

    builder.addCase(saveSet.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const updatedSet = payload.data;
        const { solutionKey, setLines } = updatedSet;
        const allIds = solutionKey.split('-');
        const targetScenarioKey = `${allIds[0]}-${allIds[1]}`;

        const setLineToBeSyncedKeys = payload.setLineToBeSyncedKeys ?? [];

        updateSet(targetScenarioKey, updatedSet, state, payload.toBeSyncedKey);

        setLineToBeSyncedKeys.forEach((key, i) => {
          updateSetLine(
            targetScenarioKey,
            updatedSet.setKey,
            solutionKey,
            setLines[i],
            state,
            key,
          );
        });
      }
    });
    builder.addCase(saveSetLine.fulfilled, (state, { payload }) => {
      if (payload.data) {
        const { setKey, solutionKey, toBeSyncedKey } = payload;
        const updatedSetLine = payload.data;
        const keyParts = solutionKey.split('-');
        const targetScenarioKey = `${keyParts[0]}-${keyParts[1]}`;

        updateSetLine(
          targetScenarioKey,
          setKey,
          solutionKey,
          updatedSetLine,
          state,
          toBeSyncedKey,
        );
      }
    });
    // @ts-expect-error

    builder.addCase(
      saveNetwork.fulfilled,
      (state: ScenarioSlice, { payload }: NetworkResponsePromiseType) => {
        if (payload.data) {
          const {
            data: updatedNetwork,
            toBeSyncedKey,
            networkCategoryToBeSyncedKeys,
          } = payload;

          const targetScenarioKey = getScenarioKey(updatedNetwork.networkKey);
          const solutionKey = getSolutionKey(updatedNetwork.networkKey);

          const networkCategoryKeys = networkCategoryToBeSyncedKeys ?? [];

          updateNetwork(
            targetScenarioKey,
            solutionKey,
            updatedNetwork,
            state,
            toBeSyncedKey,
          );

          networkCategoryKeys.forEach((key, i) => {
            updateNetworkCategory(
              targetScenarioKey,
              solutionKey,
              updatedNetwork.networkKey,
              updatedNetwork.networkCategories[i],
              state,
              key,
            );
          });
        }
      },
    );
    builder.addCase(
      saveNetworkCategory.fulfilled,
      (
        state: ScenarioSlice,
        { payload }: NetworkCategoryResponsePromiseType,
      ) => {
        if (payload.data) {
          const {
            data: updatedNetworkCategory,
            networkKey,
            toBeSyncedKey,
          } = payload;

          const targetScenarioKey = getScenarioKey(
            updatedNetworkCategory.networkCategoryKey,
          );
          const solutionKey = getSolutionKey(
            updatedNetworkCategory.networkCategoryKey,
          );

          updateNetworkCategory(
            targetScenarioKey,
            solutionKey,
            networkKey,
            updatedNetworkCategory,
            state,
            toBeSyncedKey ?? '',
          );
        }
      },
    );
    builder.addCase(
      createOrUpdateSlotType.fulfilled,
      createOrUpdateSlotTypeReducer,
    );
    builder.addCase(saveVenue.fulfilled, saveVenueReducer);
    // @ts-expect-error
    builder.addCase(saveVenues.fulfilled, saveVenuesReducer);
    builder.addCase(deleteVenue.fulfilled, deleteVenueReducer);
    builder.addCase(saveTeam.fulfilled, saveTeamReducer);
    builder.addCase(deleteTeam.fulfilled, deleteTeamReducer);
    builder.addCase(updateVenueTeam.fulfilled, updateVenueTeamReducer);
    builder.addCase(saveOpponent.fulfilled, saveOpponentReducer);
    builder.addCase(cloneSolveData.fulfilled, cloneSolveDataReducer);
    builder.addCase(
      compareAndCreateSeed.fulfilled,
      compareAndCreateSeedReducer,
    );
    builder.addCase(deleteSolveData.fulfilled, deleteSolveDataReducer);
    builder.addCase(cloneSolution.fulfilled, cloneSolutionReducer);
    builder.addCase(deleteSolution.fulfilled, deleteSolutionReducer);
    builder.addCase(
      cloneScenarioWithSolution.fulfilled,
      cloneScenarioWithSolutionReducer,
    );
    builder.addCase(
      cloneScenarioWithSolveData.fulfilled,
      cloneScenarioWithSolveDataReducer,
    );
    builder.addCase(createRround.fulfilled, createRoundReducer);
    builder.addCase(updateRound.fulfilled, updateRoundReducer);
    builder.addCase(deleteRound.fulfilled, deleteRoundReducer);
    builder.addCase(updateSlot.fulfilled, updateSlotReducer);
    builder.addCase(getCalculatedSets.fulfilled, getCalculatedSetsReducer);
  },
});

export const {
  setScenarioKey,
  setSolutionKey,
  clearSolutionAndSolveDataKey,
  addSolution,
  setSolveDataKey,
  setSeason,
  syncGamesLock,
  updateSolveData,
  syncConstraint,
  syncToBeClonedSolution,
  syncSet,
  syncSetline,
  syncOpponent,
  syncSolutionId,
  addClonedSolutionKey,
  removeFirstClonedSolutionLocalKey,
  setSolutionLocalKeyMap,
  syncVenue,
  syncTeamVenueMap,
  syncTeam,
  syncNewTeam,
  syncSlotType,
  syncNetwork,
  syncNetworkCategory,
  syncRoundTemplate,
  syncTimeslot,
  syncRoundTemplateWeekStartDay,
} = scenarioSlice.actions;

export default scenarioSlice.reducer;
