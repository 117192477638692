import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Header } from 'Components/Layout/Header';
import Empty from 'Components/Elements/Common/Empty/Empty';
import {
  ChevronsRight,
  ChevronsLeft,
  VenueIcon,
  OpponentsIcon,
  TeamIcon,
  SlotIcon,
  ConstraintsIcon,
} from 'Components/Elements/Icons';
import { Drawer } from 'Components/Elements/Drawer';
import Solutions from 'Components/Elements/Solution/Solutions';
import SolveDataTable from 'Components/Elements/Solve/SolveDataTable';
import { TeamList } from 'Components/Elements/Team';
import { VenueList } from 'Components/Elements/Venue';
import OpponentMatchTable from 'Components/Elements/Opponents/OpponentMatchTable';
import useScenario from 'storeHooks/useScenario';
import useNetwork from 'storeHooks/useNetwork';
import SlotSection from 'Components/Elements/Slots';
import showMessage from 'Components/Elements/Common/Message';
import {
  leftSidebarOpenWidth,
  rightDrawerMinWidth,
  rightDrawerDefaultWidth,
  bottomDrawerDefaultHeight,
  bottomDrawerMinimumHeight,
} from 'Components/Elements/Drawer/constants';
import SolveScenario from 'Components/Modules/Scenario/SolveScenario';
import ConstraintsGroup from 'Components/Elements/ConstraintGroup';
import { Divider, Tooltip, Spin } from 'antd';
import { PenaltyList } from 'Components/Elements/Penalties';
import ViewLog from 'Components/Modules/Scenario/ViewLog';
import { SignalREventType } from 'Models/SignalRMessage';
import {
  SolutionStatusCreated,
  SolutionStatusSolveRequested,
  SolutionStatusSolving,
  SolutionStatusStopRequested,
  SolutionStatusStopped,
  SolutionStatusStopping,
} from 'Models/SolutionStatuses';
import i18n from 'plugins/i18next';
import { ExportModelSummary } from 'Services/ScenarioService';
import useQueue from 'storeHooks/useQueue';
import useStatusHubMessages from 'customHooks/useStatusHubMessages';
import useConnectHubs from 'customHooks/useConnectHubs';
import useApplicationLogHubMessages from 'customHooks/useApplicationLogHubMessages';
import './style.scss';

export type ScenarioOptions =
  | 'penalty'
  | 'team'
  | 'venue'
  | 'opponent'
  | 'slot'
  | 'constraint'
  | 'log';

const getMaxInnerHeight = (isFooterVisible?: boolean) => {
  let headerheight = 0;
  let footerHeight = 0;

  const headerElement: HTMLDivElement = document.querySelector(
    '.optimal-app-header',
  )!;
  // @ts-expect-error
  const footerElement: HTMLDivElement = document.querySelector('footer')!;

  if (headerElement) {
    headerheight = headerElement.offsetHeight;
  }

  if (footerElement && isFooterVisible) {
    footerHeight = footerElement.offsetHeight;
  }

  const { innerHeight } = window;

  let offsetValue = 58;

  if (innerHeight > 810 && innerHeight <= 888) {
    offsetValue = 65;
  } else if (innerHeight > 888 && innerHeight <= 986) {
    offsetValue = 74;
  } else if (innerHeight > 986 && innerHeight <= 1110) {
    offsetValue = 83;
  } else if (innerHeight > 1110 && innerHeight <= 1184) {
    offsetValue = 87;
  } else if (innerHeight > 1184 && innerHeight <= 1132) {
    offsetValue = 90;
  } else if (innerHeight > 1132) {
    offsetValue = 99;
  }

  return window.innerHeight - headerheight - footerHeight - offsetValue;
};

const showFullscreenLoader = false;

export const handleExportModelSummary = async (
  scenarioKey: string,
  solutionKey: string,
  solveDataKey: string,
  setIsDownloading: (x: boolean) => void,
) => {
  try {
    setIsDownloading(true);
    const response = await ExportModelSummary(
      scenarioKey,
      solutionKey,
      solveDataKey,
    );

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      `data:${response.mimeType};charset=utf-8;base64,${response.content}`,
    );
    downloadLink.setAttribute('download', response.fileName);
    downloadLink.click();

    showMessage({
      key: solveDataKey,
      type: 'success',
      content: i18n.t('GENERAL.FEEDBACK.SOLUTION.MODEL_SUMMARY_DOWNLOADED'),
    });
  } catch (err) {
    //
  }

  setIsDownloading(false);
};

function Scenario() {
  const { t } = useTranslation();
  const {
    currentScenarioKey,
    selectedSolution,
    setCurrentSolutionKey,
    setCurrentSolveDataKey,
    solveData,
    stateData,
    solveScenarioStop,
  } = useScenario();
  const { isSaving } = useNetwork();
  const { queue } = useQueue();
  const [searchParams] = useSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const scheduleRef = useRef<HTMLDivElement>(null);
  const lastSideWidth = useRef(0);
  const lastBottomHeight = useRef(0);
  const loaded = useRef(false);
  const [isSolutionsVisible, setIsSolutionsVisible] = useState(false);
  const [isModelSummaryBtnVisible, setIsModelSummaryBtnVisible] =
    useState(false);
  const [isModelSummaryDownloading, setIsModelSummaryDownloading] =
    useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [tempWidth, setTempWidth] = useState(0);
  const [tempHeight, setTempHeight] = useState(0);
  const [agGridHeight, setAgGridHeight] = useState(0);
  const [agGridWidth, setAgGridWidth] = useState(0);
  const [isDrawerFullScreen, setIsDrawerFullScreen] = useState(true);
  const [activeElement, setActiveElement] = useState<ScenarioOptions | null>(
    null,
  );
  const [isLogButtonVisible, setIsLogButtonVisible] = useState(false);
  const [isSolveModalVisible, setIsSolveModalVisible] = useState(false);
  const { applicationLogHub, connectApplicationLogHubHandler, statusHub } =
    useConnectHubs();

  const { message: applicationLog } = useApplicationLogHubMessages({
    applicationLogHub,
  });

  useStatusHubMessages({
    statusHub,
    eventIds: [SignalREventType.SolutionEvent],
    setIsLogButtonVisible,
    connectApplicationLogHubHandler,
  });

  const activeWindowElement = useMemo(() => {
    switch (activeElement) {
      case 'penalty':
        return <PenaltyList />;
      case 'team':
        return <TeamList />;
      case 'venue':
        return <VenueList />;
      case 'opponent':
        return <OpponentMatchTable />;
      case 'constraint':
        return <ConstraintsGroup />;
      case 'slot':
        return <SlotSection />;
      case 'log':
        return <ViewLog applicationLog={applicationLog} />;
      default:
        return null;
    }
  }, [activeElement, applicationLog]);

  const haveAvailableSlots = useMemo(() => {
    if (solveData && stateData) {
      return solveData.slotRows?.length > 0;
    }
    return false;
  }, [solveData, stateData]);

  const toggleResultSectionVisibility = () =>
    setIsSolutionsVisible((prev) => !prev);

  const adjustGridHeight = () => {
    const heightOffset = tempHeight || tempWidth ? 68 : 150;

    setAgGridHeight(
      window.innerHeight - tempHeight - heightOffset - (tempWidth > 0 ? 12 : 0),
    );
  };

  const adjustGridWidth = () => {
    if (isSolutionsVisible) {
      setAgGridWidth(
        document.body.clientWidth - tempWidth - leftSidebarOpenWidth - 6,
      );
    } else {
      setAgGridWidth(document.body.clientWidth - tempWidth - 48);
    }
  };

  const onOpenRightDrawer = () => {
    setTempHeight(0);

    if (tempWidth) {
      setTempWidth(0);
      adjustGridWidth();
    } else {
      setTempWidth(rightDrawerDefaultWidth);
    }
  };

  const onOpenBottomDrawer = () => {
    setTempWidth(0);
    setIsDrawerFullScreen(true);

    if (tempHeight) {
      setTempHeight(0);
      adjustGridWidth();
    } else {
      setTempHeight(bottomDrawerDefaultHeight);
    }

    adjustGridHeight();
  };

  const onCloseBottomDrawer = () => {
    setTempHeight(0);
    setActiveElement(null);
  };

  const onCloseRightDrawer = () => {
    setTempWidth(0);
    setActiveElement(null);
  };

  const onDockRight = () => {
    onOpenRightDrawer();
  };

  const onDockDown = () => {
    onOpenBottomDrawer();
  };

  const exportModelSummary = async () => {
    if (!currentScenarioKey || !selectedSolution || !solveData) return;

    await handleExportModelSummary(
      currentScenarioKey,
      selectedSolution.solutionKey,
      solveData.solveDataKey,
      setIsModelSummaryDownloading,
    );
  };

  const onSideDrawerWidthChange = (value: number) => {
    setIsDrawerFullScreen(false);

    const minLength = value <= rightDrawerMinWidth;
    const maxLength = document.body.clientWidth - value <= leftSidebarOpenWidth;
    const regularLength = document.body.clientWidth - value;

    if (minLength) {
      const length = value === 0 ? lastSideWidth.current : rightDrawerMinWidth;
      setTempWidth(length);
      lastSideWidth.current = length;
    } else if (maxLength) {
      setTempWidth(document.body.clientWidth - leftSidebarOpenWidth);
      lastSideWidth.current = document.body.clientWidth - leftSidebarOpenWidth;
    } else {
      setTempWidth(regularLength);
      lastSideWidth.current = regularLength;
    }
  };

  const onBottomDrawerHeightChange = (value: number) => {
    setIsDrawerFullScreen(false);

    const regularHeight = window.innerHeight - value;
    // this 200 has to be checked on other device.
    // window.screen.height - max clientX gives me 200
    const minHeight = regularHeight <= bottomDrawerMinimumHeight;

    if (minHeight) {
      const height =
        value === 0 ? lastBottomHeight.current : bottomDrawerMinimumHeight;

      setTempHeight(height);
      lastBottomHeight.current = height;
    } else {
      const calculated = value === 0 ? lastBottomHeight.current : regularHeight;

      setTempHeight(calculated);
      lastBottomHeight.current = calculated;
    }
  };

  const getCurrentGridWidth = () => {
    if (
      scheduleRef.current &&
      containerRef.current &&
      (isSolutionsVisible || tempWidth)
    ) {
      return (
        containerRef.current.clientWidth - tempWidth - leftSidebarOpenWidth
      );
    }

    if (containerRef.current && tempHeight) {
      return isSolutionsVisible
        ? containerRef.current.clientWidth - leftSidebarOpenWidth
        : containerRef.current.clientWidth - 36;
    }
    return 900;
  };

  const gridContentWidth = getCurrentGridWidth();

  const isDrawerVisible = tempWidth || tempHeight;

  const onOpenTeamDrawer = () => {
    setActiveElement('team');
    onOpenBottomDrawer();
  };

  const onOpenVenueDrawer = () => {
    setActiveElement('venue');
    onOpenBottomDrawer();
  };

  const onOpenPenaltiesDrawer = () => {
    setActiveElement('penalty');
    onOpenBottomDrawer();
  };

  const onOpenOpponentDrawer = () => {
    setActiveElement('opponent');
    onOpenBottomDrawer();
  };

  const onOpenConstraintDrawer = () => {
    setActiveElement('constraint');
    onOpenBottomDrawer();
  };

  const onOpenSlotDrawer = () => {
    setActiveElement('slot');
    onOpenBottomDrawer();
  };

  const onOpenLogDrawer = () => {
    setActiveElement('log');
    onOpenBottomDrawer();
  };

  const toggleSolveModal = () => {
    setIsSolveModalVisible((prev) => !prev);
  };

  useEffect(() => {
    if (lastBottomHeight.current) {
      setAgGridHeight(window.innerHeight - lastBottomHeight.current);
      adjustGridWidth();
    }
    if (tempHeight) {
      setAgGridHeight(window.innerHeight - tempHeight);
      adjustGridWidth();
    } else {
      setAgGridHeight(0);
    }
  }, [tempHeight]);

  useEffect(() => {
    if (lastSideWidth.current) {
      setAgGridWidth(
        document.body.clientWidth -
          lastSideWidth.current -
          (isSolutionsVisible ? leftSidebarOpenWidth : 0),
      );
    }
    if (tempWidth) {
      setAgGridWidth(
        document.body.clientWidth -
          tempWidth -
          (isSolutionsVisible ? leftSidebarOpenWidth : 0),
      );
    }

    if (tempWidth) {
      adjustGridHeight();
    }
  }, [tempWidth]);

  useEffect(() => {
    adjustGridWidth();
    adjustGridHeight();
  }, [tempHeight, tempWidth, isSolutionsVisible]);

  useEffect(() => {
    const urlSolveKey = searchParams.get('solveKey');
    if (urlSolveKey) {
      const parts = urlSolveKey.split('-');

      if (parts.length === 4) {
        parts.pop();
        setCurrentSolutionKey(parts.join('-'));
      }

      setCurrentSolveDataKey(urlSolveKey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      !isLogButtonVisible &&
      selectedSolution &&
      [
        SolutionStatusSolveRequested,
        SolutionStatusSolving,
        SolutionStatusStopping,
        SolutionStatusStopped,
      ].includes(selectedSolution.solutionStatus)
    ) {
      setIsLogButtonVisible(() => true);
    }
  }, [selectedSolution]);

  const handleSolveScenarioStop = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;

    const solveScenarioStopResult = await solveScenarioStop(
      selectedSolution?.solutionKey || null,
    );
    return solveScenarioStopResult;
  };

  const isSolveButtonVisible: boolean =
    (selectedSolution ?? false) &&
    (selectedSolution?.solutionStatus === SolutionStatusCreated ||
      selectedSolution?.solutionStatus === SolutionStatusSolveRequested);

  const isSolveButtonDisabled: boolean =
    queue.length > 0 ||
    ((selectedSolution ?? false) &&
      selectedSolution?.solutionStatus === SolutionStatusSolveRequested);

  const isSolveStopButtonVisible: boolean =
    (selectedSolution ?? false) &&
    (selectedSolution?.solutionStatus === SolutionStatusSolving ||
      selectedSolution?.solutionStatus === SolutionStatusStopRequested ||
      selectedSolution?.solutionStatus === SolutionStatusStopping);

  const isSolveStopButtonDisabled: boolean =
    (selectedSolution ?? false) &&
    (selectedSolution?.solutionStatus === SolutionStatusStopRequested ||
      selectedSolution?.solutionStatus === SolutionStatusStopping);

  const handleScreenResize = () => {
    adjustGridWidth();
    adjustGridHeight();
  };

  useEffect(() => {
    if (solveData) {
      setIsloading(false);
    }
  }, [solveData]);

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);

    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      setIsSolutionsVisible(true);
    }

    return () => {
      if (!containerRef.current) {
        setCurrentSolutionKey('');
        setCurrentSolveDataKey('');
      }
    };
  }, []);

  useEffect(() => {
    if (
      selectedSolution?.solutionStatus !== SolutionStatusStopped ||
      solveData?.solveDataKey ===
        selectedSolution?.solveDataList[0]?.solveDataKey
    ) {
      setIsModelSummaryBtnVisible(false);
    } else {
      setIsModelSummaryBtnVisible(true);
    }
  }, [selectedSolution, solveData]);

  const maxInnerHeight = getMaxInnerHeight(!!tempHeight);

  return (
    <div className="overflow-hidden">
      <Header />

      <div className="scenario-detail-container">
        <div
          className={classNames('scenario-content', {
            full: isDrawerVisible,
          })}
          ref={containerRef}
          style={{
            height: isDrawerVisible ? `calc(100% - ${agGridHeight}px)` : '',
          }}
        >
          <div
            className={classNames('results-sidebar', {
              open: isSolutionsVisible,
            })}
            data-testid="sidebar-trigger"
          >
            <div
              className={classNames('results', {
                open: isSolutionsVisible,
              })}
            >
              {isSolutionsVisible ? <Solutions /> : null}
            </div>
            <div
              className="cursor-pointer py-1 trigger"
              onClick={toggleResultSectionVisibility}
            >
              {isSolutionsVisible ? <ChevronsLeft /> : <ChevronsRight />}
            </div>
          </div>
          <div
            className={classNames('schedule-container', {
              'flex-col': tempHeight,
            })}
            ref={scheduleRef}
          >
            <div
              className="round-teams-grid py-1"
              style={{
                width: gridContentWidth,
              }}
            >
              <Spin
                spinning={isLoading || isSaving}
                fullscreen={
                  showFullscreenLoader &&
                  isSaving &&
                  (tempHeight !== 0 || tempWidth !== 0)
                }
                className="w-full"
              >
                {haveAvailableSlots ? (
                  <SolveDataTable
                    height={tempHeight ? agGridHeight : 0}
                    width={agGridWidth}
                    maxInnerHeight={maxInnerHeight}
                    customWidth={tempWidth}
                  />
                ) : (
                  <Empty
                    title={t('GENERAL.SCENARIO.NO_SCHEDULE')}
                    subtitle={t('GENERAL.SCENARIO.SETUP')}
                    height={300}
                    ignoreIcon
                    className="w-full"
                  />
                )}
              </Spin>
            </div>
            {tempHeight === 0 && (
              <Drawer
                direction="right"
                length={tempWidth}
                onClose={onCloseRightDrawer}
                onChange={onSideDrawerWidthChange}
                onDockBottom={onDockDown}
                isSaving={isSaving}
              >
                {activeWindowElement}
              </Drawer>
            )}
          </div>
        </div>

        <Drawer
          direction="bottom"
          length={tempHeight}
          onClose={onCloseBottomDrawer}
          onChange={onBottomDrawerHeightChange}
          onDockRight={onDockRight}
          isSaving={isSaving}
          fullscreen={isDrawerFullScreen}
        >
          {tempHeight ? activeWindowElement : null}
        </Drawer>

        {!isDrawerVisible && (
          <footer>
            <div
              className={classNames('scenario-actions', {
                invisible: !stateData,
              })}
            >
              <button
                type="button"
                className="penalty-button px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                onClick={onOpenPenaltiesDrawer}
                data-testid="penalty-button"
              >
                {t('GENERAL.PENALTY.TITLE')}
              </button>

              <button
                type="button"
                onClick={exportModelSummary}
                data-testid="model-summary-button"
                className={classNames(
                  'penalty-button px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none',
                  {
                    invisible: !isModelSummaryBtnVisible,
                  },
                )}
                disabled={isModelSummaryDownloading}
              >
                {t(
                  isModelSummaryDownloading
                    ? 'GENERAL.DOWNLOADING'
                    : 'GENERAL.MODEL.SUMMARY.TITLE',
                )}
              </button>

              <span className="flex-grow-1" />
              <Tooltip placement="top" title={t('GENERAL.TEAM.TITLE_other')}>
                <div
                  className="icon px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenTeamDrawer}
                  data-testid="team-drawer-button"
                >
                  <TeamIcon />
                </div>
              </Tooltip>

              <Tooltip placement="top" title={t('GENERAL.VENUE.TITLE_other')}>
                <div
                  className="icon px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenVenueDrawer}
                  data-testid="venue-drawer-button"
                >
                  <VenueIcon />
                </div>
              </Tooltip>

              <Tooltip placement="top" title={t('GENERAL.SLOT.TITLE_other')}>
                <div
                  className="icon px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenSlotDrawer}
                  data-testid="slot-button"
                >
                  <SlotIcon />
                </div>
              </Tooltip>

              <Divider type="vertical" className="divider" />

              <Tooltip
                placement="top"
                title={t('GENERAL.OPPONENT.TITLE_other')}
              >
                <div
                  className="icon px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenOpponentDrawer}
                  data-testid="opponent-button"
                >
                  <OpponentsIcon />
                </div>
              </Tooltip>

              <Divider type="vertical" className="divider" />

              <Tooltip
                placement="top"
                title={t('GENERAL.CONSTRAINT.TITLE_other')}
              >
                <div
                  className="icon px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenConstraintDrawer}
                  data-testid="constraint-button"
                >
                  <ConstraintsIcon />
                </div>
              </Tooltip>

              <span className="flex-grow-1" />
              {isLogButtonVisible && (
                <button
                  type="button"
                  className="penalty-button px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
                  onClick={onOpenLogDrawer}
                  data-testid="view-log-button"
                >
                  {t('GENERAL.VIEW_LOG')}
                </button>
              )}

              <Tooltip
                placement="top"
                title={t(
                  isSolveButtonDisabled
                    ? 'GENERAL.FEEDBACK.SOLVE.WAIT'
                    : 'GENERAL.SOLVE',
                )}
              >
                <button
                  type="button"
                  className={classNames(
                    'px-3 py-2 text-xs font-medium text-white rounded-lg focus:ring-4 focus:outline-none',
                    {
                      'bg-gray-500': isSolveButtonDisabled,
                      'bg-blue-500': !isSolveButtonDisabled,
                      hidden: !isSolveButtonVisible,
                    },
                  )}
                  onClick={toggleSolveModal}
                  data-testid="solve-button"
                  disabled={isSolveButtonDisabled}
                >
                  {t('GENERAL.SOLVE')}
                </button>
              </Tooltip>

              {isSolveStopButtonVisible && (
                <button
                  type="button"
                  title={`${selectedSolution?.solutionStatus}`}
                  className={`${
                    isSolveStopButtonDisabled ? 'bg-gray-500' : 'bg-red-500'
                  } px-3 py-2 text-xs font-medium text-white rounded-lg focus:ring-4 focus:outline-none`}
                  onClick={handleSolveScenarioStop}
                  data-testid="solveStop-button"
                  disabled={isSolveStopButtonDisabled}
                >
                  {t('GENERAL.SOLVE_STOP')}
                </button>
              )}
            </div>
          </footer>
        )}
      </div>

      {isSolveModalVisible && (
        <SolveScenario
          onCancel={() => setIsSolveModalVisible(false)}
          setIsLogButtonVisible={setIsLogButtonVisible}
          connectApplicationLogHub={connectApplicationLogHubHandler}
        />
      )}
    </div>
  );
}

export default Scenario;
