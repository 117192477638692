import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';
import ServiceIntegrationForm from './ServiceIntegrationForm';
import {
  ServiceIntegration,
  ServiceIntegrationItem,
  ValidProviders,
} from 'Models/User';
import { Aws, Azure, GCP } from 'Components/Elements/Icons';

interface Props {
  item: {
    provider: ValidProviders;
    title: string;
    subtitle: string;
  };
  integratedServices: ServiceIntegration;
  onSave: (data: ServiceIntegrationItem) => void;
}

function IntegrationCard({
  item,
  integratedServices,
  onSave,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm((prev) => !prev);
  };

  useEffect(() => {
    if (integratedServices[item.provider]) {
      setShowForm(true);
    }
  }, [integratedServices]);

  if (showForm) {
    return (
      <ServiceIntegrationForm
        item={item}
        integratedServices={integratedServices}
        onSave={onSave}
      />
    );
  }

  return (
    <div className="flex gap-3 border rounded-md p-3 px-4 items-center">
      <div>
        {item.provider === 'AWS' && <Aws />}
        {item.provider === 'Azure' && <Azure />}
        {item.provider === 'GCP' && <GCP />}
      </div>
      <div className="grow">
        <div className="section-title font-semibold pl-0">{item.title}</div>
        <div className="section-subtitle text-sm">{item.subtitle}</div>
      </div>

      <button
        type="button"
        className="text-white bg-blue-500 flex items-center gap-2 bg px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
        onClick={toggleForm}
      >
        <LinkOutlined rotate={45} />
        {t('GENERAL.CONNECT')}
      </button>
    </div>
  );
}

export default IntegrationCard;
