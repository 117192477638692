import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SignalRService, {
  connectApplicationLogHub,
  connectSolverLogHub,
  connectStatusHub,
  disconnectApplicationLogHub,
  disconnectSolverLogHub,
  disconnectStatusHub,
} from 'Services/SignalRService';
import {
  selectCurrentScenarioKey,
  selectSolutionKey,
} from 'store/slices/scenarioSlice';

export default function useConnectHubs() {
  const scenarioKey = useSelector(selectCurrentScenarioKey);
  const solutionKey = useSelector(selectSolutionKey);

  const applicationLogHubRef = useRef<SignalRService | null>(null);
  const [applicationLogHub, setApplicationLogHub] = useState(
    applicationLogHubRef.current,
  );

  const solverLogHubRef = useRef<SignalRService | null>(null);
  const [solverLogHub, setSolverLogHub] = useState(solverLogHubRef.current);

  const statusHubRef = useRef<SignalRService | null>(null);
  const [statusHub, setStatusHub] = useState(statusHubRef.current);

  const isExecuting = useRef<boolean>(false);

  const connectApplicationLogHubHandler = (
    currentSolutionKey?: string,
  ): Promise<SignalRService | null> => {
    if (applicationLogHubRef.current) {
      return Promise.resolve(applicationLogHubRef.current);
    }

    return connectApplicationLogHub(currentSolutionKey)
      .then((hub: SignalRService) => {
        applicationLogHubRef.current = hub;
        setApplicationLogHub(applicationLogHubRef.current);
        return Promise.resolve(applicationLogHubRef.current);
      })
      .catch((err) => Promise.reject(err));
  };

  const connectSolverLogHubHandler = (
    currentSolutionKey?: string,
  ): Promise<SignalRService | null> => {
    if (solverLogHubRef.current) {
      return Promise.resolve(solverLogHubRef.current);
    }

    return connectSolverLogHub(currentSolutionKey)
      .then((hub: SignalRService) => {
        solverLogHubRef.current = hub;
        setSolverLogHub(solverLogHubRef.current);
        return Promise.resolve(solverLogHubRef.current);
      })
      .catch((err) => Promise.reject(err));
  };

  const disconnectSolverLogHubHandler = () => {
    if (solverLogHubRef.current) {
      solverLogHubRef.current = null;
      setSolverLogHub(null);
      disconnectSolverLogHub();
    }
  };

  useEffect(() => {
    if (!isExecuting.current || !statusHubRef.current) {
      connectStatusHub(scenarioKey)
        .then((hub: SignalRService) => {
          statusHubRef.current = hub;
          setStatusHub(statusHubRef.current);
        })
        .finally(() => {
          isExecuting.current = false;
        });

      isExecuting.current = true;
    }

    return () => {
      if (statusHubRef.current) {
        statusHubRef.current = null;
        setStatusHub(null);
        disconnectStatusHub();
      }
    };
  }, [scenarioKey]);

  useEffect(
    () => () => {
      if (applicationLogHubRef.current) {
        applicationLogHubRef.current = null;
        setApplicationLogHub(null);
        disconnectApplicationLogHub();
      }

      if (solverLogHubRef.current) {
        solverLogHubRef.current = null;
        setSolverLogHub(null);
        disconnectSolverLogHub();
      }
    },
    [solutionKey],
  );

  return {
    applicationLogHub,
    solverLogHub,
    connectApplicationLogHubHandler,
    connectSolverLogHubHandler,
    disconnectSolverLogHubHandler,
    statusHub,
  };
}
