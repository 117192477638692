import React, { useEffect, useState } from 'react';
import SignalRMessages from 'Components/Scenarios/EditScenario/Solve/Solve/SignalRMessages';
import { FetchLogMessages } from 'Services/ScenarioService';
import useScenario from 'storeHooks/useScenario';
import { handleFetchLogs } from './ViewLog';

export function ApplicationLog({ message }: { message: string }) {
  const { selectedSolution } = useScenario();
  const [displayMessage, setDisplayMessage] = useState(message);

  useEffect(() => {
    handleFetchLogs(
      selectedSolution,
      'ApplicationLog',
      FetchLogMessages,
      setDisplayMessage,
    );
  }, [selectedSolution]);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <div className="terminal-log" data-testid="application-log">
      <SignalRMessages message={displayMessage} />
    </div>
  );
}
