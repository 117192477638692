import React, { useEffect, useState, useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Dropdown, Card } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { SwapOutlined } from '@ant-design/icons';
import {
  LockType,
  LockTypeLock,
  LockTypeUnLock,
  BUILD_SET_FROM_GAMES,
} from 'Models/LockTypes';
import {
  ColumnTypeBye,
  ColumnTypeMatch,
  NameMap,
  SolveTableType,
  ZoomType,
} from '../../../../../Models/Scenario.d';
import { Team } from 'Models/Team';
import { Edit } from 'Components/Elements/Icons';
import { Color, NetworkCategory } from 'Models/Network';
import ErrorBoundary from 'Components/Common/ErrorBoundary';
import { RoundInstance } from 'Models/RoundInstance';
import { SolverColumn, SolverRow } from './SolveTypes';
import PopoverRoundDetail from './PopoverRoundDetail';
import RoundNameEditPopover, { CUSTOM_NAME } from './RoundNameEditPopover';
import { useSelector } from 'react-redux';
import {
  selectVenueTeams,
  selectVenues,
  selectTeams,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import {
  initializeTableSelection,
  unInitializeTableSelection,
  getSelectedCells,
  clearCellsHilighting,
  selectAllCells,
  toBeExecutedWhileMultiSelect,
} from './SelectSolveTableCells';
import { DEFAULT_UNLOCKED_BYE_CELL_COLOR } from 'utils/constants';
import './Solve.scss';
import { Venue } from 'Models/Venue';
import { decodeHTML } from 'utils/ui-helper';
import { SlotType } from 'Models/Slot';

dayjs.extend(utc);

interface SolveTableHtmlProps {
  slotRows: SolverRow[];
  selectedHomeTeams: Team[];
  onGamesLock: (
    lockType: LockType,
    columnShortIds: string[],
  ) => Promise<string>;
  createFixedGameSet: (columnShortIds: string[]) => void;
  type?: SolveTableType;
  zoom: ZoomType;
}

type CellStyleType = {
  color?: Color;
  backgroundColor?: Color;
  display?: Color;
} | null;

type DisplayModeType = 'default' | 'transpose';

type TransposedColumnType = {
  column: SolverColumn;
  round: RoundInstance;
};
type TransposedDataType = {
  team: Team;
  columns: TransposedColumnType[];
};

type TeamMapType = Record<string, Team>;

type MENU_KEY_TYPE = typeof BUILD_SET_FROM_GAMES | LockType;

const READ_ONLY = 'read-only';

function HtmlSlotRow({
  slotRow,
  rowIndex,
  zoom,
  highlightedColumns,
  getCalculatedCell,
  getPopoverInfo,
}: {
  slotRow: SolverRow;
  rowIndex: number;
  zoom: string;
  highlightedColumns: number[];
  getPopoverInfo: (slotRow: SolverRow) => {
    cellInfos: [string, string][];
    roundCellText: string;
    round: RoundInstance;
  };
  getCalculatedCell: (
    col: SolverColumn,
    rowIndex: number,
    i: number,
  ) => JSX.Element;
}): JSX.Element {
  const { roundCellText, round } = getPopoverInfo(slotRow);
  const [isEditPopoverOpen, setEditPopoverOpen] = useState(false);

  const toggleEditPopover = () => {
    setEditPopoverOpen((prev) => !prev);
  };

  const editPopover = () => (
    <RoundNameEditPopover
      value={roundCellText}
      round={round}
      onClose={toggleEditPopover}
    />
  );

  return (
    <tr
      key={slotRow.round.roundNumber.toString()}
      id={slotRow.round.roundNumber.toString()}
      className="solve-table-body-row"
    >
      {/* Slot */}
      <td
        className={classNames(`zoom-${zoom}`, {
          active: rowIndex === highlightedColumns[0],
        })}
      >
        <div className={classNames('solve-table-cell-slot')}>
          <div className="flex items-center justofy-between gap-2">
            <span className={READ_ONLY}>{roundCellText}</span>

            <Dropdown
              menu={{ items: [] }}
              trigger={['click']}
              dropdownRender={editPopover}
              open={isEditPopoverOpen}
              destroyPopupOnHide
            >
              <div
                className="px-1"
                onClick={toggleEditPopover}
                data-testid="trigger-edit-name"
              >
                <Edit className="w-3 h-3 hidden" />
              </div>
            </Dropdown>
          </div>
        </div>
      </td>

      {/* Teams */}
      {slotRow.columns.map((col, i) => getCalculatedCell(col, rowIndex, i))}
    </tr>
  );
}

export default function SolveTableHtml({
  slotRows,
  selectedHomeTeams,
  onGamesLock,
  createFixedGameSet,
  type,
  zoom,
}: SolveTableHtmlProps): JSX.Element {
  const { t } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const allVenues = useSelector(selectVenues);
  const allTeams = useSelector(selectTeams);
  const venueTeams = useSelector(selectVenueTeams);
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [highlightedColumns, setHighlightedColumns] = useState([-1, -1]);
  const [displayType, setDisplayType] = useState<DisplayModeType>('default');
  const currentType = type ?? 'all';

  const contextMenuItems: MenuProps['items'] = [
    {
      label: t('GENERAL.LOCK.SELECTED_GAMES'),
      key: LockTypeLock,
    },
    {
      label: t('GENERAL.UNLOCK.SELECTED_GAMES'),
      key: LockTypeUnLock,
    },
    {
      label: t('GENERAL.SOLVE_ACTION.BUILD_SET_FIX_GAMES'),
      key: BUILD_SET_FROM_GAMES,
    },
  ];

  const venuesMap = useMemo(() => {
    if (allVenues) {
      return allVenues.reduce((acc: NameMap, v: Venue) => {
        acc[v.venueId] = decodeHTML(v.name);

        return acc;
      }, {});
    }
    return {};
  }, [allVenues]);

  const teamVenues = useMemo(() => {
    if (allTeams && venueTeams) {
      return allTeams.reduce((acc: Record<string, string[]>, team: Team) => {
        const TeamVenues: string[] = [];

        venueTeams.forEach((venueTeam) => {
          if (
            venueTeam.teamIds.includes(team.teamId) &&
            !TeamVenues.includes(venueTeam.venueId)
          ) {
            TeamVenues.push(venueTeam.venueId);
          }
        });

        acc[team.teamId] = TeamVenues;

        return acc;
      }, {});
    }

    return {};
  }, [allTeams, venueTeams]);

  const teamsNameMap: TeamMapType = useMemo(() => {
    const map: TeamMapType = {};

    if (selectedSolution) {
      selectedSolution.stateData.teams.forEach((team) => {
        map[team.code] = team;
      });
    }
    return map;
  }, [selectedSolution]);

  useEffect(() => {
    setTimeout(() => {
      initializeTableSelection();
    }, 500);

    toBeExecutedWhileMultiSelect.clear_headers = () =>
      setHighlightedColumns((prev) => {
        if (prev[0] !== -1 || prev[0] !== -1) {
          return [-1, -1];
        }
        return prev;
      });

    return () => {
      unInitializeTableSelection();
    };
  }, [zoom, slotRows, type]);

  useEffect(() => {
    clearCellsHilighting();
  }, [type]);

  const transposedData: TransposedDataType[] = selectedHomeTeams.map(
    (team, i) => {
      const newColumns: TransposedColumnType[] = [];

      slotRows.forEach((slotRow) => {
        newColumns.push({
          column: slotRow.columns[i],
          round: slotRow.round,
        });
      });

      return {
        team,
        columns: newColumns,
      } as TransposedDataType;
    },
  );

  const onClearEveryThing = () => {
    setHighlightedColumns([-1, -1]);
    clearCellsHilighting();
  };

  const handleSelectAllCells = () => {
    const currentSelectedCellCount = getSelectedCells().filter((element) =>
      element.classList.contains('highlighted'),
    ).length;

    if (currentSelectedCellCount === 0) {
      selectAllCells();
    } else {
      onClearEveryThing();
    }
  };

  const onSwapOrientation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onClearEveryThing();
    setDisplayType((prev) => (prev === 'default' ? 'transpose' : 'default'));
  };

  const getPopoverInfo = (slotRow: SolverRow) => {
    let cellInfos: [string, string][] = [];
    const rndNum: number = slotRow.round.roundNumber;

    let roundCellText = `${rndNum}`;

    const datesInRow = slotRow.columns.filter((c) => c.date).map((c) => c.date);

    const uniqDatesInRow = Array.from(new Set(datesInRow)); // distinct

    let rowDate = '';
    if (uniqDatesInRow.length <= 1) {
      const oneRowDate: Date = slotRow.round.roundDays[0].date;
      const slotDayDate = dayjs(oneRowDate).utc();
      const slotDateMonth = slotDayDate.format('MMM');
      const slotDateWeekDay = slotDayDate.format('ddd');
      const slotDate = `${slotDateMonth} ${slotDayDate.format('DD')}`;

      rowDate = `${slotDate}, ${slotDateWeekDay}`;

      roundCellText += ` - ${slotDate}, ${slotDateWeekDay}`;
    } else {
      roundCellText += ` - ${t('GENERAL.MULTI_DATES')}`;

      uniqDatesInRow.forEach((dt) => {
        const oneRowDate = dt as Date;
        const slotDayDate = dayjs(oneRowDate).utc();
        const slotDateMonth = slotDayDate.format('MMM');
        const slotDateWeekDay = slotDayDate.format('ddd');
        const slotDate = `${slotDateMonth} ${slotDayDate.format('DD')}`;

        rowDate = `${rowDate} ${
          rowDate ? ',' : ''
        } ${slotDate}, ${slotDateWeekDay}`;
      });
    }

    cellInfos = [
      [t('GENERAL.ROUND.TITLE'), rndNum.toString()],
      [t('GENERAL.TIME.DATE'), rowDate],
    ];

    if (slotRow.round.name.includes(CUSTOM_NAME)) {
      roundCellText = slotRow.round.name.replace(CUSTOM_NAME, '').split('#')[0];
    }

    return {
      cellInfos,
      roundCellText,
      round: slotRow.round,
    };
  };

  const getRoundDate = (slotRow: SolverRow) => {
    const { cellInfos, roundCellText } = getPopoverInfo(slotRow);

    const createPopover = () => <PopoverRoundDetail items={cellInfos} />;

    return (
      <Dropdown
        menu={{ items: [] }}
        trigger={['hover']}
        dropdownRender={createPopover}
        destroyPopupOnHide
      >
        <span className={READ_ONLY}>{roundCellText}</span>
      </Dropdown>
    );
  };

  function getTeamCellStyle(col: SolverColumn) {
    let className: string = '';
    let cellTeamColorsStyle: CellStyleType = null;

    const defaultCellColor: Color = 'white';
    const defaultUnlockedCellColor: Color = 'grey';
    const defaultByeCellColor: Color = 'black';
    const defaultUnlockedByeCellColor: Color = DEFAULT_UNLOCKED_BYE_CELL_COLOR;

    if (col.columnType === ColumnTypeBye) {
      className = 'solve-table-cell-team-bye-selected';

      if (col.isLocked) {
        className += ' locked';
        cellTeamColorsStyle = {
          color: defaultCellColor,
          backgroundColor: defaultByeCellColor,
        };
      } else {
        className += ' unlocked';
        cellTeamColorsStyle = {
          color: defaultCellColor,
          backgroundColor: defaultUnlockedByeCellColor,
        };
      }
    } else if (col.isHomeGameForOpponentTeam === false) {
      // homeTeam cell
      className = 'solve-table-cell-team-away-selected';

      if (col.isLocked) {
        className += ' locked';
        cellTeamColorsStyle = {
          color: defaultCellColor,
          backgroundColor: col.networkCategory?.color as Color,
        };
      } else {
        className += ' unlocked';
        cellTeamColorsStyle = {
          color: defaultCellColor,
          backgroundColor: defaultUnlockedCellColor,
        };
      }
    } else if (col.isHomeGameForOpponentTeam === true) {
      // awayTeam cell
      className = 'solve-table-cell-team-home-selected';

      if (col.isLocked) {
        className += ' locked';
        cellTeamColorsStyle = {
          color: col.networkCategory?.color as Color,
          backgroundColor: defaultCellColor,
        };
      } else {
        className += ' unlocked';
        cellTeamColorsStyle = {
          color: defaultUnlockedCellColor,
          backgroundColor: defaultCellColor,
        };
      }
    } else {
      // empty cell
      className = 'solve-table-cell-team-not-selected';
    }

    if (isSavingInProgress) {
      // saving in process for a cell
      className = 'saving-in-progress';
    }

    return { className, cellTeamColorsStyle };
  }

  const getCalculatedCell = (
    col: SolverColumn,
    rowIndex: number,
    i: number,
  ) => {
    let className = '';

    let cellTxt = '';
    let cellInfos: [string, string][] = [];
    let cellTeamColorsStyle: CellStyleType = null;

    ({ className, cellTeamColorsStyle } = getTeamCellStyle(col));

    if (col.team) {
      let matchStr: string | null = null;
      let venueStr: string | null = null;

      let slotDayDate: Dayjs | null = null;
      let slotType: SlotType | null = null;
      let networkCategory: NetworkCategory | null = null;
      let slotDateWeekDay: string | null = null;
      let slotDate: string | null = null;

      if (col.opponentTeam && col.columnType === ColumnTypeMatch && col.date) {
        const homeTeam = !col.isHomeGameForOpponentTeam
          ? col.team
          : col.opponentTeam;
        const awayTeam = col.isHomeGameForOpponentTeam
          ? col.team
          : col.opponentTeam;

        matchStr = `${homeTeam?.code}(H) vs ${awayTeam?.code}(A)`;
        venueStr = `${homeTeam?.venue?.name}`;
        cellTxt = col.opponentTeam.code;

        slotDayDate = dayjs(col.date).utc();
        networkCategory = col.networkCategory!;
        slotType = networkCategory.slotType ?? null;
        slotDateWeekDay = slotDayDate.format('ddd');
        slotDate = slotDayDate.format('DD MMM');
      } else if (col.columnType === ColumnTypeBye) {
        matchStr = 'Bye for ' + col.team.code;
        venueStr = `${col.venue?.name}`;
      }

      cellInfos = [
        [t('GENERAL.MATCHUP'), matchStr ?? ''],
        [t('GENERAL.TIME.DATE'), `${slotDateWeekDay ?? ''}, ${slotDate ?? ''}`],
        [t('GENERAL.VENUE.TITLE'), venueStr ?? ''],
        [t('GENERAL.SLOT.TYPE'), `${slotType?.name}`],
        [t('GENERAL.SET_LINE.CATEGORY'), `${networkCategory?.name}`],
        [t('GENERAL.NETWORK.TITLE'), `${networkCategory?.network?.name}`],
      ];
    }

    const reactColKey = `${col.team?.teamId}_${col.opponentTeam?.teamId}${i}`;

    let showText = true;

    if (cellTeamColorsStyle) {
      if (col.columnType === ColumnTypeBye) {
        showText = false;
      }

      if (
        currentType === 'home' &&
        (cellTeamColorsStyle.backgroundColor === 'white' ||
          col.columnType === ColumnTypeBye)
      ) {
        cellTeamColorsStyle.color = 'white';
        cellTeamColorsStyle.backgroundColor = 'white';
      } else if (
        currentType === 'away' &&
        (cellTeamColorsStyle.backgroundColor !== 'white' ||
          col.columnType === ColumnTypeBye)
      ) {
        cellTeamColorsStyle.color = 'white';
        cellTeamColorsStyle.backgroundColor = 'white';
      } else if (currentType === 'locked' && !col.isLocked) {
        cellTeamColorsStyle.backgroundColor = 'white';
        cellTeamColorsStyle.color = 'white';
      } else if (currentType === 'unlocked' && col.isLocked) {
        cellTeamColorsStyle.backgroundColor = 'white';
        cellTeamColorsStyle.color = 'white';
      }
    }

    const createPopover = () => (
      <PopoverRoundDetail
        items={cellInfos}
        message={
          cellInfos.length == 0 || !showText ? t('GENERAL.GAME.EMPTY') : ''
        }
      />
    );

    return (
      <td
        id={col.columnShortId}
        onMouseDown={(e) => e.stopPropagation()}
        className={`team-match ${className} zoom-${zoom}-cell`}
        key={reactColKey}
        style={cellTeamColorsStyle ?? {}}
        onClick={() => setHighlightedColumns([rowIndex, i])}
        onContextMenu={(e) => {
          // @ts-expect-error
          e.target.parentElement.classList.add('highlighted');
          setHighlightedColumns([rowIndex, i]);
        }}
        data-testid="team-match"
      >
        <Dropdown
          menu={{ items: [] }}
          trigger={['hover']}
          dropdownRender={createPopover}
          placement="topLeft"
          destroyPopupOnHide
        >
          <div>{showText ? cellTxt : <span>&nbsp;&nbsp;</span>}</div>
        </Dropdown>
      </td>
    );
  };

  const getTransposedHtmlSlotRow = (
    slotRow: TransposedDataType,
    rowIndex: number,
  ): JSX.Element => {
    return (
      <tr key={slotRow.team.teamId} className="solve-table-body-row">
        {/* Slot */}
        <td
          className={classNames({
            active: rowIndex === highlightedColumns[0],
          })}
        >
          <div className={classNames('solve-table-cell-slot', READ_ONLY)}>
            <Dropdown
              menu={{ items: [] }}
              trigger={['hover']}
              dropdownRender={() => (
                <Card
                  className="table-cell-popover"
                  type="inner"
                  title=""
                  style={{ minWidth: 200 }}
                >
                  {getTeamPopoverInfo(slotRow.team.code)}
                </Card>
              )}
              destroyPopupOnHide
            >
              <span>{slotRow.team.code}</span>
            </Dropdown>
          </div>
        </td>

        {/* Teams */}
        {slotRow.columns.map(({ column: col }, i) =>
          getCalculatedCell(col, rowIndex, i),
        )}
      </tr>
    );
  };

  const getTeamPopoverInfo = (teamCode: string) => {
    const selectedTeam = teamsNameMap[teamCode]!;

    return [
      {
        title: t('GENERAL.NAME.TITLE'),
        value: selectedTeam.name,
      },
      {
        title: t('GENERAL.CITY'),
        value: selectedTeam.city,
      },
      {
        title: t('GENERAL.TEAM.HOME_VENUE'),
        value: teamVenues[selectedTeam.teamId]
          ?.map((venueId) => venuesMap[venueId])
          .join(','),
      },
      {
        title: 'ID',
        value: selectedTeam.teamId,
      },
    ].map((item) => (
      <div key={item.title} className="flex items-center p-0 gap-1">
        <span className="w-20">{item.title}</span>
        <span className="mx-1">:</span>
        <span>{item.value}</span>
      </div>
    ));
  };

  const getTableHead = (selectedHomeTeams: Team[]) => (
    <tr key="0" className="solve-table-head">
      <th className="cursor-pointer toggle-view" onClick={handleSelectAllCells}>
        <span className="swap" onClick={onSwapOrientation}>
          <SwapOutlined />
        </span>
      </th>

      {displayType === 'transpose'
        ? slotRows.map((slotRow, colIndex) => (
            <th
              key={`slot-row-${colIndex}`}
              className={classNames(`zoom-${zoom}-cell`, {
                active: highlightedColumns[1] === colIndex,
              })}
            >
              {getRoundDate(slotRow)}
            </th>
          ))
        : selectedHomeTeams.map((homeTeam, colIndex) => (
            <th
              key={homeTeam.teamId}
              className={classNames(`zoom-${zoom}-cell`, {
                active: highlightedColumns[1] === colIndex,
              })}
            >
              <div>
                <Dropdown
                  menu={{ items: [] }}
                  trigger={['hover']}
                  dropdownRender={() => (
                    <Card
                      className="table-cell-popover"
                      type="inner"
                      title=""
                      style={{ minWidth: 200 }}
                    >
                      {getTeamPopoverInfo(homeTeam.code)}
                    </Card>
                  )}
                  destroyPopupOnHide
                >
                  <span>{homeTeam.code}</span>
                </Dropdown>
              </div>
            </th>
          ))}
    </tr>
  );

  const getTableBody = function (slotRows: SolverRow[]) {
    if (slotRows) {
      return slotRows.map((slotRow, rowIndex) => (
        <HtmlSlotRow
          key={`${slotRow.round.roundTemplateKey}-${slotRow.round.roundInstanceKey}`}
          slotRow={slotRow}
          rowIndex={rowIndex}
          zoom={zoom}
          highlightedColumns={highlightedColumns}
          getCalculatedCell={getCalculatedCell}
          getPopoverInfo={getPopoverInfo}
        />
      ));
    } else {
      return (
        <tr key={0}>
          <td>No rows</td>
        </tr>
      );
    }
  };

  const getTransposedTableBody = function (
    transposedRows: TransposedDataType[],
  ) {
    if (transposedRows) {
      return transposedRows.map((slotRow, rowIndex) =>
        getTransposedHtmlSlotRow(slotRow, rowIndex),
      );
    } else {
      return (
        <tr key={0}>
          <td>No rows</td>
        </tr>
      );
    }
  };

  const handleMenuItemClick: MenuProps['onClick'] = async ({ key }) => {
    const menuOptions = key as MENU_KEY_TYPE;

    const selectedCells = getSelectedCells();
    const selectedCellsIds = selectedCells
      .filter(
        (cell) =>
          !cell.classList.contains('solve-table-cell-team-not-selected'),
      )
      .map((cell) => cell.id);

    if (menuOptions === BUILD_SET_FROM_GAMES) {
      createFixedGameSet(selectedCellsIds);
      clearCellsHilighting();
      return;
    }

    try {
      setIsSavingInProgress(true);
      clearCellsHilighting();

      onGamesLock(menuOptions, selectedCellsIds);
    } catch (err: any) {
      writeError('handleMenuItemClick', err);
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const writeError = (src: string, err: any): void => {
    const msg = `${src} - ${err.message}`;
    alert(msg);
    //TODO: setError(msg);
    console.error(msg);
  };

  const onTableClick = (e: React.MouseEvent<HTMLTableElement>) => {
    // @ts-expect-error
    if (e.target.classList.contains(READ_ONLY)) {
      onClearEveryThing();
    }
  };

  return (
    <ErrorBoundary componentName="SolveTable">
      <Table
        className={`solve-table ${
          isSavingInProgress ? 'saving-in-progress' : ''
        }`}
        onClick={onTableClick}
        bordered
        data-testid="solve-table"
      >
        <thead>{getTableHead(selectedHomeTeams)}</thead>
        <Dropdown
          menu={{ items: contextMenuItems, onClick: handleMenuItemClick }}
          trigger={['contextMenu']}
          destroyPopupOnHide
        >
          <tbody>
            {displayType === 'default'
              ? getTableBody(slotRows)
              : getTransposedTableBody(transposedData)}
          </tbody>
        </Dropdown>
      </Table>
    </ErrorBoundary>
  );
}
