import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card, Divider, Button, Input } from 'antd';
import { useVenue as useVenueQueue } from 'storeHooks/queue';
import { Solution } from 'Models/Scenario';
import { Venue } from 'Models/Venue';
import {
  QueueActionType,
  QueueCreateUpdateVenuePayloadType,
} from 'Models/Queue';
import { useTranslation } from 'react-i18next';
import i18n from 'plugins/i18next';
import useQueue from 'storeHooks/useQueue';
import {
  CREATED,
  NEW,
  SYNC_VENUE_KEY_PREFIX,
  SYNC_VENUE_TEAM_KEY_PREFIX,
} from 'utils/variables';
import useSolution from 'customHooks/useSolution';
import './style.scss';

interface Props {
  onClose: () => void;
}

export const handleCreateVenue = async (
  count: number,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncVenueToStore: (data: QueueCreateUpdateVenuePayloadType) => void,
) => {
  if (!selectedSolution) return;

  const { solutionKey, venues: currentVenues } = selectedSolution.stateData;
  const { solutionKey: localSolutionKey } = selectedSolution;
  const usedVenueCodes = currentVenues.map((item) =>
    item.code.trim().toLowerCase(),
  );
  let iVenue = 1;
  const venues: Venue[] = [];

  while (venues.length < count) {
    const venueCode = i18n.t('GENERAL.VENUE.CODE_NUMBER', {
      number: count + iVenue,
    });
    const venueName = i18n.t('GENERAL.VENUE.NUMBER', {
      number: count + iVenue,
    });

    if (!usedVenueCodes.includes(venueCode.toLowerCase())) {
      const toBeSyncedKey = `${SYNC_VENUE_KEY_PREFIX}${uuidv4()}`;

      const toBeSyncedVenueTeamKey = `${SYNC_VENUE_TEAM_KEY_PREFIX}${uuidv4()}`;
      venues.push({
        venueKey: toBeSyncedKey,
        venueId: toBeSyncedKey,
        code: venueCode,
        name: venueName,
        venueStatus: CREATED,
        toBeSyncedKey,
        solutionKey,
        venueTeam: {
          venueTeamKey: toBeSyncedVenueTeamKey,
          teamIds: [],
          venueId: toBeSyncedKey, // will be replaced from server data tracking this syncedKey
          venueTeamStatus: CREATED,
          toBeSyncedKey: toBeSyncedVenueTeamKey,
        },
      });
    }

    iVenue++;
  }

  syncVenueToStore({
    data: venues,
    solutionKey: localSolutionKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_VENUE',
    payload: {
      data: venues.map((item) => ({
        ...item,
        venueStatus: NEW,
      })),
      toBeSyncedKeys: venues
        .filter((item) => item.toBeSyncedKey)
        .map((item) => item.toBeSyncedKey) as string[],
      solutionKey: localSolutionKey, // local solution key
      actualSolutionKey: solutionKey,
    },
  });
};

function VenueCreatePopOver({ onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { syncVenueToStore } = useVenueQueue();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const [count, setCount] = useState(1);

  const enableButton = useMemo(() => {
    if (count > 0) {
      return true;
    }

    return false;
  }, [count]);

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCount(Number(e.currentTarget.value));
  };

  const onSave = async () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    await handleCreateVenue(
      Math.min(count, 10),
      chosenSolution,
      onEnqueue,
      syncVenueToStore,
    );
    onClose();
  };

  return (
    <Card
      className="default-pop-over"
      type="inner"
      title={t('GENERAL.VENUE.CREATE', { count: 2 })}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.VENUE.TOTAL')}</span>

        <Input
          className="w-40"
          type="number"
          value={count}
          onChange={onCountChange}
          data-testid="venue-count"
        />
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          disabled={!enableButton}
          onClick={onSave}
          data-testid="add-venue-count"
        >
          {t('GENERAL.VENUE.CREATE', { count: 2 })}
        </Button>
      </div>
    </Card>
  );
}

export default VenueCreatePopOver;
