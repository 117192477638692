import React, { useCallback, useState } from 'react';
import type { CollapseProps } from 'antd';
import { Button, Collapse, Input, message, Select, Switch } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  ServiceIntegrationItem,
  ValidProviders,
  ServiceIntegration,
} from 'Models/User';
import { Aws, Azure, GCP } from 'Components/Elements/Icons';

interface IntegrationFormProps {
  item: {
    provider: ValidProviders;
    title: string;
    subtitle: string;
  };
  integratedServices: ServiceIntegration;
}

type IntegrationFormHeaderProps = IntegrationFormProps & {
  onSave: (data: ServiceIntegrationItem) => void;
};

function FormHeader({
  item,
  integratedServices,
}: IntegrationFormProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="p-2 flex gap-2">
      <div>
        {item.provider === 'AWS' && <Aws />}
        {item.provider === 'Azure' && <Azure />}
        {item.provider === 'GCP' && <GCP />}
      </div>
      <div className="grow">
        <p className="section-title pl-0">{item.title}</p>
        <div className="flex gap-1 items-center text-xs text-[#98a2b3]">
          <span>ID : NA</span>
          <span className="opacity-50">|</span>
          <span>{t('GENERAL.USER.FORM.INSTANCES')} : NA</span>
        </div>
      </div>
      <div>
        {integratedServices[item.provider] ? (
          <Button
            icon={<ApiOutlined />}
            danger
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {t('GENERAL.DISCONNECT')}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

function FormBody({
  item,
  integratedServices,
  onSave,
}: IntegrationFormHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<ServiceIntegrationItem>({
    providerName: item.provider,
    isAutoScalingEnabled:
      integratedServices[item.provider]?.isAutoScalingEnabled ?? false,
    minInstanceCount: integratedServices[item.provider]?.minInstanceCount ?? 0,
    maxInstanceCount: integratedServices[item.provider]?.maxInstanceCount ?? 0,
    instanceType: integratedServices[item.provider]?.instanceType ?? '',
    isSolverDeployed:
      integratedServices[item.provider]?.isSolverDeployed ?? false,
  });

  const onSubmit = useCallback(() => {
    if (formData.minInstanceCount > formData.maxInstanceCount) {
      message.error(t('GENERAL.USER.FORM.INSTANCE_COUNT_VALIDATION_ERROR'));
      return;
    }

    onSave(formData);
  }, [formData]);

  return (
    <div>
      <div className="p-4">
        <div className="flex items-center justify-between mb-3">
          <span className="section-subtitle">
            {t('GENERAL.USER.FORM.AUTO_SCALING_ENABLED')}
          </span>

          <Switch
            className="bg-slate-100"
            checked={formData.isAutoScalingEnabled}
            onChange={(checked) => {
              setFormData((prev) => ({
                ...prev,
                isAutoScalingEnabled: checked,
              }));
            }}
          />
        </div>
        <div className="flex items-center justify-between mb-3">
          <span className="section-subtitle">
            {t('GENERAL.USER.FORM.IS_SOLVER_DEPLOYED')}
          </span>

          <Switch
            className="bg-slate-100"
            checked={formData.isSolverDeployed}
            onChange={(checked) => {
              setFormData((prev) => ({
                ...prev,
                isSolverDeployed: checked,
              }));
            }}
          />
        </div>
        <div className="flex items-center justify-between mb-3">
          <span className="section-subtitle">
            {t('GENERAL.USER.FORM.INSTANCE_TYPE')}
          </span>

          <Select options={[]} placeholder="Select" className="w-40" />
        </div>
        <div className="flex items-center justify-between mb-3">
          <span className="section-subtitle">
            {t('GENERAL.USER.FORM.MIN_INSTANCE_COUNT')}
          </span>

          <Input
            type="number"
            value={formData.minInstanceCount}
            min={0}
            className="w-40"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setFormData((prev) => ({
                ...prev,
                minInstanceCount: Number(e.currentTarget.value),
              }));
            }}
          />
        </div>
        <div className="flex items-center justify-between mb-3">
          <span className="section-subtitle">
            {t('GENERAL.USER.FORM.MAX_INSTANCE_COUNT')}
          </span>

          <Input
            type="number"
            value={formData.maxInstanceCount}
            min={0}
            className="w-40"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setFormData((prev) => ({
                ...prev,
                maxInstanceCount: Number(e.currentTarget.value),
              }));
            }}
          />
        </div>
      </div>

      <div className="flex justify-end p-3 collapse-footer">
        <button type="button" className="btn btn-action" onClick={onSubmit}>
          {t('GENERAL.SAVE')}
        </button>
      </div>
    </div>
  );
}

function ServiceIntegrationForm({
  item,
  onSave,
  integratedServices,
}: IntegrationFormHeaderProps): JSX.Element {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <FormHeader item={item} integratedServices={integratedServices} />,
      children: (
        <FormBody
          item={item}
          integratedServices={integratedServices}
          onSave={onSave}
        />
      ),
      showArrow: false,
    },
  ];

  const onChange = (key: string | string[]) => {
    if (key) {
      //  handle active key
    }
  };

  return (
    <Collapse
      defaultActiveKey={!integratedServices[item.provider] ? '1' : ''}
      onChange={onChange}
      items={items}
      className="provider-collapse"
    />
  );
}

export default ServiceIntegrationForm;
